import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import NotificationDropdown from "../../NotificationDropdown/NotificationDropdown";

import clientsIcon from "../../../img/icon/icon_profile.svg";
import clientsIconGreen from "../../../img/icon/icon_profile_green.svg";
import historyIcon from "../../../img/icon/icon_history.svg";
import historyIconGreen from "../../../img/icon/icon_history_green.svg";
import ratesIcon from "../../../img/icon/icon_rates.svg";
import ratesIconGreen from "../../../img/icon/icon_rates_green.svg";
import notificationsIcon from "../../../img/icon/icon_notifications_white.svg";
import notificationsIconGreen from "../../../img/icon/icon_notifications_green.svg";
import profileIcon from "../../../img/icon/icon_tattoo.svg";
import profileIconGreen from "../../../img/icon/icon_tattoo_green.svg";
import menuIcon from "../../../img/icon/icon_collapse.svg";
import xIcon from "../../../img/icon/icon_x.svg";
import logo from "../../../img/logo/logo_hor@2x.png";
import unReadNotificationsIcon from "../../../img/icon/icon_notifcation_badge.svg";
import unReadNotificationsIconGreen from "../../../img/icon/icon_notifcation_badge_green.svg";

import { getNotificationsFromServer } from "../../../store/notificationSlice";

import "./ArtistNav.css";
import { notification } from "antd";

const ArtistNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [unread, setUnread] = useState(false);
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    const toggleNotificationDropdown = (e) => {
      if (activeLink === "/artistNotifications") {
        setToggleDropdown(false);
        setActiveLink(location.pathname);
      }
    };
    if (toggleDropdown) {
      window.addEventListener("click", (e) => toggleNotificationDropdown(e));
    } else {
      window.removeEventListener("click", (e) => toggleNotificationDropdown(e));
    }
    return () =>
      window.removeEventListener("click", toggleNotificationDropdown());
  }, [toggleDropdown, location.pathname, activeLink]);

  useEffect(() => {
    const data = {
      pageIndex: 1,
      pageSize: 5,
    };
    dispatch(getNotificationsFromServer(data));
  }, []);


  useEffect(() => {
    if (!notifications || !notifications.notifications) {
    } else {
      setUnread(
        notifications.notifications.noteVMs.some(
          (notification) => notification.read === "0001-01-01T00:00:00"
        )
      );
    }
  }, [notifications]);


  useEffect(() => {
    if (activeLink === "/artistNotifications") {
    } else {
      setActiveLink(location.pathname);
      setToggleDropdown(false);
    }
  }, [activeLink, location.pathname]);

  const handleNotificationDropdown = () => {
    if (activeLink === "/artistNotifications") {
    } else {
      setToggleDropdown(!toggleDropdown);
      setActiveLink("/artistNotifications");
    }
  };

  const renderNotificationIcon = () => {
    if (activeLink === "/artistNotifications") {
      if (unread) {
        return unReadNotificationsIconGreen;
      } else {
        return notificationsIconGreen;
      }
    } else {
      if (unread) {
        return unReadNotificationsIcon;
      } else {
        return notificationsIcon;
      }
    }
  };

  return (
    <nav className="artist_nav">
      <div className={toggleMenu ? "artist_navbar show-nav" : "artist_navbar"}>
        <div className="artist_nav_logo">
          <img src={logo} alt="Ink Me Financial Logo" />
        </div>
        <div className="artist_nav_link">
          <NavLink
            className={
              activeLink === "/artist"
                ? "artist_nav_active"
                : "artist_nav_icon_link"
            }
            to="/artist"
            replace
          >
            <img
              className="icon"
              src={activeLink === "/artist" ? clientsIconGreen : clientsIcon}
              alt="clients icon"
            />
            {t("NAVBAR.CLIENTS")}
          </NavLink>
        </div>
        <div className="artist_nav_link">
          <NavLink
            className={
              activeLink === "/artistHistory"
                ? "artist_nav_active"
                : "artist_nav_icon_link"
            }
            to="/artistHistory"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/artistHistory" ? historyIconGreen : historyIcon
              }
              alt="history icon"
            />
            {t("NAVBAR.HISTORY")}
          </NavLink>
        </div>
        <div className="artist_nav_link">
          <NavLink
            className={
              activeLink === "/artistRatesTerms"
                ? "artist_nav_active"
                : "artist_nav_icon_link"
            }
            to="/artistRatesTerms"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/artistRatesTerms" ? ratesIconGreen : ratesIcon
              }
              alt="rates and terms icon"
            />
            {t("NAVBAR.RATES_TERMS")}
          </NavLink>
        </div>
        <div className="artist_nav_link">
          <div
            className={
              activeLink === "/artistNotifications"
                ? "artist_nav_active"
                : "artist_nav_icon_link"
            }
            onClick={() => handleNotificationDropdown()}
          >
            <img
              className="icon"
              src={renderNotificationIcon()}
              alt="notifications icon"
            />
            {t("NAVBAR.NOTIFICATIONS")}
          </div>
          {toggleDropdown ? (
            <NotificationDropdown navigateTo={"/artistNotifications"} />
          ) : (
            ""
          )}
        </div>
        <div className="artist_nav_link">
          <NavLink
            className={
              activeLink === "/artistProfile"
                ? "artist_nav_active"
                : "artist_nav_icon_link"
            }
            to="/artistProfile"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/artistProfile" ? profileIconGreen : profileIcon
              }
              alt="profile icon"
            />
            {t("NAVBAR.PROFILE")}
          </NavLink>
        </div>
      </div>
      <button
        className="artist_nav_collapse"
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        {toggleMenu ? (
          <img className="nav_menu_icon" src={xIcon} alt="menu close icon" />
        ) : (
          <img className="nav_menu_icon" src={menuIcon} alt="menu icon" />
        )}
      </button>
    </nav>
  );
};

export default ArtistNav;
