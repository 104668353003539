import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import FormContainer from "../../components/FormContainer/FormContainer";
import CustomInput from "../../components/ButtonsFormFields/CustomInput/CustomInput";
import LgGreenButton from "../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import PasswordInput from "../../components/ButtonsFormFields/PasswordInput/PasswordInput";

import {
  changeUserPasswordCodeRequired,
  getEmail,
  resendUserConfirmationCode,
  getErrorMessage,
  getIsLoggedIn,
  getAccessToken,
  getRole,
  resetForgotPassword,
} from "../../store/authSlice";

import validate from "./ResetPasswordFormValidationRules";
import "./resetPassword.css";

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [securityCode, setSecurityCode] = useState("");
  const email = useSelector(getEmail);
  const errorMessage = useSelector(getErrorMessage);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const role = useSelector(getRole);
  const accessToken = useSelector(getAccessToken);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn === true) {
      if (accessToken && role === "Artist") {
        navigate("/artist");
      }
      if (accessToken && role === "Customer") {
        navigate("/customer");
      }
    } else {
      if (errorMessage === "ERRORS.UNCONFIRMED_STATUS") {
        navigate("/verifyAccount");
      }
    }
  }, [accessToken, isLoggedIn, role, errorMessage, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        email: email,
        password: password.trim(),
        passwordConfirmation: confirmPassword.trim(),
        code: securityCode.trim(),
      };
      dispatch(changeUserPasswordCodeRequired(data));
    }
  }, [
    errors,
    isSubmitting,
    email,
    password,
    confirmPassword,
    securityCode,
    dispatch,
  ]);

  const handleChange = (event) => {
    console.log(event);
    setIsSubmitting(false);
    if (event.target.name === "password") {
      setPassword(event.target.value.trim());
    }
    if (event.target.name === "confirmPassword") {
      setConfirmPassword(event.target.value.trim());
    }
    if (event.target.name === "securityCode") {
      setSecurityCode(event.target.value.trim());
    }
  };

  const onResetPasswordSubmit = async (event) => {
    if (event) event.preventDefault();
    if (event.target.name === "submit") {
      setIsSubmitting(true);
      setErrors({});
      setErrors(validate(password, confirmPassword, securityCode));
    }
  };

  const onResendCodeSubmit = async (event) => {
    event.preventDefault();
    const data = {
      email: email,
    };
    dispatch(resendUserConfirmationCode(data));
  };
  const rerouteToForgotPassword = () => {
    dispatch(resetForgotPassword());
    navigate(-1);
  };

  return (
    <div className="reset_password">
      <FormContainer
        title={t("FORM_FIELDS.RESET_PASSWORD")}
        onFormSubmit={onResetPasswordSubmit}
        navigatelink={rerouteToForgotPassword}
      >
        <p className="reset_instructions">
          {t("FORM_FIELDS.RESET_PASSWORD_INSTRUCTIONS")}
        </p>
        <CustomInput
          type="text"
          name="securityCode"
          value={securityCode}
          placeholder={t("FORM_FIELDS.SECURITY_CODE")}
          onInputChange={handleChange}
          handleKeyPress={handleChange}
          errorMessage={t(errors.securityCode)}
          required
        />
        <PasswordInput
          name="password"
          value={password}
          placeholder={t("FORM_FIELDS.NEW_PASSWORD")}
          onInputChange={handleChange}
          handleKeyPress={handleChange}
          errorMessage={t(errors.password)}
          required
        />
        <PasswordInput
          name="confirmPassword"
          value={confirmPassword}
          placeholder={t("FORM_FIELDS.CONFIRM_PASSWORD")}
          onInputChange={handleChange}
          handleKeyPress={handleChange}
          errorMessage={t(errors.confirmPassword)}
          required
        />
        <p className={errorMessage ? "general_error" : "hidden"}>
          {t(errorMessage)}
        </p>
        <div className="resend_code_button">
          <button
            className="resend_button"
            onClick={onResendCodeSubmit}
            type="button"
          >
            {t("FORM_FIELDS.RE_SEND_CODE")}
          </button>
        </div>
        <div className="reset_password_button">
          <LgGreenButton
            text={t("FORM_FIELDS.RESET_PASSWORD")}
            type="submit"
            name="submit"
            onButtonClick={onResetPasswordSubmit}
          />
        </div>
      </FormContainer>
    </div>
  );
};

export default ResetPassword;
