import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ErrorModal from "../../Modals/ErrorModal/ErrorModal";
import CustomerNav from "../CustomerNav/CustomerNav";
import CustomerFooter from "../CustomerFooter/CustomerFooter";
import Loader from "../../Loader/Loader";

import { getCustomer } from "../../../store/customerSlice";
import "./CustomerPageWrapper.css";

const CustomerPageWrapper = ({ title, children, image }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomer());
  }, []);

  return (
    <div>
      <CustomerNav />
      <ErrorModal />
      <Loader />
      <div
        className="customer_header"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(38,38,38,1) 65%, rgba(38,38,38,0) 100%), url(${image})`,
        }}
      >
        <div className="customer_title">{title}</div>
      </div>
      <div className="page_wrapper_container">{children}</div>
      <CustomerFooter />
    </div>
  );
};

export default CustomerPageWrapper;
