import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormContainer from "../../../components/FormContainer/FormContainer";
import CustomInput from "../../../components/ButtonsFormFields/CustomInput/CustomInput";
import LgGreenButton from "../../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import PasswordInput from "../../../components/ButtonsFormFields/PasswordInput/PasswordInput";

import {
  getArtistError,
  getArtistStatus,
  signUpArtist,
} from "../../../store/artistSlice";
import { addEmail } from "../../../store/authSlice";

import validate from "./ArtistSignUpFormValidationRules";
import "./ArtistSignUp.css";

const ArtistSignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [artistName, setArtistName] = useState("");
  const [studioName, setStudioName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("Province");
  const [postalCode, setPostalCode] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [howDidHear, setHowDidHear] = useState("");
  const [howDidHearOther, setHowDidHearOther] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const status = useSelector(getArtistStatus);
  const errorMessage = useSelector(getArtistError);

  useEffect(() => {
    if (!status) {
    } else {
      if (status === "Email_Not_Verified") {
        navigate("/verifyAccount");
      }
    }
  }, [status, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      let howHeard;
      if (howDidHear === "other") {
        howHeard = howDidHearOther;
      } else {
        howHeard = howDidHear;
      }
      const data = {
        artistName: artistName,
        studioName: studioName,
        firstName: firstName,
        lastName: lastName,
        profileEmail: profileEmail,
        phone: phone,
        password: password,
        street: address,
        city: city,
        province: province,
        postalCode: postalCode,
        website: website,
        instagram: instagram,
        loginEmail: email,
        passwordConfirmation: confirmPassword,
        howHeard: howHeard,
      };
      addEmail(email);
      sessionStorage.setItem("email", email);
      dispatch(signUpArtist(data));
    }
  }, [
    errors,
    isSubmitting,
    address,
    artistName,
    studioName,
    firstName,
    lastName,
    profileEmail,
    phone,
    password,
    city,
    province,
    postalCode,
    website,
    instagram,
    email,
    confirmPassword,
    howDidHear,
    howDidHearOther,
  ]);

  const handleChange = (event) => {
    event.persist();
    event.target.value.trim();
    setIsSubmitting(false);
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
    if (event.target.name === "confirmPassword") {
      setConfirmPassword(event.target.value);
    }
    if (event.target.name === "artistName") {
      if (event.target.value.length <= 40) {
        setArtistName(event.target.value);
      }
    }
    if (event.target.name === "studioName") {
      if (event.target.value.length <= 40) {
        setStudioName(event.target.value);
      }
    }
    if (event.target.name === "firstName") {
      if (event.target.value.length <= 20) {
        setFirstName(event.target.value);
      }
    }
    if (event.target.name === "lastName") {
      if (event.target.value.length <= 20) {
        setLastName(event.target.value);
      }
    }
    if (event.target.name === "phone") {
      const input = event.target.value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 11);
      let phoneNumber;
      if (input.length > 6) {
        phoneNumber = `(${areaCode})${middle}-${last}`;
      } else if (input.length > 3) {
        phoneNumber = `(${areaCode})${middle}`;
      } else if (input.length > 0) {
        phoneNumber = `(${areaCode}`;
      }
      setPhone(phoneNumber);
    }
    if (event.target.name === "address") {
      setAddress(event.target.value);
    }
    if (event.target.name === "city") {
      setCity(event.target.value);
    }
    if (event.target.name === "province") {
      setProvince(event.target.value);
    }
    if (event.target.name === "postalCode") {
      setPostalCode(event.target.value);
    }
    if (event.target.name === "website") {
      setWebsite(event.target.value);
    }
    if (event.target.name === "instagram") {
      setInstagram(event.target.value);
    }
    if (event.target.name === "profileEmail") {
      setProfileEmail(event.target.value);
    }
    if (event.target.name === "howDidHear") {
      setHowDidHear(event.target.value);
    }
    if (event.target.name === "howDidHearOther") {
      if (event.target.value.length < 40) {
        setHowDidHearOther(event.target.value);
      }
    }
  };

  const signUpArtistSubmit = async (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    setErrors(
      validate(
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        phone,
        address,
        city,
        province,
        postalCode,
        profileEmail,
        artistName,
        website,
        howDidHear,
        howDidHearOther
      )
    );
  };

  return (
    <div className="artist_sign_up">
      <FormContainer
        title={t("FORM_FIELDS.SIGN_UP")}
        onFormSubmit={signUpArtistSubmit}
        navigatelink={() => navigate(-1)}
      >
        <CustomInput
          type="text"
          name="artistName"
          value={artistName}
          placeholder={t("FORM_FIELDS.ARTIST_NAME")}
          onInputChange={handleChange}
          errorMessage={t(errors.artistName)}
        />
        <CustomInput
          type="text"
          name="studioName"
          value={studioName}
          placeholder={t("FORM_FIELDS.STUDIO_NAME")}
          onInputChange={handleChange}
          errorMessage={t(errors.studioName)}
        />
        <CustomInput
          type="email"
          name="profileEmail"
          value={profileEmail}
          placeholder={t("FORM_FIELDS.PROFILE_EMAIL")}
          onInputChange={handleChange}
          errorMessage={t(errors.profileEmail)}
          required
        />
        <CustomInput
          type="tel"
          name="phone"
          value={phone}
          placeholder={t("FORM_FIELDS.PHONE")}
          onInputChange={handleChange}
          errorMessage={t(errors.phone)}
          required
        />
        <CustomInput
          type="text"
          name="address"
          value={address}
          placeholder={t("FORM_FIELDS.ADDRESS")}
          onInputChange={handleChange}
          errorMessage={t(errors.address)}
          required
        />
        <CustomInput
          type="text"
          name="city"
          value={city}
          placeholder={t("FORM_FIELDS.CITY")}
          onInputChange={handleChange}
          errorMessage={t(errors.city)}
          required
        />
        <select
          className="province_select"
          name="province"
          defaultValue={province}
          onChange={handleChange}
        >
          <option value="Province" disabled hidden>
            {t("FORM_FIELDS.PROVINCE")}
          </option>
          <option value="AB">{t("FORM_FIELDS.ALBERTA")}</option>
          <option value="BC">{t("FORM_FIELDS.BRITISH_COLUMBIA")}</option>
          <option value="MB">{t("FORM_FIELDS.MANITOBA")}</option>
          <option value="NB">{t("FORM_FIELDS.NEW_BRUNSWICK")}</option>
          <option value="NL">{t("FORM_FIELDS.NEWFOUNDLAND")}</option>
          <option value="NS">{t("FORM_FIELDS.NOVA_SCOTIA")}</option>
          <option value="NT">{t("FORM_FIELDS.NORTHWEST_TERRITORIES")}</option>
          <option value="NU">{t("FORM_FIELDS.NUNAVUT")}</option>
          <option value="ON">{t("FORM_FIELDS.ONTARIO")}</option>
          <option value="PE">{t("FORM_FIELDS.PEI")}</option>
          <option value="QC">{t("FORM_FIELDS.QUEBEC")}</option>
          <option value="SK">{t("FORM_FIELDS.SASKATCHEWAN")}</option>
          <option value="YT">{t("FORM_FIELDS.YUKON")}</option>
        </select>
        <CustomInput
          type="text"
          name="postalCode"
          value={postalCode}
          placeholder={t("FORM_FIELDS.POSTALCODE")}
          onInputChange={handleChange}
          errorMessage={t(errors.postalCode)}
          required
        />
        <CustomInput
          type="text"
          name="website"
          value={website}
          placeholder={t("FORM_FIELDS.WEBSITE")}
          onInputChange={handleChange}
          errorMessage={t(errors.website)}
        />
        <CustomInput
          type="text"
          name="instagram"
          value={instagram}
          placeholder={t("FORM_FIELDS.INSTAGRAM")}
          onInputChange={handleChange}
          errorMessage={t(errors.instagram)}
        />
        <CustomInput
          type="text"
          name="firstName"
          value={firstName}
          placeholder={t("FORM_FIELDS.FIRST_NAME")}
          onInputChange={handleChange}
          errorMessage={t(errors.firstName)}
          required
        />
        <CustomInput
          type="text"
          name="lastName"
          value={lastName}
          placeholder={t("FORM_FIELDS.LAST_NAME")}
          onInputChange={handleChange}
          errorMessage={t(errors.lastName)}
          required
        />
        <CustomInput
          type="email"
          name="email"
          value={email}
          placeholder={t("FORM_FIELDS.LOGIN_EMAIL")}
          onInputChange={handleChange}
          errorMessage={t(errors.email)}
          required
        />
        <PasswordInput
          name="password"
          value={password}
          placeholder={t("FORM_FIELDS.PASSWORD")}
          onInputChange={handleChange}
          errorMessage={t(errors.password)}
          required
        />
        <PasswordInput
          name="confirmPassword"
          value={confirmPassword}
          placeholder={t("FORM_FIELDS.CONFIRM_PASSWORD")}
          onInputChange={handleChange}
          errorMessage={t(errors.confirmPassword)}
          required
        />
        <select
          className="province_select"
          name="howDidHear"
          defaultValue={howDidHear}
          onChange={handleChange}
        >
          <option value="howDidHear">{t("FORM_FIELDS.HOW_DID_HEAR")}</option>
          <option value="social media">{t("FORM_FIELDS.SOCIAL_MEDIA")}</option>
          <option value="in person">{t("FORM_FIELDS.IN_PERSON")}</option>
          <option value="search">{t("FORM_FIELDS.ONLINE_SEARCH")}</option>
          <option value="other">{t("FORM_FIELDS.OTHER")}</option>
        </select>
        <p className={errors.howDidHear ? "general_error" : "hidden"}>
          {t(errors.howDidHear)}
        </p>
        {howDidHear === "other" ? (
          <CustomInput
            type="test"
            name="howDidHearOther"
            value={howDidHearOther}
            placeholder={t("FORM_FIELDS.HOW_DID_HEAR")}
            onInputChange={handleChange}
            errorMessage={t(errors.howDidHearOther)}
            required
          />
        ) : null}
        <p className={errorMessage ? "general_error" : "hidden"}>
          {errorMessage ? t(errorMessage) : null}
        </p>
        <div className="register_button">
          <LgGreenButton
            text={t("FORM_FIELDS.REGISTER")}
            onButtonClick={signUpArtistSubmit}
          />
        </div>
        <div className="register_secondary_buttons">
          <button onClick={() => navigate("/login")}>
            {t("NAVBAR.LOGIN")}
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default ArtistSignUp;
