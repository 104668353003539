import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Pagination } from "antd";
import ArtistCard from "../../Artists/ArtistCard/ArtistCard";
import {
  getArtistCount,
  getArtists,
  getArtistsInfo,
  getIndividualArtistPageState,
  getTargetArtist,
  getTargetArtistsData,
} from "../../../store/customerSlice";

import "./ArtistPageContent.css";


const ArtistPageContent = ({ searchTerm, pageIndex, setPageIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const artists = useSelector(getArtists);
  const pageSize = 6;
  const totalArtistCount = useSelector(getArtistCount);
  const individualArtistPageState = useSelector(getIndividualArtistPageState);
  const individualArtist = useSelector(getTargetArtist);

  const error = () => {
    console.log("error");
  };

  const success = (position) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    const data = {
      searchTerms: searchTerm,
      customerLocationLatitude: position.coords.latitude,
      customerLocationLongitude: position.coords.longitude,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    dispatch(getArtistsInfo(data));
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      const data = {
        searchTerms: searchTerm,
        customerLocationLatitude: latitude,
        customerLocationLongitude: longitude,
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      dispatch(getArtistsInfo(data));
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const data = {
        searchTerms: searchTerm,
        customerLocationLatitude: latitude,
        customerLocationLongitude: longitude,
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      dispatch(getArtistsInfo(data));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, dispatch, longitude, latitude, pageIndex]);

  const onChange = (page, pageSize) => {
    setPageIndex(page);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getTargetArtistsData(e.target.value));
  };
  const renderTags = () => {
    if (!individualArtist) {
      return <div></div>;
    }
    if (!individualArtist.tags) {
      return <div></div>;
    } else {
      return individualArtist.tags.split(",").map((tag) => {
        return (
          <div key={tag} className="tag_wrapper">
            <p className="tag">{tag}</p>
          </div>
        );
      });
    }
  };

  const renderImages = () => {
    if (!individualArtist) {
      return <div></div>;
    }
    if (!individualArtist.portfolioImages) {
      return <div></div>;
    } else {
      return individualArtist.portfolioImages.map((image) => {
        return (
          <img
            key={image.id}
            className="individual_artist_portfolio_image"
            src={image.url}
            alt=""
          />
        );
      });
    }
  };

  const renderIndividualArtist = !individualArtist ? (
    " "
  ) : (
    <div className="individual_artist_container">
      <div className="individual_artist_info">
        <div className="individual_artist_contact">
          <p>{individualArtist.profileEmail}</p>
          <span className="dot"></span>
          <p>{individualArtist.phone}</p>
          <span className="dot"></span>
          <p className="individual_artist_links">
            <a href={individualArtist.website}>{individualArtist.website}</a>
          </p>
          <span className="dot"></span>
          <p className="individual_artist_links">
            {individualArtist.instagram}
          </p>
        </div>
        <div className="individual_artist_tags">{renderTags()}</div>
      </div>
      <div className="individual_artist_portfolio">
        <div className="individual_artist_description">
          <h2 className="individual_artist_secondary_title">
            {t("ARTISTS.DESCRIPTION")}
          </h2>
          <p>{individualArtist.description}</p>
        </div>
        <div className="individual_artist_portfolio_image_container">
          <h2 className="individual_artist_secondary_title">
            {t("ARTISTS.PORTFOLIO")}
          </h2>
          <div className="individual_artist_portfolio_image_wrapper">
            {renderImages()}
          </div>
        </div>
      </div>
    </div>
  );
  const renderArtists = !artists
    ? ""
    : artists.map(
        ({
          id,
          artistName,
          studioName,
          bannerUrl,
          address,
          website,
          instagram,
        }) => {
          return (
            <ArtistCard
              key={id}
              id={id}
              artistName={artistName}
              studioName={studioName}
              bannerUrl={bannerUrl}
              address={address}
              website={website}
              instagram={instagram}
              onButtonClick={handleSubmit}
            />
          );
        }
      );

  return (
    <div className="artist_content_container">
      <div className="artist_content_info">
        {individualArtistPageState ? renderIndividualArtist : renderArtists}
      </div>
      {individualArtistPageState ? (
        ""
      ) : (
        <div className="page_numbers">
          <Pagination
            size="small"
            current={pageIndex}
            pageSize={pageSize}
            total={totalArtistCount}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default ArtistPageContent;
