import axios from "./api";

export function getContentType(type, language) {
    return axios({
      url: `/Setting/content/${type}/${language}`,
      method: "get",
    });
  }

  export function sendFeedback(data) {
    return axios({
      url: `/User/feedback`,
      method: "post",
      data
    });
  }
