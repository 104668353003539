import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import {
  login,
  verifyEmail,
  changePassword,
  resendConfirmationCode,
  forgotPassword,
  changePasswordCodeRequired,
  updateAcceptance,
} from "../api/user";

const initialState = {
  accessToken: null,
  refreshToken: null,
  expireTime: 0,
  role: "",
  loading: false,
  email: "",
  errorMessage: "",
  verifiedEmail: false,
  isLoggedIn: false,
  tosUpToDate: true,
  generalError: false,
  changePasswordModalState: false,
  forgotPasswordState: false,
  tosModalState: false,
  ppModalState: false,
};

export const loginUser = createAsyncThunk(
  "User/Signin",
  async (data, thunkAPI) => {
    try {
      let res = await login(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "USER_STATUS_UNCONFIRMED") {
          return thunkAPI.rejectWithValue("ERRORS.UNCONFIRMED_STATUS");
        }
        if (error.response.data.message === "USER_LOCKED_OUT") {
          return thunkAPI.rejectWithValue("ERRORS.USER_LOCKED_OUT");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        return thunkAPI.rejectWithValue(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(error);
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const userVerifyEmail = createAsyncThunk(
  "/User/verifyEmail",
  async (data, thunkAPI) => {
    try {
      let res = await verifyEmail(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        // Request made and server responded
        if (error.response.data.message === "USER_ALREADY_CONFIRMED") {
          return thunkAPI.rejectWithValue("ERRORS.USER_ALREADY_CONFIRMED");
        }
        if (error.response.data.message === "INVALID_USER") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_VERIFY");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "/User/changePassword",
  async (data, thunkAPI) => {
    try {
      let res = await changePassword(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        // Request made and server responded
        if (error.response.data.message === "PASSWORD_MISMATCH") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_PASSWORD");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);
export const resendUserConfirmationCode = createAsyncThunk(
  "/User/ResendEmailConfirmationCode",
  async (data, thunkAPI) => {
    try {
      let res = await resendConfirmationCode(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        // Request made and server responded
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const userForgotPassword = createAsyncThunk(
  "/User/ForgotPassword",
  async (data, thunkAPI) => {
    try {
      let res = await forgotPassword(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.message === "USER_STATUS_UNCONFIRMED") {
          return thunkAPI.rejectWithValue("ERRORS.UNCONFIRMED_STATUS");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        // Request made and server responded
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);
export const changeUserPasswordCodeRequired = createAsyncThunk(
  "/User/ChangePasswordCodeRequired",
  async (data, thunkAPI) => {
    try {
      let res = await changePasswordCodeRequired(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.message === "INVALID_USER") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_VERIFY");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        // Request made and server responded
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const updateTOSandPPAcceptance = createAsyncThunk(
  "/updateTOSandPPAcceptance",
  async (data, thunkAPI) => {
    try {
      let res = await updateAcceptance();
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.message === "INVALID_USER") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_VERIFY");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        // Request made and server responded
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    addEmail: (state, { payload }) => {
      state.email = payload;
    },
    addId: (state, { payload }) => {
      state.id = payload;
    },
    reset: (state) => {
      state = initialState;
    },
    toggleGeneralError: (state) => {
      state.generalError = !state.generalError;
    },
    toggleChangePasswordModalState: (state) => {
      state.changePasswordModalState = !state.changePasswordModalState;
    },
    resetForgotPassword: (state) => {
      state.forgotPasswordState = false;
    },
    updateTokens: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    closeTosModalState: (state) => {
      state.tosModalState = false;
    },
    togglePPModalState: (state) => {
      state.ppModalState = !state.ppModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [loginUser.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      let time = new Date().getTime();
      state.accessToken = payload.accessToken;
      state.expireTime = time + payload.expiresIn * 1000 - 10;
      state.refreshToken = payload.refreshToken;
      let decoded = jwt_decode(payload.accessToken);
      sessionStorage.setItem("access_token", payload.accessToken);
      sessionStorage.setItem("refresh_token", payload.refreshToken);
      sessionStorage.setItem("expire_time", state.expireTime);
      sessionStorage.setItem("isRetry", false);
      state.role = decoded["cognito:groups"][0];
      state.isLoggedIn = true;
      state.tosUpToDate = payload.tosUpToDate;
      if (payload.tosUpToDate === false) {
        state.tosModalState = true;
      }
    },
    [userVerifyEmail.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [userVerifyEmail.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [userVerifyEmail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.verifiedEmail = true;
    },
    [changeUserPassword.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [changeUserPassword.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [changeUserPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.changePasswordModalState = false;
    },
    [resendUserConfirmationCode.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [resendUserConfirmationCode.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [resendUserConfirmationCode.fulfilled]: (state) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
    },
    [userForgotPassword.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [userForgotPassword.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [userForgotPassword.fulfilled]: (state) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.forgotPasswordState = true;
    },
    [changeUserPasswordCodeRequired.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [changeUserPasswordCodeRequired.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [changeUserPasswordCodeRequired.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      let time = new Date().getTime();
      state.accessToken = payload.accessToken;
      state.expireTime = time + payload.expiresIn * 1000 - 10;
      state.refreshToken = payload.refreshToken;
      let decoded = jwt_decode(payload.accessToken);
      sessionStorage.setItem("access_token", payload.accessToken);
      sessionStorage.setItem("refresh_token", payload.refreshToken);
      sessionStorage.setItem("expire_time", state.expireTime);
      state.role = decoded["cognito:groups"][0];
      state.isLoggedIn = true;
    },
    [updateTOSandPPAcceptance.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [updateTOSandPPAcceptance.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [updateTOSandPPAcceptance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.tosUpToDate = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addEmail,
  addId,
  reset,
  toggleGeneralError,
  toggleChangePasswordModalState,
  resetForgotPassword,
  updateTokens,
  togglePPModalState,
  closeTosModalState,
  resetErrorMessage,
} = authSlice.actions;
export const getErrorMessage = (state) => state.auth.errorMessage;
export const getAccessToken = (state) => state.auth.accessToken;
export const getRefreshToken = (state) => state.auth.refreshToken;
export const getRole = (state) => state.auth.role;
export const getEmail = (state) => state.auth.email;
export const getVerifiedEmail = (state) => state.auth.verifiedEmail;
export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getGeneralError = (state) => state.auth.generalError;
export const getLoading = (state) => state.auth.loading;
export const getForgotPasswordState = (state) => state.auth.forgotPasswordState;
export const getChangePasswordModalState = (state) =>
  state.auth.changePasswordModalState;
export const getTosUpToDate = (state) => state.auth.tosUpToDate;
export const getTosModalState = (state) => state.auth.tosModalState;
export const getPPModalState = (state) => state.auth.ppModalState;
export default authSlice.reducer;
