import React,{useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Footer from "../../components/LandingPageComponent/Footer/Footer";
import Nav from "../../components/LandingPageComponent/Nav/nav";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import Loader from "../../components/Loader/Loader";

import image from "../../img//banner_eye/banner_eye@2x.png";

import { getAboutMessage, getAbout } from "../../store/contentSlice";

const About = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aboutContent = useSelector(getAboutMessage);

  useEffect(() => {
    dispatch(getAbout());
  }, []);

  return (
    <div>
      <Nav />
      <ErrorModal />
      <Loader />
      <div
        className="customer_header"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(38,38,38,1) 65%, rgba(38,38,38,0) 100%), url(${image})`,
        }}
      >
        <div className="customer_title">{t("NAVBAR.ABOUT")}</div>
      </div>
      <div className="profile_container">
        <div
          className="content_container"
          dangerouslySetInnerHTML={{ __html: aboutContent.htmlContent }}
        ></div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
