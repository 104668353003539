export const getDate = (isoDate) => {
  const date = new Date(isoDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return dt + "-" + month + "-" + year;
};

export const getDateWithTime = (isoDate) => {
  const date = new Date(isoDate);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();

  if (month < 10) {
    month = "0" + month;
  }

  let amPm = "AM";
  if (dt < 10) {
    dt = "0" + dt;
  }
  if (hour >= 12) {
    amPm = "PM";
  }
  hour = hour % 12;
  hour = hour ? hour : 12;

  if (minute.toString().length === 1) {
    minute = `0${minute}`;
  }

  return `${dt}-${month}-${year} ${hour}:${minute}${amPm}`;
};

export const htmlDecode = (input) => {
  var e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

export const notificationTitle = (notificationMessage) => {
  switch (notificationMessage) {
    case "SUBLOAN_APPROVED":
      return "NOTIFICATIONS.REDEMPTION_APPROVED";
    case "SUBLOAN_DECLINED":
      return "NOTIFICATIONS.REDEMPTION_DECLINED";
    case "SUBLOAN_CREATED":
      return "NOTIFICATIONS.REDEMPTION_REQUESTED";
    case "FIRST_LATE_PAYMENT":
      return "NOTIFICATIONS.LATE_PAYMENT";
    case "SECOND_LATE_PAYMENT":
      return "NOTIFICATIONS.LATE_PAYMENT";
    case "THIRD_LATE_PAYMENT":
      return "NOTIFICATIONS.LATE_PAYMENT";
    case "LOAN_EXPIRY_WARNING":
      return "NOTIFICATIONS.EXPIRY_SOON";
    case "LOAN_AWAITING_APPROVAL":
      return "NOTIFICATIONS.LOAN_AWAITING_APPROVAL";
    case "LOAN_APPROVED":
      return "NOTIFICATIONS.LOAN_APPROVED";
    case "LOAN_DENIED":
      return "NOTIFICATIONS.LOAN_DENIED";
    case "ACCOUNT_VERIFIED":
      return "NOTIFICATIONS.ACCOUNT_VERIFIED";
    case "ARTIST_DECLINED":
      return "NOTIFICATIONS.ARTIST_DECLINED";
    case "FEEDBACK_REPLY":
      return "NOTIFICATIONS.FEEDBACK_REPLY";
    case "ARTIST_PAYOUT":
      return "NOTIFICATIONS.ARTIST_PAYOUT";
    case "ARTIST_CANCELLED_LOAN":
      return "NOTIFICATIONS.ARTIST_CANCELLED_LOAN";
    case "REACTIVATE_LOAN":
      return "NOTIFICATIONS.REACTIVATE_LOAN";
    case "USER_BLOCK":
      return "NOTIFICATIONS.USER_BLOCK";
    case "USER_UNBLOCKED":
      return "NOTIFICATIONS.USER_UNBLOCKED";
    case "BLOCK_COMPLETED_LOAN":
      return "NOTIFICATIONS.BLOCK_COMPLETED_LOAN";
    case "LOAN_APPROVAL_CUSTOMER":
      return "NOTIFICATIONS.LOAN_APPROVAL_CUSTOMER";
    case "PAYMENT_SYSTEM_FAILURE":
      return "NOTIFICATIONS.PAYMENT_SYSTEM_FAILURE";
    default:
      return "NOTIFICATIONS.ERROR";
  }
};

export const notificationMessage = (notification) => {
  switch (notification.messageId) {
    case "SUBLOAN_APPROVED":
      return {
        message: "NOTIFICATIONS.REDEMPTION_APPROVED_MESSAGE",
        data: {
          name:
            notification.data.customerFirstName +
            " " +
            notification.data.customerLastName,
          amount: Number(notification.data.requestAmount).toFixed(2),
        },
      };
    case "SUBLOAN_DECLINED":
      return {
        message: "NOTIFICATIONS.REDEMPTION_DECLINED_MESSAGE",
        data: {
          amount: Number(notification.data.requestAmount).toFixed(2),
          name:
            notification.data.customerFirstName +
            " " +
            notification.data.customerLastName,
        },
      };
    case "SUBLOAN_CREATED":
      return {
        message: "NOTIFICATIONS.REDEMPTION_REQUESTED_MESSAGE",
        data: {
          amount: Number(notification.data.requestAmount).toFixed(2),
          name: notification.data.studioName,
        },
      };
    case "FIRST_LATE_PAYMENT":
      return {
        message: "NOTIFICATIONS.FIRST_LATE_PAYMENT_MESSAGE",
        data: {
          amount: Number(notification.data.paymentAmount).toFixed(2),
        },
      };
    case "SECOND_LATE_PAYMENT":
      return {
        message: "NOTIFICATIONS.SECOND_LATE_PAYMENT_MESSAGE",
        data: {
          amount: Number(notification.data.paymentAmount).toFixed(2),
          daysLate: notification.data.daysLate,
        },
      };
    case "THIRD_LATE_PAYMENT":
      return { message: "NOTIFICATIONS.THIRD_LATE_PAYMENT_MESSAGE" };
    case "LOAN_EXPIRY_WARNING":
      return {
        message: "NOTIFICATIONS.EXPIRY_SOON_MESSAGE",
        data: {
          amount: Number(notification.data.approvedAmount).toFixed(2),
          date: notification.data.expireDate,
        },
      };
    case "LOAN_AWAITING_APPROVAL":
      return {
        message: "NOTIFICATIONS.LOAN_AWAITING_APPROVAL_MESSAGE",
        data: {
          name:
            notification.data.customerFirstName +
            " " +
            notification.data.customerLastName,
          amount: Number(notification.data.requestAmount).toFixed(2),
          artistName: notification.data.studioName,
        },
      };
    case "LOAN_APPROVED":
      return {
        message: "NOTIFICATIONS.LOAN_APPROVED_MESSAGE",
        data: {
          amount: Number(notification.data.approvedAmount).toFixed(2),
        },
      };
    case "LOAN_DENIED":
      return {
        message: "NOTIFICATIONS.LOAN_DENIED_MESSAGE",
        data: {
          amount: Number(notification.data.deniedAmount).toFixed(2),
        },
      };
    case "ACCOUNT_VERIFIED":
      return { message: "NOTIFICATIONS.ACCOUNT_VERIFIED_MESSAGE" };
    case "ARTIST_DECLINED":
      return { message: "NOTIFICATIONS.ARTIST_DECLINED_MESSAGE" };
    case "FEEDBACK_REPLY":
      return {
        message: "NOTIFICATIONS.FEEDBACK_REPLY_MESSAGE",
        data: {
          name: notification.data.customerFirstName,
        },
      };
    case "ARTIST_PAYOUT":
      return {
        message: "NOTIFICATIONS.ARTIST_PAYOUT_MESSAGE",
        data: {
          name: notification.data.studioName,
          amount: Number(notification.data.payoutAmount).toFixed(2),
        },
      };
    case "ARTIST_CANCELLED_LOAN":
      return {
        message: "NOTIFICATIONS.ARTIST_CANCELLED_LOAN_MESSAGE",
        data: {
          customerName: notification.data.custonerFirstName,
          customerEmail: notification.data.customerEmail,
          data: notification.data.requestDate,
          name: notification.data.studioName,
          address: notification.data.studioAddress,
          amount: notification.data.requestAmount,
        },
      };
    case "REACTIVATE_LOAN":
      return {
        message: "NOTIFICATIONS.REACTIVATE_LOAN_MESSAGE",
        data: {
          amount: Number(notification.data.approvedAmount).toFixed(2),
        },
      };
    case "USER_BLOCK":
      return { message: "NOTIFICATIONS.USER_BLOCK_MESSAGE" };
    case "USER_UNBLOCKED":
      return { message: "NOTIFICATIONS.USER_UNBLOCKED_MESSAGE" };
    case "BLOCK_COMPLETED_LOAN":
      return {
        message: "NOTIFICATIONS.BLOCK_COMPLETED_LOAN_MESSAGE",
        data: {
          amount: Number(notification.data.approvedAmount).toFixed(2),
        },
      };
    case "LOAN_APPROVAL_CUSTOMER":
      return {
        message: "NOTIFICATIONS.LOAN_APPROVAL_CUSTOMER_MESSAGE",
        data: {
          amount: Number(notification.data.approvedAmount).toFixed(2),
        },
      };
    case "PAYMENT_SYSTEM_FAILURE":
      return {
        message: "NOTIFICATIONS.PAYMENT_SYSTEM_FAILURE_MESSAGE",
        data: {
          amount: Number(notification.data.paymentAmount).toFixed(2),
        },
      };
    default:
      return { message: "NOTIFICATIONS.ERROR_MESSAGE" };
  }
};
