import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ArtistNav from "../ArtistNav/ArtistNav";
import ArtistFooter from "../ArtistFooter/ArtistFooter";
import ErrorModal from "../../Modals/ErrorModal/ErrorModal";
import Loader from "../../Loader/Loader";

import waitingIcon from "../../../img/icon/Icon_waiting.svg";
import verifiedIcon from "../../../img/icon/icon_verified.svg";
import declinedIcon from "../../../img/icon/icon_x_white.svg";

import { getArtistInfo } from "../../../store/artistSlice";

import "./ArtistPageWrapper.css";

const ArtistPageWrapper = ({ title, children }) => {
  const { t } = useTranslation();
  const { artistInfo } = useSelector(getArtistInfo);

  const renderStatus = () => {
    if (!artistInfo) {
      return <div></div>;
    }
    if (artistInfo.status === "Waiting_Verification") {
      return (
        <div className="status">
          <img src={waitingIcon} alt="waiting icon" />
          <p>{t("ARTISTS.AWAITING_VERIFICATION")}</p>
        </div>
      );
    } else if (artistInfo.status === "Active") {
      return (
        <div className="status_verified">
          <img src={verifiedIcon} alt="waiting icon" />
          <p>{t("ARTISTS.VERIFIED")}</p>
        </div>
      );
    } else if (artistInfo.status === "Declined") {
      return (
        <div className="status_declined">
          <img src={declinedIcon} alt="waiting icon" />
          <p>{t("ARTISTS.DECLINED")}</p>
        </div>
      );
    } else if (artistInfo.status === "Blocked") {
      return (
        <div className="status_declined">
          <img src={declinedIcon} alt="waiting icon" />
          <p>{t("ARTISTS.BLOCKED")}</p>
        </div>
      );
    }
  };

  return (
    <div>
      <ArtistNav />
      <ErrorModal />
      <Loader />
      <div className="artist_header">
        <h2 className="artist_title">{title}</h2>
        {renderStatus()}
      </div>
      <div>{children}</div>
      <ArtistFooter />
    </div>
  );
};

export default ArtistPageWrapper;
