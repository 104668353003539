import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import rightIcon from "../../../img/icon/icon_right_white.svg";

import {
  getCustomerLoansInfo,
  toggleApproveLoanModalState,
  toggleDeclineLoanModalState,
  setTargetLoan,
  toggleIndividualLoanViewMoreState,
} from "../../../store/customerSlice";

import { getDateWithTime } from "../../../utils/helpers";

import "react-circular-progressbar/dist/styles.css";
import "./LoanCard.css";

const LoanCard = ({
  status,
  isPaidOff,
  requestedAmount,
  term,
  id,
  artistName,
  created,
  approvedAmount = 0,
  usedAmount = 0,
  updatedOn,
  approvedOn,
  missedPaymentDate,
  subLoanRequestedAmount,
  subLoanRequestedDate,
  subLoanFee,
  subLoanTotal,
  subLoanMonthlyPayment,
  subLoanId,
  subLoanNextChargeDate,
  isHistory,
  sentToCollections,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loans = useSelector(getCustomerLoansInfo);
  const history = useSelector((state) => state.customer.customerHistory);

  const handleApprove = (e) => {
    e.preventDefault();
    const target = loans.filter((loan) => loan.loanId === e.target.value);
    dispatch(setTargetLoan(target[0]));
    dispatch(toggleApproveLoanModalState());
  };

  const handleDecline = (e) => {
    e.preventDefault();
    const target = loans.filter((loan) => loan.loanId === e.target.value);
    dispatch(setTargetLoan(target[0]));
    dispatch(toggleDeclineLoanModalState());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let target;
    if (isHistory) {
      target = history.filter((loan) => loan.loanId === e.target.value);
    } else {
      target = loans.filter((loan) => loan.loanId === e.target.value);
    }
    dispatch(setTargetLoan(target[0]));
    dispatch(toggleIndividualLoanViewMoreState());
  };

  const renderStatus = () => {
    if (
      status === "Loan_Awaiting_Approval" ||
      status === "Risk_Score_Waiting"
    ) {
      return (
        <h3 className="loan_card_status">
          {t("CUSTOMERS.STATUS_AWAITING_APPROVAL")}
        </h3>
      );
    } else if (
      status === "Loan_Approved" ||
      status === "Active" ||
      status === "SubLoan_Waiting_Approval" ||
      status === "Completed"
    ) {
      return (
        <h3 className="loan_card_status">{t("CUSTOMERS.STATUS_APPROVED")}</h3>
      );
    } else if (status === "In_Collections" && !sentToCollections) {
      return (
        <h3 className="loan_card_in_collections">
          {t("CUSTOMERS.STATUS_ADMIN_HOLD")}
        </h3>
      );
    }else if (status === "In_Collections" && sentToCollections) {
      return (
        <h3 className="loan_card_in_collections">
          {t("CUSTOMERS.STATUS_IN_COLLECTIONS")}
        </h3>
      );
    }
  };



  const renderLoanApproveMessage = () => {
    const monthNames = [
      t("CUSTOMERS.JANUARY"),
      t("CUSTOMERS.FEBRUARY"),
      t("CUSTOMERS.MARCH"),
      t("CUSTOMERS.APRIL"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUNE"),
      t("CUSTOMERS.JULY"),
      t("CUSTOMERS.AUGUST"),
      t("CUSTOMERS.SEPTEMBER"),
      t("CUSTOMERS.OCTOBER"),
      t("CUSTOMERS.NOVEMBER"),
      t("CUSTOMERS.DECEMBER"),
    ];
    const nextCharge = new Date(subLoanNextChargeDate);
    const date = new Date();
    if (
      nextCharge.getMonth() === date.getMonth() &&
      nextCharge.getDate() === date.getDate()
    ) {
      return t("CUSTOMERS.FIRST_PAYMENT_MESSAGE");
    } else {
      return t("CUSTOMERS.SECOND_PAYMENT_MESSAGE", {
        month: monthNames[nextCharge.getMonth()],
        day: nextCharge.getDate(),
      });
    }
  };

  const renderCardContent = () => {
    if (
      status === "Loan_Awaiting_Approval" ||
      status === "Risk_Score_Waiting"
    ) {
      return (
        <div className="loan_details_text_container">
          <div className="loan_details">
            <p className="loan_details_title">
              {t("CUSTOMERS.REQUESTED_AMOUNT")}
            </p>
            <p className="loan_details_values">${requestedAmount.toFixed(2)}</p>
            <p className="loan_details_title">
              {t("CUSTOMERS.REQUESTED_ON")} {getDateWithTime(created)}
            </p>
          </div>
        </div>
      );
    } else if (status === "Loan_Approved") {
      return (
        <div className="loan_approved_text">
          <div className="loan_details_approved_text_container">
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_AMOUNT")}
              </p>
              <p className="loan_details_values">
                ${requestedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_ON")} {getDateWithTime(created)}
              </p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.APPROVED_AMOUNT")}
              </p>
              <p className="loan_details_values">
                ${approvedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.APPROVED_ON")} {getDateWithTime(approvedOn)}
              </p>
            </div>
          </div>
          <div className="loan_approve_message">
            <p>
              {t("CUSTOMERS.APPROVED_MESSAGE1")}
              {approvedAmount.toFixed(2)}
              <Trans i18nKey="CUSTOMERS.APPROVED_MESSAGE2">
                through Ink Me Financials. You have
                <strong>30 days</strong>
                to begin your tattoo
              </Trans>
            </p>
          </div>
          <button
            className="button_view_more"
            value={id}
            onClick={handleSubmit}
          >
            {t("CUSTOMERS.VIEW_MORE")} <img src={rightIcon} alt="right Icon" />
          </button>
        </div>
      );
    } else if (status === "Active" || status === "Completed") {
      return (
        <div className="loan_approved_text">
          <div className="loan_details_text_container">
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_AMOUNT")}
              </p>
              <p className="loan_details_values">
                ${requestedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_ON")} {getDateWithTime(created)}
              </p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.APPROVED_AMOUNT")}
              </p>
              <p className="loan_details_values">
                ${approvedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.APPROVED_ON")} {getDateWithTime(approvedOn)}
              </p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">{t("CUSTOMERS.AMOUNT_USED")}</p>
              <p className="loan_details_values"> ${usedAmount.toFixed(2)}</p>
              <p className="loan_details_title">
                {t("CUSTOMERS.UPDATED_ON")} {getDateWithTime(updatedOn)}
              </p>
            </div>
          </div>
          {missedPaymentDate !== null ? (
            <div className="loan_details">
              <p className="loan_details_title_missed_payment">
                {t("CUSTOMERS.MISSED_PAYMENT_DATE")}{" "}
                {getDateWithTime(missedPaymentDate)}
              </p>
            </div>
          ) : null}
          <button
            className="button_view_more"
            value={id}
            onClick={handleSubmit}
          >
            {t("CUSTOMERS.VIEW_MORE")} <img src={rightIcon} alt="right Icon" />
          </button>
        </div>
      );
    } else if (status === "SubLoan_Waiting_Approval") {
      return (
        <div className="subloan_waiting_approval_loan_approved_text">
          <div className="subloan_waiting_approval_loan_details_text_container">
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.ARTIST_REQUESTED")}
              </p>
              <p className="loan_details_values">
                ${subLoanRequestedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_ON")}{" "}
                {getDateWithTime(subLoanRequestedDate)}
              </p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">{t("CUSTOMERS.FEES")}</p>
              <p className="loan_details_values"> ${subLoanFee.toFixed(2)}</p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">{t("CUSTOMERS.TOTAL")}</p>
              <p className="loan_details_values">${subLoanTotal.toFixed(2)}</p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.MONTLY_PAYMENT", { term: term })}
              </p>
              <p className="loan_details_values">
                ${subLoanMonthlyPayment.toFixed(2)}
              </p>
            </div>
          </div>
          <div className="loan_approve_message">
            <p>{renderLoanApproveMessage()}</p>
          </div>
          <button
            className="button_view_more"
            value={id}
            onClick={handleSubmit}
          >
            {t("CUSTOMERS.VIEW_MORE")} <img src={rightIcon} alt="right Icon" />
          </button>
        </div>
      );
    } else if (status === "In_Collections") {
      return (
        <div className="loan_approved_text">
          <div className="loan_details_text_container">
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_AMOUNT")}
              </p>
              <p className="loan_details_values">
                ${requestedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.REQUESTED_ON")} {getDateWithTime(created)}
              </p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">
                {t("CUSTOMERS.APPROVED_AMOUNT")}
              </p>
              <p className="loan_details_values">
                ${approvedAmount.toFixed(2)}
              </p>
              <p className="loan_details_title">
                {t("CUSTOMERS.APPROVED_ON")} {getDateWithTime(approvedOn)}
              </p>
            </div>
            <div className="loan_details">
              <p className="loan_details_title">{t("CUSTOMERS.AMOUNT_USED")}</p>
              <p className="loan_details_values"> ${usedAmount.toFixed(2)}</p>
              <p className="loan_details_title">
                {t("CUSTOMERS.UPDATED_ON")} {getDateWithTime(updatedOn)}
              </p>
            </div>
          </div>
          {missedPaymentDate !== null ? (
            <div className="loan_details">
              <p className="loan_details_title_missed_payment">
                {t("CUSTOMERS.MISSED_PAYMENT_DATE")}{" "}
                {getDateWithTime(missedPaymentDate)}
              </p>
            </div>
          ) : null}
          <button
            className="button_view_more"
            value={id}
            onClick={handleSubmit}
          >
            {t("CUSTOMERS.VIEW_MORE")} <img src={rightIcon} alt="right Icon" />
          </button>
        </div>
      );
    }
  };

  const renderButtons = () => {
    if (
      status === "Loan_Awaiting_Approval" ||
      status === "Risk_Score_Waiting"
    ) {
      return (
        <button className="lg_button_inactive" disabled>
          {t("CUSTOMERS.AWAITING_APPROVAL")}
        </button>
      );
    } else if (status === "SubLoan_Waiting_Approval") {
      return (
        <div className="approve_loan_buttons">
          <button className="sm_approve" value={id} onClick={handleApprove}>
            {t("CUSTOMERS.APPROVE")}
          </button>
          <button className="sm_decline" value={id} onClick={handleDecline}>
            {t("CUSTOMERS.DECLINE")}
          </button>
        </div>
      );
    } else if (status === "Completed" && isPaidOff) {
      return (
        <button className="lg_button_inactive" disabled>
          {t("CUSTOMERS.COMPLETED")}
        </button>
      );
    }
  };
  return (
    <div className="loan_card_container">
      <div className="loan_cicle_container">
        <div className="loan_circle_size">
          <CircularProgressbarWithChildren
            strokeWidth={3}
            counterClockwise={true}
            value={
              subLoanRequestedAmount
                ? subLoanRequestedAmount + usedAmount
                : usedAmount
            }
            maxValue={approvedAmount === 0 ? requestedAmount : approvedAmount}
            styles={buildStyles({
              pathColor: "var(--green)",
              trailColor: "#7c7c7c",
            })}
          >
            <h3 className="circle_amount_used">
              $
              {subLoanRequestedAmount
                ? (subLoanRequestedAmount + usedAmount).toFixed(2)
                : usedAmount.toFixed(2)}
            </h3>
            <p className="circle_requested_amount">
              {t("CUSTOMERS.CIRCLE_MESSAGE", {
                total:
                  approvedAmount === 0
                    ? requestedAmount.toFixed(2)
                    : approvedAmount.toFixed(2),
              })}
            </p>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <div className="loan_details_container">
        <div className="loan_card_header">
          <h2 className="loan_card_title">{artistName}</h2>
          {renderButtons()}
        </div>
        {renderStatus()}
        {renderCardContent()}
      </div>
    </div>
  );
};

export default LoanCard;
