export default function validate(
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phone,
  address,
  city,
  province,
  postalCode,
  profileEmail,
  artistName,
  website,
  howDidHear,
  howDidHearOther
) {
  let errors = {};
  const regexEmail = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const regexPhone = new RegExp(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  );
  const regexPostalCode = new RegExp(
    /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i
  );
  const regexPassword = new RegExp(
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/
  );
  const regexName = new RegExp(/^[0-9a-zA-Z]+$/);
  if (!email) {
    errors.email = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(email)) {
    errors.email = "ERRORS.VALID_EMAIL";
  }
  if (!password) {
    errors.password = "ERRORS.PASSWORD_REQUIRED";
  }
  if (confirmPassword === "") {
    errors.confirmPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (password !== confirmPassword) {
    errors.confirmPassword = "ERRORS.PASSWORDS_MUST_MATCH";
  }
  if (!regexPassword.test(password)) {
    errors.confirmPassword = "ERRORS.VALID_PASSWORD";
  }
  if (!firstName) {
    errors.firstName = "ERRORS.FIRSTNAME_REQUIRED";
  } else if (!regexName.test(firstName)) {
    errors.firstName = "ERRORS.VALID_NAME";
  }
  if (!lastName) {
    errors.lastName = "ERRORS.LASTNAME_REQUIRED";
  } else if (!regexName.test(lastName)) {
    errors.lastName = "ERRORS.VALID_NAME";
  }
  if (!phone) {
    errors.phone = "ERRORS.PHONE_REQUIRED";
  } else if (!regexPhone.test(phone)) {
    errors.phone = "ERRORS.VALID_PHONE";
  }
  if (!address) {
    errors.address = "ERRORS.ADDRESS_REQUIRED";
  }
  if (!city) {
    errors.city = "ERRORS.CITY_REQUIRED";
  }
  if (!province) {
    errors.province = "ERRORS.PROVINCE_REQUIRED";
  }
  if (!postalCode) {
    errors.postalCode = "ERRORS.POSTALCODE_REQUIRED";
  } else if (!regexPostalCode.test(postalCode)) {
    errors.postalCode = "ERRORS.VALID_POSTALCODE";
  }
  if (!profileEmail) {
    errors.profileEmail = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(email)) {
    errors.profileEmail = "ERRORS.VALID_EMAIL";
  }
  if (!artistName) {
    errors.artistName = "ERRORS.ARTIST_NAME_REQUIRED";
  }
  if (website) {
    if (
      !(website.indexOf("http://") === 0 || website.indexOf("https://") === 0)
    ) {
      errors.website = "ERRORS.VALID_WEBSITE";
    }
  }
  if (!howDidHear || howDidHear === "howDidHear") {
    errors.howDidHear = "ERRORS.HOW_DID_HEAR_REQUIRED";
  }
  if (howDidHear === "other") {
    if (!howDidHearOther) {
      errors.howDidHearOther = "ERRORS.HOW_DID_HEAR_REQUIRED";
    }
  }
  return errors;
}
