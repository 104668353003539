import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SuccessModal from "../Modals/SuccessModal/SuccessModal";
import LgGreenButton from "../ButtonsFormFields/LgGreenButton/LgGreenButton";

import { sendFeedback } from "../../api/content";

import "./Feedback.css";




const Feedback = () => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState("subject");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [toggleSuccess, setToggleSuccess] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "message") {
      setMessage(e.target.value);
    }
    if (e.target.name === "feedback") {
      setSubject(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.length === 0) {
    } else {
      const data = {
        subject: subject,
        message: message,
      };
      try {
        const res = await sendFeedback(data);
        setMessage("");
        setSubject("subject");
        setToggleSuccess(true);
        setTimeout(() => {
          setToggleSuccess(false);
        }, 1500);
      } catch (error) {
        console.log(error.response);
        setError(t("ERRORS.GENERAL_ERROR"));
      }
    }
  };

  return (
    <div className="feedback_container">
      <select
        className="feedback_subject_select"
        name="feedback"
        defaultValue={subject}
        onChange={handleChange}
      >
        <option value="subject" disabled>
          {t("FORM_FIELDS.SELECT_SUBJECT")}
        </option>
        <option value="Bug_Reports">{t("FORM_FIELDS.BUG_REPORTS")}</option>
        <option value="Feature_Request">
          {t("FORM_FIELDS.FEATURE_REQUEST")}
        </option>
        <option value="Complaint">{t("FORM_FIELDS.COMPLAINT")}</option>
        <option value="Help_Request">{t("FORM_FIELDS.HELP_REQUEST")}</option>
      </select>
      <textarea
        className="feedback_message"
        name="message"
        placeholder="Message"
        value={message}
        onChange={(e) => handleChange(e)}
      ></textarea>
      <LgGreenButton
        text={t("FORM_FIELDS.SUBMIT")}
        onButtonClick={(e) => handleSubmit(e)}
      />
      <p className="error_message">{error}</p>
      {toggleSuccess ? (
        <SuccessModal title={"FORM_FIELDS.FEEDBACK_SUBMITTED"} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Feedback;
