import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getNotificationsFromServer } from "../../store/notificationSlice";
import { readNotifications } from "../../api/user";

import {
  getDateWithTime,
  notificationTitle,
  notificationMessage,
} from "../../utils/helpers";

import "./NotificationDropdown.css";

const NotificationDropdown = ({ navigateTo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );


  useEffect(() => {
    const readNotificationsOnDropdown = async (data) => {
      try {
        const res = await readNotifications(data);
      } catch (error) {
        console.log(error.response);
      }
    };
    const data = {
      pageIndex: 1,
      pageSize: 5,
    };
    dispatch(getNotificationsFromServer(data));
    readNotificationsOnDropdown(data);
  }, []);

  const renderNotifications = () => {
    if (!notifications) {
      return <div>{t("NAVBAR.NO_NOTIFICATIONS")}</div>;
    } else {
      return notifications.noteVMs.map((notification) => {
        const message = notificationMessage(notification);
        return (
          <div
            key={notification.id}
            className={
              notification.read === "0001-01-01T00:00:00"
                ? "notification_dropdown_content_container notification_dropdown_content_container_unread"
                : "notification_dropdown_content_container"
            }
          >
            <div className="notification_dropdown_title_container">
              <h3>{t(notificationTitle(notification.messageId))}</h3>
              <p>{getDateWithTime(notification.created)}</p>
            </div>
            <p className="notification_dropdown_message">
              {t(message.message, message.data)}
            </p>
          </div>
        );
      });
    }
  };
  return (
    <div className="notification_dropdown_background_container">
      <div className="notification_dropdown_container">
        <div className="notifications">
          {renderNotifications()}
        </div>
        <div
          className="notifications_link"
          onClick={() => navigate(navigateTo)}
        >
          {t("CUSTOMERS.SEE_ALL")}
        </div>
      </div>
    </div>
  );
};

export default NotificationDropdown;
