import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import NotificationDropdown from "../../NotificationDropdown/NotificationDropdown";

import accountIcon from "../../../img/icon/icon_profile.svg";
import accountIconGreen from "../../../img/icon/icon_profile_green.svg";
import historyIcon from "../../../img/icon/icon_history.svg";
import historyIconGreen from "../../../img/icon/icon_history_green.svg";
import ratesIcon from "../../../img/icon/icon_rates.svg";
import ratesIconGreen from "../../../img/icon/icon_rates_green.svg";
import notificationsIcon from "../../../img/icon/icon_notifications_white.svg";
import notificationsIconGreen from "../../../img/icon/icon_notifications_green.svg";
import artistsIcon from "../../../img/icon/icon_tattoo.svg";
import artistsIconGreen from "../../../img/icon/icon_tattoo_green.svg";
import homeIcon from "../../../img/icon/icon_home.svg";
import homeIconGreen from "../../../img/icon/icon_home_green.svg";
import menuIcon from "../../../img/icon/icon_collapse.svg";
import xIcon from "../../../img/icon/icon_x.svg";
import logo from "../../../img/logo/logo_hor@2x.png";
import unReadNotificationsIcon from "../../../img/icon/icon_notifcation_badge.svg";
import unReadNotificationsIconGreen from "../../../img/icon/icon_notifcation_badge_green.svg";

import { getNotificationsFromServer } from "../../../store/notificationSlice";

import "./CustomerNav.css";

const CustomerNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [unread, setUnread] = useState(false);
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    const toggleNotificationDropdown = (e) => {
      if (activeLink === "/customerNotifications") {
        setToggleDropdown(false);
        setActiveLink(location.pathname);
      }
    };
    if (toggleDropdown) {
      window.addEventListener("click", (e) => toggleNotificationDropdown(e));
    } else {
      window.removeEventListener("click", (e) => toggleNotificationDropdown(e));
    }
    return () =>
      window.removeEventListener("click", toggleNotificationDropdown());
  }, [toggleDropdown, location.pathname, activeLink]);

  useEffect(() => {
    const data = {
      pageIndex: 1,
      pageSize: 5,
    };
    dispatch(getNotificationsFromServer(data));
  }, []);

  useEffect(() => {
    if (!notifications || !notifications.notifications) {
    } else {
      setUnread(
        notifications.notifications.noteVMs.some(
          (notification) => notification.read === "0001-01-01T00:00:00"
        )
      );
    }
  }, [notifications]);

  useEffect(() => {
    if (activeLink === "/customerNotifications") {
    } else {
      setActiveLink(location.pathname);
      setToggleDropdown(false);
    }
  }, [activeLink, location.pathname]);

  const handleNotificationDropdown = () => {
    if (activeLink === "/customerNotifications") {
    } else {
      setToggleDropdown(true);
      setActiveLink("/customerNotifications");
    }
  };

  const renderNotificationIcon = () => {
    if (activeLink === "/customerNotifications") {
      if (unread) {
        return unReadNotificationsIconGreen;
      } else {
        return notificationsIconGreen;
      }
    } else {
      if (unread) {
        return unReadNotificationsIcon;
      } else {
        return notificationsIcon;
      }
    }
  };

  return (
    <nav className="customer_nav">
      <div
        className={toggleMenu ? "customer_navbar show-nav" : "customer_navbar"}
      >
        <div className="customer_nav_logo">
          <img src={logo} alt="Ink Me Financial Logo" />
        </div>
        <div className="customer_nav_link">
          <NavLink
            className={
              activeLink === "/customer" || activeLink === "/customer/"
                ? "customer_nav_active"
                : "customer_nav_icon_link"
            }
            to="/customer"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/customer" || activeLink === "/customer/"
                  ? homeIconGreen
                  : homeIcon
              }
              alt="home icon"
            />
            {t("NAVBAR.HOME")}
          </NavLink>
        </div>
        <div className="customer_nav_link">
          <NavLink
            className={
              activeLink === "/customerArtists"
                ? "customer_nav_active"
                : "customer_nav_icon_link"
            }
            to="/customerArtists"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/customerArtists"
                  ? artistsIconGreen
                  : artistsIcon
              }
              alt="artist icon"
            />
            {t("NAVBAR.ARTISTS")}
          </NavLink>
        </div>
        <div className="customer_nav_link">
          <NavLink
            className={
              activeLink === "/customerHistory"
                ? "customer_nav_active"
                : "customer_nav_icon_link"
            }
            to="/customerHistory"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/customerHistory"
                  ? historyIconGreen
                  : historyIcon
              }
              alt="history icon"
            />
            {t("NAVBAR.HISTORY")}
          </NavLink>
        </div>
        <div className="customer_nav_link">
          <NavLink
            className={
              activeLink === "/customerRatesTerms"
                ? "customer_nav_active"
                : "customer_nav_icon_link"
            }
            to="/customerRatesTerms"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/customerRatesTerms"
                  ? ratesIconGreen
                  : ratesIcon
              }
              alt="rates and terms icon"
            />
            {t("NAVBAR.RATES_TERMS")}
          </NavLink>
        </div>
        <div className="customer_nav_link">
          <div
            className={
              activeLink === "/customerNotifications"
                ? "customer_nav_active"
                : "customer_nav_icon_link"
            }
            onClick={() => handleNotificationDropdown()}
          >
            <img
              className="icon"
              src={renderNotificationIcon()}
              alt="notifications icon"
            />
            {t("NAVBAR.NOTIFICATIONS")}
          </div>
          {toggleDropdown ? (
            <NotificationDropdown navigateTo={"/customerNotifications"} />
          ) : (
            ""
          )}
        </div>
        <div className="customer_nav_link">
          <NavLink
            className={
              activeLink === "/customerProfile"
                ? "customer_nav_active"
                : "customer_nav_icon_link"
            }
            to="/customerProfile"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/customerProfile"
                  ? accountIconGreen
                  : accountIcon
              }
              alt="account icon"
            />
            {t("NAVBAR.ACCOUNT")}
          </NavLink>
        </div>
      </div>
      <button
        className="customer_nav_collapse"
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        {toggleMenu ? (
          <img className="nav_menu_icon" src={xIcon} alt="menu close icon" />
        ) : (
          <img className="nav_menu_icon" src={menuIcon} alt="menu icon" />
        )}
      </button>
    </nav>
  );
};

export default CustomerNav;
