import React from "react";
import { useTranslation } from "react-i18next";

import ErrorModal from "../Modals/ErrorModal/ErrorModal";
import Loader from '../Loader/Loader';

import logo from "../../img/logo/logo_main@2x.png";
import arrowLeftIcon from "../../img/icon/icon_arrow_left.svg";

import "./FormContainer.css";

const FormContainer = ({ children, title, onFormSubmit, navigatelink }) => {
  const { t } = useTranslation();
  return (
    <div className="form_container">
      <ErrorModal />
      <Loader />
      <div className="form_inputs">
        <form className="form" onSubmit={onFormSubmit}>
          <h3 className="form_title">
            <img
              onClick={navigatelink}
              src={arrowLeftIcon}
              alt="left arrow icon"
            ></img>
            {title}
          </h3>
          <div className="form_input_content">{children}</div>
        </form>
      </div>
      <div className="form_content">
        <img src={logo} alt="Ink Me Financial Logo" />
        <p>{t("FORM_FIELDS.FORM_CONTENT")}</p>
      </div>
    </div>
  );
};

export default FormContainer;
