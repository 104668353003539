export default function validate(
  profileEmail,
  phone,
  address,
  city,
  province,
  postalCode,
  artistName,
  firstName,
  lastName,
  website
) {
  let errors = {};
  const regexEmail =  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const regexPhone = new RegExp(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  );
  const regexPostalCode = new RegExp(
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
  );
  const regexName = new RegExp(/^[0-9a-zA-Z]+$/);
  if (!firstName) {
    errors.firstName = "ERRORS.FIRSTNAME_REQUIRED";
  } else if (!regexName.test(firstName)) {
    errors.firstName = "ERRORS.VALID_NAME";
  }
  if (!lastName) {
    errors.lastName = "ERRORS.LASTNAME_REQUIRED";
  } else if (!regexName.test(lastName)) {
    errors.lastName = "ERRORS.VALID_NAME";
  }
  if (!phone) {
    errors.phone = "ERRORS.PHONE_REQUIRED";
  } else if (!regexPhone.test(phone)) {
    errors.phone = "ERRORS.VALID_PHONE";
  }
  if (!address) {
    errors.address = "ERRORS.ADDRESS_REQUIRED";
  }
  if (!city) {
    errors.city = "ERRORS.CITY_REQUIRED";
  }
  if (!province) {
    errors.province = "ERRORS.PROVINCE_REQUIRED";
  }
  if (!postalCode) {
    errors.postalCode = "ERRORS.POSTALCODE_REQUIRED";
  } else if (!regexPostalCode.test(postalCode)) {
    errors.postalCode = "ERRORS.VALID_POSTALCODE";
  }
  if (!profileEmail) {
    errors.profileEmail = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(profileEmail)) {
    errors.profileEmail = "ERRORS.VALID_EMAIL";
  }
  if (!artistName) {
    errors.artistName = "ERRORS.ARTIST_NAME_REQUIRED";
  }
  if (website) {
    if (
      !(website.indexOf("http://") === 0 || website.indexOf("https://") === 0)
    ) {
      errors.website = "ERRORS.VALID_WEBSITE";
    }
  }
  return errors;
}
