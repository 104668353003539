export default function validate(phone, firstName, lastName) {
  let errors = {};
  const regexPhone = new RegExp(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  );
  const regexName = new RegExp(/^[0-9a-zA-Z]+$/);
  if (!firstName) {
    errors.firstName = "ERRORS.FIRSTNAME_REQUIRED";
  } else if (!regexName.test(firstName)) {
    errors.firstName = "ERRORS.VALID_NAME";
  }
  if (!lastName) {
    errors.lastName = "ERRORS.LASTNAME_REQUIRED";
  } else if (!regexName.test(lastName)) {
    errors.lastName = "ERRORS.VALID_NAME";
  }
  if (!phone) {
    errors.phone = "ERRORS.PHONE_REQUIRED";
  } else if (!regexPhone.test(phone)) {
    errors.phone = "ERRORS.VALID_PHONE";
  }
  return errors;
}
