import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import FormContainer from "../../components/FormContainer/FormContainer";
import CustomInput from "../../components/ButtonsFormFields/CustomInput/CustomInput";
import LgGreenButton from "../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";

import {
  getErrorMessage,
  getVerifiedEmail,
  userVerifyEmail,
  resendUserConfirmationCode,
  resetErrorMessage,
} from "../../store/authSlice";

import validate from "./VerifyAccountFormValidationRules";
import "./VerifyAccount.css";
import { resetArtistInfo } from "../../store/artistSlice";
import { resetCustomerInfo } from "../../store/customerSlice";

const VerifyAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [securityCode, setSecurityCode] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const verifiedEmail = useSelector(getVerifiedEmail);
  const errorMessage = useSelector(getErrorMessage);

  useEffect(() => {
    if (errorMessage === "ERRORS.USER_ALREADY_CONFIRMED") {
      navigate("/login");
    }
    if (verifiedEmail === true) {
      navigate("/login");
    }
  }, [verifiedEmail, errorMessage, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        email: sessionStorage.getItem("email"),
        verificationCode: securityCode.trim(),
      };
      dispatch(userVerifyEmail(data));
    }
  }, [errors, isSubmitting, securityCode, dispatch]);

  useEffect(() => {
    dispatch(resetErrorMessage());

    return () => {
      dispatch(resetArtistInfo());
      dispatch(resetCustomerInfo());
    };
  }, []);

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    if (event.target.name === "securityCode") {
      setSecurityCode(event.target.value);
    }
  };

  const onVerifyAccountSubmit = async (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    setErrors(validate(securityCode));
  };

  const onResendConfirmationCodeSubmit = async (event) => {
    if (event) event.preventDefault();
    const data = {
      email: sessionStorage.getItem("email"),
    };
    dispatch(resendUserConfirmationCode(data));
  };

  return (
    <div className="verify_account">
      <FormContainer
        title={t("FORM_FIELDS.VERIFY_ACCOUNT")}
        onFormSubmit={onVerifyAccountSubmit}
        navigatelink={() => {
          navigate(-1) || navigate("/login");
          dispatch(resetArtistInfo());
          dispatch(resetCustomerInfo());
        }}
      >
        <p className="verify_instructions">
          {t("FORM_FIELDS.VERIFY_INSTRUCTIONS")}
        </p>
        <CustomInput
          type="text"
          name="securityCode"
          value={securityCode}
          placeholder={t("FORM_FIELDS.ENTER_CODE")}
          onInputChange={handleChange}
          errorMessage={t(errors.securityCode)}
          required
        />
        <p className={errorMessage ? "general_error" : "hidden"}>
          {t(errorMessage)}
        </p>
        <div className="resend_code_button">
          <button
            className="resend_button"
            onClick={onResendConfirmationCodeSubmit}
          >
            {t("FORM_FIELDS.RE_SEND_CODE")}
          </button>
        </div>
        <div className="verify_account_button">
          <LgGreenButton
            text={t("FORM_FIELDS.VERIFY_ACCOUNT")}
            onButtonClick={onVerifyAccountSubmit}
          />
        </div>
      </FormContainer>
    </div>
  );
};

export default VerifyAccount;
