import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import { editArtistDescription } from "../../../store/artistSlice";

import "./ArtistEditDescription.css";

const ArtistEditDescription = ({ artistDescription, onModalClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [description, setDescriptionForm] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!artistDescription) {
      setDescriptionForm("");
      setCount(0);
    } else {
      setDescriptionForm(artistDescription);
      setCount(artistDescription.length);
    }
  }, [artistDescription]);

  const handleChange = (e) => {
    if (description.length <= 500) {
      setDescriptionForm(e.target.value);
      setCount(description.length);
    }
  };
  const saveChanges = () => {
    const data = {
      description: description,
    };
    dispatch(editArtistDescription(data));
  };

  return (
    <div>
      <ModalContainer
        title={t("ARTISTS.EDIT_PROFILE_DETAILS")}
        onButtonClose={onModalClose}
      >
        <textarea
          className="edit_description"
          name="description"
          value={description}
          onChange={handleChange}
        ></textarea>
        <p className="text_count">{count}/500</p>
        <div className="save_changes_button">
          <LgGreenButton
            text={t("FORM_FIELDS.SAVE_CHANGES")}
            onButtonClick={saveChanges}
          />
        </div>
      </ModalContainer>
    </div>
  );
};

export default ArtistEditDescription;
