import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import FormContainer from "../../components/FormContainer/FormContainer";
import CustomInput from "../../components/ButtonsFormFields/CustomInput/CustomInput";
import LgGreenButton from "../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import {
  addEmail,
  getErrorMessage,
  getForgotPasswordState,
  userForgotPassword,
} from "../../store/authSlice";

import validate from "./ForgotPasswordFormValidationRules";
import "./forgotPassword.css";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const forgotPasswordState = useSelector(getForgotPasswordState);
  const errorMessage = useSelector(getErrorMessage);

  useEffect(() => {
    if (errorMessage === "ERRORS.UNCONFIRMED_STATUS") {
      navigate("/verifyAccount");
    }
    if (forgotPasswordState === true) {
      navigate("/resetPassword");
    }
  }, [forgotPasswordState, errorMessage, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        email: email.trim(),
      };
      dispatch(userForgotPassword(data));
    }
  }, [errors, isSubmitting, email, dispatch]);

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
  };

  const onForgotPasswordSubmit = async (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    sessionStorage.setItem("email", email);
    dispatch(addEmail(email));
    setErrors({});
    setErrors(validate(email));
  };

  return (
    <div className="forgot_password">
      <FormContainer
        title={t("FORM_FIELDS.FORGOT_PASSWORD")}
        onFormSubmit={onForgotPasswordSubmit}
        navigatelink={() => navigate(-1)}
      >
        <p className="forgot_instructions">
          {t("FORM_FIELDS.FORGOT_PASSWORD_INSTRUCTIONS")}
        </p>
        <CustomInput
          type="email"
          name="email"
          value={email}
          placeholder={t("FORM_FIELDS.EMAIL")}
          onInputChange={handleChange}
          errorMessage={t(errors.email)}
          required
        />
        <div className="forgot_password_button">
          <LgGreenButton
            text={t("FORM_FIELDS.SUBMIT")}
            onButtonClick={onForgotPasswordSubmit}
          />
        </div>
        <div className="forgot_password_secondary_buttons">
          <button onClick={() => navigate("/")}>{t("NAVBAR.SIGN_UP")}</button>
          <button onClick={() => navigate("/login")}>
            {t("NAVBAR.LOGIN")}
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default ForgotPassword;
