import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ArtistPageWrapper from "../../../components/Artists/ArtistPageWrapper/ArtistPageWrapper";
import ClientCard from "../../../components/Artists/ClientCard/ClientCard";
import RedeemAmountModal from "../../../components/Modals/RedeemAmountModal/RedeemAmountModal";
import CompleteTattooModal from "../../../components/Modals/CompleteTattooModal/CompleteTattooModal";
import LgGreenButton from "../../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import ClientDetails from "../../../components/Artists/ClientDetails/ClientDetails";
import { Pagination } from "antd";

import arrowLeftIcon from "../../../img/icon/icon_arrow_white.svg";
import logo from "../../../img/logo/logo_main.png";
import SearchIcon from "../../../img/icon/icon_search_white.svg";

import {
  getArtistData,
  getArtistInfo,
  toggleRedeemAmountModalState,
  getClientData,
  getClientCount,
  getClientInfo,
  setTargetLoanId,
  toggleCompleteTattooModalState,
  getPayoutInfo,
} from "../../../store/artistSlice";

import "./ArtistDashboard.css";

const ArtistDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { artistInfo } = useSelector(getArtistInfo);
  const [search, setSearch] = useState("");
  const totalClientCount = useSelector(getClientCount);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 4;
  const currentClients = useSelector(getClientInfo);
  const [targetLoan, setTargetLoan] = useState();
  const [individualClientViewMoreState, setIndividualClientViewMoreState] =
    useState(false);
  const payout = useSelector(getPayoutInfo);

  useEffect(() => {
    const data = {
      searchTerms: "",
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    dispatch(getClientData(data));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const data = {
        searchTerms: search,
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      dispatch(getClientData(data));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, dispatch, pageIndex]);

  useEffect(() => {
    dispatch(getArtistData());
  }, [dispatch]);

  const handleChange = (event) => {
    if (event.target.name === "search") {
      setSearch(event.target.value);
    }
  };
  const onChange = (page, pageSize) => {
    setPageIndex(page);
  };
  const onRedeemAmount = (e) => {
    e.preventDefault();
    dispatch(setTargetLoanId(e.target.value));
    dispatch(toggleRedeemAmountModalState());
    const target = currentClients.filter(
      (loan) => loan.loanApplicationId === e.target.value
    );
    setTargetLoan(target[0]);
  };
  const onCompleteTattoo = (e) => {
    dispatch(setTargetLoanId(e.target.value));
    dispatch(toggleCompleteTattooModalState());
  };
  const handleViewMore = (e) => {
    dispatch(setTargetLoanId(e.target.value));
    const target = currentClients.filter(
      (loan) => loan.loanApplicationId === e.target.value
    );
    setTargetLoan(target[0]);
    setIndividualClientViewMoreState(true);
  };
  const renderClients = () => {
    if (!currentClients) {
      return;
    } else {
      return currentClients.map((client) => {
        return (
          <ClientCard
            key={client.loanApplicationId}
            loanId={client.loanApplicationId}
            name={`${client.customerFirstName} ${client.customerLastName}`}
            email={client.customerEmail}
            phone={client.customerPhone}
            loanStatus={client.fullLoanStatus}
            approvedAmount={client.loanApprovedAmount}
            approvedOn={client.approvedAmountDate}
            usedAmount={client.loanUsedAmount}
            updatedOn={client.usedAmountDate}
            onRedeemAmount={onRedeemAmount}
            onCompleteTattoo={onCompleteTattoo}
            handleViewMore={handleViewMore}
          />
        );
      });
    }
  };

  const dashboardContent = () => {
    if (!artistInfo) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("ERRORS.GENERAL_ERROR")}</p>
          </div>
        </div>
      );
    } else if (individualClientViewMoreState) {
      return (
        <div className="current_clients_main_details_container">
          <ClientDetails
            targetLoan={targetLoan}
            onCompleteTattoo={onCompleteTattoo}
          />
          <RedeemAmountModal search={search} />
          <CompleteTattooModal search={search} />
        </div>
      );
    } else if (
      artistInfo.status === "Active" ||
      artistInfo.status === "Blocked"
    ) {
      if (currentClients.length === 0 || !currentClients) {
        return (
          <div className="current_clients_main_container">
            <div className="pending_payout_container">
              <h3 className="pending_payout">${payout.payout.toFixed(2)}</h3>
              <p className="pending_payout_message">
                {t("ARTISTS.PENDING_PAYOUTS")}
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: payout.payoutTimingVerbage }}
              />
            </div>
            <div className="current_client_container">
              <div className="no_current_clients">
                <img src={logo} alt="Ink Me Financial logo" />
                <div className="not_verified_content">
                  <p>{t("ARTISTS.NO_CURRENT_CLIENTS")}</p>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="current_clients_main_container">
            <div className="pending_payout_container">
              <h3 className="pending_payout">${payout.payout.toFixed(2)}</h3>
              <p className="pending_payout_message">
                {t("ARTISTS.PENDING_PAYOUTS")}
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: payout.payoutTimingVerbage }}
              />
            </div>
            <div className="current_client_container">
              {renderClients()}
              <div className="page_numbers">
                <Pagination
                  size="small"
                  current={pageIndex}
                  pageSize={pageSize}
                  total={totalClientCount}
                  onChange={onChange}
                />
              </div>
              <RedeemAmountModal search={search} />
              <CompleteTattooModal search={search} />
            </div>
          </div>
        );
      }
    } else if (artistInfo.status === "Waiting_Verification") {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("ARTISTS.NOT_VERIFIED_MESSAGE")}</p>
          </div>
        </div>
      );
    } else if (artistInfo.status === "Declined") {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("ARTISTS.DECLINED_MESSAGE")}</p>
          </div>
        </div>
      );
    }
  };

  const renderTitle = () => {
    if (!artistInfo) {
      return "ARTISTS.HOME";
    } else if (individualClientViewMoreState) {
      return !targetLoan ? null : (
        <div className="secondary_screen_client_title_container">
          <div className="individual_artist_title_content">
            <img
              className="navigation_arrow"
              src={arrowLeftIcon}
              alt="left arrow icon"
              onClick={() => setIndividualClientViewMoreState(false)}
            ></img>
            <div className="individual_client_title_details">
              <h2 className="individual_artist_title">
                {targetLoan.customerFirstName} {targetLoan.customerLastName}
              </h2>
              <div className="individual_client_details">
                <p>
                  {targetLoan.customerEmail} <span className="grey_dot"></span>
                </p>
                <p>{targetLoan.customerPhone}</p>
              </div>
            </div>
          </div>
          <LgGreenButton
            text={t("ARTISTS.REDEEM_AMOUNT")}
            disabled={artistInfo.status === "Blocked" ? true : false}
            value={targetLoan.loanApplicationId}
            onButtonClick={onRedeemAmount}
          />
        </div>
      );
    } else if (artistInfo.status === "Waiting_Verification") {
      return t("ARTISTS.HOME");
    } else {
      return t("ARTISTS.CURRENT_CLIENTS");
    }
  };
  return (
    <div>
      <ArtistPageWrapper title={renderTitle()}>
        {individualClientViewMoreState ? null : (
          <div className="artist_dashboard_searchbar_container">
            <input
              type="text"
              name="search"
              value={search}
              placeholder={t("ARTISTS.SEARCH_CLIENTS")}
              onChange={handleChange}
            />
            <img
              className="artist_dashboard_search_icon"
              src={SearchIcon}
              alt="Search Icon"
            />
          </div>
        )}
        <div>{dashboardContent()}</div>
      </ArtistPageWrapper>
    </div>
  );
};

export default ArtistDashboard;
