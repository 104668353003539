import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer_links_main">
        <NavLink className="footer_links" to="/">
          {t("NAVBAR.HOME")}
        </NavLink>
        <NavLink className="footer_links" to="/userArtist">
          {t("NAVBAR.ARTISTS")}
        </NavLink>
        <NavLink className="footer_links" to="/userAbout">
          {t("NAVBAR.ABOUT")}
        </NavLink>
        <NavLink className="footer_links" to="/userContact">
          {t("NAVBAR.CONTACT")}
        </NavLink>
        <p className="footer_logo">Ink Me Financials</p>
      </div>
    </div>
  );
};

export default Footer;
