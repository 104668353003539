import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ImageEditor from "../../../components/ImageEditor/ImageEditor";
import ArtistPageWrapper from "../../../components/Artists/ArtistPageWrapper/ArtistPageWrapper";
import ArtistEditDescription from "../../../components/Modals/ArtistEditDescription/ArtistEditDescription";
import ArtistChangePassword from "../../../components/Modals/ChangePassword/ChangePassword";
import Feedback from "../../../components/Feedback/Feedback";
import ArtistEditInfo from "../../../components/Modals/ArtistEditInfo/ArtistEditInfo";
import ArtistEditBanking from "../../../components/Modals/ArtistEditBanking/ArtistEditBanking";

import editIcon from "../../../img/icon/icon_edit.svg";
import logo from "../../../img/logo/logo_main.png";
import trashIcon from "../../../img/icon/button_trash_white.svg";
import rightIcon from "../../../img/icon/icon_right.svg";
import arrowLeftIcon from "../../../img/icon/icon_arrow_white.svg";

import {
  getArtistInfo,
  updatePortfolioImages,
  bannerUpload,
  addPortfolioImage,
  toggleEditArtistInfoModalState,
  getArtistInfoModalState,
  getArtistDescriptionModalState,
  toggleEditArtistDescriptionModalState,
  setErrorMessage,
  toggleEditArtistBankingModalState,
} from "../../../store/artistSlice";
import {
  reset,
  getChangePasswordModalState,
  toggleChangePasswordModalState,
} from "../../../store/authSlice";
import {
  getAbout,
  getAboutMessage,
  getTOS,
  getTOSMessage,
  getPP,
  getPPMessage,
} from "../../../store/contentSlice";

import "./ArtistProfile.css";

import { logoutUser } from "../../../App";

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const ArtistProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { artistInfo } = useSelector(getArtistInfo);
  const changePasswordModalState = useSelector(getChangePasswordModalState);
  const [toggleBannerInput, setToggleBannerInput] = useState(false);
  const [toggleAddPhotoInfo, setToggleAddPhotoInfo] = useState(false);
  const [toggleAbout, setToggleAbout] = useState("");
  const [toggleTOS, setToggleTOS] = useState("");
  const [togglePP, setTogglePP] = useState("");
  const [toggleFeedback, setToggleFeedback] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const artistInfoModalState = useSelector(getArtistInfoModalState);
  const artistDescriptionModalState = useSelector(
    getArtistDescriptionModalState
  );
  const artistBankingModalState = useSelector(
    (state) => state.artist.editArtistBankingModalState
  );
  const aboutContent = useSelector(getAboutMessage);
  const termsOfServiceContent = useSelector(getTOSMessage);
  const privacyPolicyContent = useSelector(getPPMessage);
  const queryParams = new URLSearchParams(location.search);
  const section = queryParams.get("section");

  useEffect(() => {
    setArtistLink(`${window.location.origin}/userArtist/?id=${artistInfo.id}`);
  }, [artistInfo.id]);

  useEffect(() => {
    if (section === "about") {
      setToggleAbout(true);
      dispatch(getAbout());
    } else if (section === "feedback") {
      setToggleAbout(false);
      setToggleFeedback(true);
    }
  }, [location.search, section]);

  useEffect(() => {
    if (toggleAbout) {
      dispatch(getAbout());
    } else if (toggleTOS) {
      dispatch(getTOS());
    } else if (togglePP) {
      dispatch(getPP());
    }
  }, [toggleAbout, toggleTOS, togglePP, toggleFeedback, dispatch]);

  const handleToggleBannerInput = () => {
    setToggleBannerInput(!toggleBannerInput);
  };

  const handleImageDelete = async (e) => {
    let name = e.target.parentNode.getAttribute("name");
    let uploadIds = artistInfo.portfolioImages
      .filter((image) => image.id !== name)
      .map((image) => image.id);
    const imageIds = { uploadIds: uploadIds };
    dispatch(updatePortfolioImages(imageIds));
  };

  const handleBannerImageUpload = async (image) => {
    var block = image.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];
    // Convert it to a blob to upload
    var blob = b64toBlob(realData, contentType);

    const file = new File([blob], `image.png`, { type: contentType });
    if (image) {
      let data = new FormData();
      if (image) {
        data.append("file", file);
      }
      await dispatch(bannerUpload(data));
      handleToggleBannerInput();
    }
  };

  const handlePortfolioImageUpload = async (image) => {
    if (artistInfo.portfolioImages.length === 4) {
      dispatch(setErrorMessage("ERRORS.IMAGE_LIMIT"));
    } else {
      var block = image.split(";");
      // Get the content type of the image
      var contentType = block[0].split(":")[1];
      // get the real base64 content of the file
      var realData = block[1].split(",")[1];
      // Convert it to a blob to upload
      var blob = b64toBlob(realData, contentType);

      const file = new File([blob], `image.png`, { type: contentType });

      if (image) {
        let data = new FormData();
        if (image) {
          data.append("file", file);
        }
        let info = {
          data: data,
          portfolioImages: artistInfo.portfolioImages,
        };
        await dispatch(addPortfolioImage(info, artistInfo.portfolioImages));
        setToggleAddPhotoInfo(!toggleAddPhotoInfo);
      }
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logoutUser();
    dispatch(reset());
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const renderTitle = () => {
    if (toggleAbout) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => {
              setToggleAbout(!toggleAbout);
              navigate("/ArtistProfile");
            }}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">{t("NAVBAR.ABOUT")}</h2>
        </div>
      );
    } else if (toggleTOS) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => setToggleTOS(!toggleTOS)}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">
            {t("MODALS.TERMS_OF_SERVICE")}
          </h2>
        </div>
      );
    } else if (togglePP) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => setTogglePP(!togglePP)}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">
            {t("MODALS.PRIVACY_POLICY")}
          </h2>
        </div>
      );
    } else if (toggleFeedback) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => {
              setToggleFeedback(!toggleFeedback);
              navigate("/ArtistProfile");
            }}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">{t("NAVBAR.FEEDBACK")}</h2>
        </div>
      );
    } else {
      return t("NAVBAR.PROFILE");
    }
  };
  const renderTags = () => {
    if (!artistInfo) {
      return <div></div>;
    }
    if (!artistInfo.tags) {
      return <div></div>;
    } else {
      return artistInfo.tags.split(",").map((tag) => {
        return (
          <div key={tag} className="tag_wrapper">
            <p className="tag">{tag}</p>
          </div>
        );
      });
    }
  };

  const renderImages = () => {
    if (!artistInfo) {
      return <div></div>;
    }
    if (!artistInfo.portfolioImages) {
      return <div></div>;
    } else {
      return artistInfo.portfolioImages.map((image) => {
        return (
          <div key={image.id} className="portfolio_image_wrapper">
            <div
              className="portfolio_delete_image"
              name={image.id}
              onClick={handleImageDelete}
            >
              <img src={trashIcon} alt="delete icon" />
            </div>
            <img className="portfolio_image" src={image.url} alt="" />
          </div>
        );
      });
    }
  };

  const renderProfile = !artistInfo ? (
    <div className="not_verified">
      <img src={logo} alt="Ink Me Financial logo" />
      <div className="not_verified_content">
        <p>{t("ERRORS.GENERAL_ERROR")}</p>
      </div>
    </div>
  ) : (
    <div className="profile_content_wrapper">
      <div className="profile_content">
        <div className="profile_banner">
          {!artistInfo.bannerUrl ? (
            <div className="profile_default_banner"></div>
          ) : (
            <img
              className="profile_banner_image"
              src={artistInfo.bannerUrl}
              alt="banner"
            />
          )}
          <button className="add_banner" onClick={handleToggleBannerInput}>
            <img src={editIcon} alt="edit icon" />
          </button>
          {toggleBannerInput ? (
            <ImageEditor
              title="ARTISTS.ARTIST_BANNER"
              width={686}
              height={253}
              handleImageUpload={handleBannerImageUpload}
              closeBannerInput={() => setToggleBannerInput(!toggleBannerInput)}
            />
          ) : (
            ""
          )}
        </div>
        <div className="profile_details_container">
          <div className="profile_details_content_container">
            <div className="profile_details_content">
              <h2 className="profile_subtitles">
                {t("ARTISTS.PROFILE_DETAILS")}
              </h2>
              <h2 className="artist_name">{artistInfo.artistName} </h2>
              <p className="profile_details">{artistInfo.studioName}</p>
              <p className="profile_details">
                {artistInfo.street}, {artistInfo.city}, {artistInfo.province},{" "}
                {artistInfo.postalCode}
              </p>
              <p className="profile_details">
                {artistInfo.profileEmail}
                <span className="dot"></span> {artistInfo.phone}
              </p>
              <p className="profile_details">{artistInfo.website}</p>
              <p className="profile_details">{artistInfo.instagram}</p>
            </div>
            <div className="edit_information_container">
              <button
                className="edit_information_button"
                onClick={() => dispatch(toggleEditArtistInfoModalState())}
              >
                {t("FORM_FIELDS.EDIT_INFO")}
              </button>
            </div>
          </div>
          <div className="tag_container">{renderTags()}</div>
        </div>
        {artistInfoModalState ? (
          <ArtistEditInfo
            onModalClose={() => dispatch(toggleEditArtistInfoModalState())}
          />
        ) : (
          ""
        )}
        <div className="portfolio_wrapper">
          <div className="portfolio_header">
            <h2 className="profile_subtitles">{t("ARTISTS.PORTFOLIO")}</h2>
            <p>{!artistInfo ? "0" : artistInfo.portfolioImages.length}/4</p>
            <div className="add_photo_button_container">
              <button
                className="add_photo_button"
                onClick={() => setToggleAddPhotoInfo(!toggleAddPhotoInfo)}
              >
                {t("FORM_FIELDS.ADD_PHOTO")}
              </button>
            </div>
          </div>
          <div className="portfolio_images_container">{renderImages()}</div>
        </div>
        {toggleAddPhotoInfo ? (
          <ImageEditor
            title="ARTISTS.PORTFOLIO_IMAGE"
            width={332.44}
            height={213.74}
            handleImageUpload={handlePortfolioImageUpload}
            closeBannerInput={() => setToggleAddPhotoInfo(!toggleAddPhotoInfo)}
          />
        ) : (
          ""
        )}
        <div className="artist_description_wrapper">
          <div className="artist_description_header">
            <h2 className="profile_subtitles">{t("ARTISTS.DESCRIPTION")}</h2>
            <div className="edit_description_button_container">
              <button
                className="edit_description_button"
                onClick={() =>
                  dispatch(toggleEditArtistDescriptionModalState())
                }
              >
                {t("FORM_FIELDS.EDIT_DESCRIPTION")}
              </button>
            </div>
          </div>
          <div className="artist_description_container">
            <p>{artistInfo.description}</p>
          </div>
        </div>
        {artistDescriptionModalState ? (
          <ArtistEditDescription
            artistDescription={artistInfo.description}
            onModalClose={() =>
              dispatch(toggleEditArtistDescriptionModalState())
            }
          />
        ) : (
          ""
        )}
        <div className="artist_password_wrapper">
          <div className="artist_password_header">
            <h2 className="profile_subtitles">{t("ARTISTS.PASSWORD")}</h2>
            <p>{artistInfo.loginEmail}</p>
            <p>
              <span className="password_dot">●●●●●●●●●●</span>
            </p>
            <p></p>
          </div>
          <div className="change_password_button_container">
            <button
              className="change_password_button"
              onClick={() => dispatch(toggleChangePasswordModalState())}
            >
              {t("FORM_FIELDS.CHANGE_PASSWORD")}
            </button>
          </div>
        </div>
        {changePasswordModalState ? <ArtistChangePassword /> : ""}
        <div className="artist_amount_received_wrapper">
          <div className="artist_amount_received_header">
            <h2 className="profile_subtitles">{t("ARTISTS.TOTAL_AMOUNT")}</h2>
          </div>
          <div className="artist_threshold_content">
            <p>{t("ARTISTS.MONTHLY_AMOUNT")}</p>
            <p className="artist_threshold_amounts">
              ${artistInfo.monthlyEarnings.toFixed(2)}
            </p>
          </div>
          <div className="artist_threshold_content">
            <p>{t("ARTISTS.YEARLY")}</p>
            <p className="artist_threshold_amounts">
              ${artistInfo.yearlyEarnings.toFixed(2)}
            </p>
          </div>
        </div>
        <div className="artist_banking_wrapper">
          <div className="artist_legal_docs">
            <h2 className="profile_subtitles">{t("ARTISTS.BANKING_INFO")}</h2>
            <p>{artistInfo.paymentEmail}</p>
          </div>
          <div className="edit_banking_button_container">
            <button
              className="edit_banking_button"
              onClick={() => dispatch(toggleEditArtistBankingModalState())}
            >
              {t("FORM_FIELDS.EDIT_DETAILS")}
            </button>
          </div>
        </div>
        {artistBankingModalState ? (
          <ArtistEditBanking
            paymentEmail={artistInfo.paymentEmail}
            onModalClose={() => dispatch(toggleEditArtistBankingModalState())}
          />
        ) : (
          ""
        )}

        <div className="artist_banking_wrapper">
          <div className="artist_legal_docs">
            <h2 className="profile_subtitles">{t("ARTISTS.IMF_LINK")}</h2>
            <p>{artistLink}</p>
          </div>
          <div className="edit_banking_button_container">
            <button
              className="edit_banking_button"
              onClick={() => navigator.clipboard.writeText(artistLink)}
            >
              {t("FORM_FIELDS.COPY_LINK")}
            </button>
          </div>
        </div>
        <div className="artist_profile_links">
          <h2 className="profile_subtitles">{t("NAVBAR.ABOUT")}</h2>
          <div onClick={() => setToggleAbout(!toggleAbout)}>
            <img src={rightIcon} alt="right icon" />
          </div>
        </div>
        <div className="artist_profile_links">
          <h2 className="profile_subtitles">{t("NAVBAR.FEEDBACK")}</h2>
          <div onClick={() => setToggleFeedback(!toggleFeedback)}>
            <img src={rightIcon} alt="right icon" />
          </div>
        </div>
        <div className="artist_profile_links">
          <h2 className="profile_subtitles">{t("MODALS.TERMS_OF_SERVICE")}</h2>
          <div onClick={() => setToggleTOS(!toggleTOS)}>
            <img src={rightIcon} alt="right icon" />
          </div>
        </div>
        <div className="artist_profile_links">
          <h2 className="profile_subtitles">{t("MODALS.PRIVACY_POLICY")}</h2>
          <div onClick={() => setTogglePP(!togglePP)}>
            <img src={rightIcon} alt="right icon" />
          </div>
        </div>
        <div className="logout_button_container">
          <button className="logout_button" onClick={handleLogout}>
            {t("FORM_FIELDS.LOGOUT")}
          </button>
        </div>
      </div>
    </div>
  );

  const renderAbout = aboutContent ? (
    <div
      className="content_container"
      dangerouslySetInnerHTML={{ __html: aboutContent.htmlContent }}
    ></div>
  ) : (
    ""
  );

  const renderTOS = termsOfServiceContent ? (
    <div
      className="content_container"
      dangerouslySetInnerHTML={{ __html: termsOfServiceContent.htmlContent }}
    ></div>
  ) : (
    ""
  );

  const renderPP = privacyPolicyContent ? (
    <div
      className="content_container"
      dangerouslySetInnerHTML={{ __html: privacyPolicyContent.htmlContent }}
    ></div>
  ) : (
    ""
  );

  const renderFeedback = <Feedback />;

  const renderContent = () => {
    if (toggleAbout) {
      return renderAbout;
    } else if (toggleTOS) {
      return renderTOS;
    } else if (togglePP) {
      return renderPP;
    } else if (toggleFeedback) {
      return renderFeedback;
    } else {
      return renderProfile;
    }
  };
  return (
    <div>
      <ArtistPageWrapper title={renderTitle()}>
        <div className="profile_container">{renderContent()}</div>
      </ArtistPageWrapper>
    </div>
  );
};

export default ArtistProfile;
