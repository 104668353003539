import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  editArtistProfile,
  getArtistError,
  getArtistInfo,
} from "../../../store/artistSlice";
import ModalContainer from "../ModalContainer/ModalContainer";
import ProfileCustomInput from "../../ButtonsFormFields/ProfileCustomInput/ProfileCustomInput";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import validate from "./EditInfoValidationRules";
import xCircleIcon from "../../../img/icon/icon_x_circle_white.svg";
import "./ArtistEditInfo.css";

const ArtistEditInfo = ({ onModalClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { artistInfo } = useSelector(getArtistInfo);
  const artistError = useSelector(getArtistError);
  const [profileEmail, setProfileEmail] = useState("");
  const [artistName, setArtistName] = useState("");
  const [studioName, setStudioName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("Province");
  const [postalCode, setPostalCode] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [addTag, setAddTag] = useState("");
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tagError, setTagError] = useState("");

  useEffect(() => {
    setArtistName(artistInfo.artistName);
    setStudioName(artistInfo.studioName);
    setProfileEmail(artistInfo.profileEmail);
    setPhone(artistInfo.phone);
    setAddress(artistInfo.street);
    setCity(artistInfo.city);
    setProvince(artistInfo.province);
    setPostalCode(artistInfo.postalCode);
    setWebsite(artistInfo.website);
    setInstagram(artistInfo.instagram);
    if (artistInfo.tags) {
      setTags(artistInfo.tags.split(","));
    }
    setFirstName(artistInfo.firstName);
    setLastName(artistInfo.lastName);
  }, [
    artistInfo.artistName,
    artistInfo.studioName,
    artistInfo.profileEmail,
    artistInfo.phone,
    artistInfo.street,
    artistInfo.city,
    artistInfo.province,
    artistInfo.postalCode,
    artistInfo.website,
    artistInfo.instagram,
    artistInfo.tags,
    artistInfo.firstName,
    artistInfo.lastName,
  ]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        firstName: firstName,
        lastName: lastName,
        artistName: artistName,
        studioName: studioName,
        profileEmail: profileEmail,
        phone: phone,
        street: address,
        city: city,
        province: province,
        postalCode: postalCode,
        website: website,
        instagram: instagram,
        tags: tags.join(","),
      };
      dispatch(editArtistProfile(data));
    }
  }, [
    errors,
    isSubmitting,
    firstName,
    lastName,
    artistName,
    studioName,
    profileEmail,
    phone,
    address,
    city,
    province,
    postalCode,
    website,
    instagram,
    tags,
    dispatch,
  ]);

  const deleteTag = (e) => {
    const name = e.target.parentNode.getAttribute("name");
    setTags(tags.filter((tag) => tag !== name));
    setTagError("");
  };

  const handleTagList = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      if (tags.length === 10) {
        setTagError("ERRORS.TAG_LIMIT");
        return;
      }
      setTags([...tags, e.target.value]);
      setAddTag("");
      setTagError("");
    }
  };
  const renderTags = () => {
    if (!artistInfo) {
      return <div></div>;
    }
    if (tags.length === 0) {
      return <div></div>;
    } else {
      return tags.map((tag) => {
        return (
          <li key={tag} className="edit_tag_container">
            <p className="edit_tag">{tag}</p>
            <div onClick={deleteTag} name={tag}>
              <img
                className="edit_close_icon"
                src={xCircleIcon}
                alt="close icon"
              />
            </div>
          </li>
        );
      });
    }
  };

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    if (event.target.name === "artistName") {
      if (event.target.value.length <= 40) {
        setArtistName(event.target.value);
      }
    }
    if (event.target.name === "studioName") {
      if (event.target.value.length <= 40) {
        setStudioName(event.target.value);
      }
    }
    if (event.target.name === "firstName") {
      if (event.target.value.length <= 20) {
        setFirstName(event.target.value);
      }
    }
    if (event.target.name === "lastName") {
      if (event.target.value.length <= 20) {
        setLastName(event.target.value);
      }
    }
    if (event.target.name === "phone") {
      const input = event.target.value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 11);
      let phoneNumber;
      if (input.length > 6) {
        phoneNumber = `(${areaCode})${middle}-${last}`;
      } else if (input.length > 3) {
        phoneNumber = `(${areaCode})${middle}`;
      } else if (input.length > 0) {
        phoneNumber = `(${areaCode}`;
      }
      setPhone(phoneNumber);
    }
    if (event.target.name === "address") {
      setAddress(event.target.value);
    }
    if (event.target.name === "city") {
      setCity(event.target.value);
    }
    if (event.target.name === "province") {
      setProvince(event.target.value);
    }
    if (event.target.name === "postalCode") {
      setPostalCode(event.target.value);
    }
    if (event.target.name === "website") {
      setWebsite(event.target.value);
    }
    if (event.target.name === "instagram") {
      setInstagram(event.target.value);
    }
    if (event.target.name === "profileEmail") {
      setProfileEmail(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors({});
    setErrors(
      validate(
        profileEmail,
        phone,
        address,
        city,
        province,
        postalCode,
        artistName,
        firstName,
        lastName,
        website
      )
    );
    setIsSubmitting(true);
  };

  return (
    <div>
      <ModalContainer
        title={t("ARTISTS.EDIT_PROFILE_DETAILS")}
        onButtonClose={onModalClose}
      >
        <form>
          <ProfileCustomInput
            type="text"
            name="artistName"
            value={artistName}
            placeholder={t("FORM_FIELDS.ARTIST_NAME")}
            onInputChange={handleChange}
            errorMessage={t(errors.artistName)}
            required
          />
          <ProfileCustomInput
            type="text"
            name="studioName"
            value={studioName}
            placeholder={t("FORM_FIELDS.STUDIO_NAME")}
            onInputChange={handleChange}
            errorMessage={t(errors.studioName)}
          />
          <ProfileCustomInput
            type="text"
            name="firstName"
            value={firstName}
            placeholder={t("FORM_FIELDS.FIRST_NAME")}
            onInputChange={handleChange}
            errorMessage={t(errors.firstName)}
            required
          />
          <ProfileCustomInput
            type="text"
            name="lastName"
            value={lastName}
            placeholder={t("FORM_FIELDS.LAST_NAME")}
            onInputChange={handleChange}
            errorMessage={t(errors.lastName)}
            required
          />
          <ProfileCustomInput
            type="email"
            name="profileEmail"
            value={profileEmail}
            placeholder={t("FORM_FIELDS.PROFILE_EMAIL")}
            onInputChange={handleChange}
            errorMessage={t(errors.profileEmail)}
            required
          />
          <ProfileCustomInput
            type="tel"
            name="phone"
            value={phone}
            placeholder={t("FORM_FIELDS.PHONE")}
            onInputChange={handleChange}
            errorMessage={t(errors.phone)}
            required
          />
          <ProfileCustomInput
            type="text"
            name="address"
            value={address}
            placeholder={t("FORM_FIELDS.ADDRESS")}
            onInputChange={handleChange}
            errorMessage={t(errors.address)}
            required
          />
          <ProfileCustomInput
            type="text"
            name="city"
            value={city}
            placeholder={t("FORM_FIELDS.CITY")}
            onInputChange={handleChange}
            errorMessage={t(errors.city)}
            required
          />
          <select
            className="profile_province_select"
            name="province"
            value={province}
            onChange={handleChange}
          >
            <option value="Province" disabled hidden>
              {t("FORM_FIELDS.PROVINCE")}
            </option>
            <option value="AB">{t("FORM_FIELDS.ALBERTA")}</option>
            <option value="BC">{t("FORM_FIELDS.BRITISH_COLUMBIA")}</option>
            <option value="MB">{t("FORM_FIELDS.MANITOBA")}</option>
            <option value="NB">{t("FORM_FIELDS.NEW_BRUNSWICK")}</option>
            <option value="NL">{t("FORM_FIELDS.NEWFOUNDLAND")}</option>
            <option value="NS">{t("FORM_FIELDS.NOVA_SCOTIA")}</option>
            <option value="NT">{t("FORM_FIELDS.NORTHWEST_TERRITORIES")}</option>
            <option value="NU">{t("FORM_FIELDS.NUNAVUT")}</option>
            <option value="ON">{t("FORM_FIELDS.ONTARIO")}</option>
            <option value="PE">{t("FORM_FIELDS.PEI")}</option>
            <option value="QC">{t("FORM_FIELDS.QUEBEC")}</option>
            <option value="SK">{t("FORM_FIELDS.SASKATCHEWAN")}</option>
            <option value="YT">{t("FORM_FIELDS.YUKON")}</option>
          </select>
          <ProfileCustomInput
            type="text"
            name="postalCode"
            value={postalCode}
            placeholder={t("FORM_FIELDS.POSTALCODE")}
            onInputChange={handleChange}
            errorMessage={t(errors.postalCode)}
            required
          />
          <ProfileCustomInput
            type="text"
            name="website"
            value={website}
            placeholder={t("FORM_FIELDS.WEBSITE")}
            onInputChange={handleChange}
            errorMessage={t(errors.website)}
          />
          <ProfileCustomInput
            type="text"
            name="instagram"
            value={instagram}
            placeholder={t("FORM_FIELDS.INSTAGRAM")}
            onInputChange={handleChange}
            errorMessage={t(errors.instagram)}
          />
          <div className="edit_tag_input">
            <input
              type="text"
              name="tags"
              placeholder={t("ARTISTS.TAGS")}
              value={addTag}
              onChange={(e) => setAddTag(e.target.value)}
              onKeyDown={handleTagList}
            />
            <ul className="edit_tag_list">{renderTags()}</ul>
          </div>
          <p className="error_message">{tagError ? t(tagError) : null}</p>
          <div className="save_changes_button">
            <LgGreenButton
              text={t("FORM_FIELDS.SAVE_CHANGES")}
              onButtonClick={handleSubmit}
            />
          </div>
        </form>
      </ModalContainer>
    </div>
  );
};

export default ArtistEditInfo;
