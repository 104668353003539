import React from "react";

import AboutUs from "../../components/LandingPageComponent/AboutUs/AboutUs";
import HomeArtistSection from "../../components/LandingPageComponent/HomeArtistSection/HomeArtistSection";
import ContactUs from "../../components/LandingPageComponent/ContactUs/ContactUs";
import Footer from "../../components/LandingPageComponent/Footer/Footer";
import Nav from "../../components/LandingPageComponent/Nav/nav";

import logo from "../../img/logo/logo_main@2x.png";

import "./home.css";

const Home = () => {
  return (
    <div>
      <Nav />
      <div className="landing_page" id="home_section">
        <div className="hero">
          <img
            src={logo}
            alt="Ink Me Financial Logo"
            width="34.22%"
            height="auto"
          />
        </div>
        <AboutUs />
        <HomeArtistSection />
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
