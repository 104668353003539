import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../../../img/logo/logo_white.svg";

import "./ArtistFooter.css";

const ArtistFooter = () => {
  const { t } = useTranslation();

  return (
    <nav className="artist_footer">
      <div className="artist_footer_content">
        <div className="artist_footer_links">
          <NavLink className="artist_footer_link" to="/artist">
            {t("NAVBAR.CLIENTS")}
          </NavLink>
          <NavLink className="artist_footer_link" to="/artistHistory">
            {t("NAVBAR.HISTORY")}
          </NavLink>
          <NavLink className="artist_footer_link" to="/artistProfile">
            {t("NAVBAR.MY_PROFILE")}
          </NavLink>
        </div>
        <div className="artist_footer_links">
          <NavLink className="artist_footer_link" to="/artistProfile/?section=about">
            {t("NAVBAR.ABOUT")}
          </NavLink>
          <NavLink className="artist_footer_link" to="/artistProfile/?section=feedback">
            {t("NAVBAR.FEEDBACK")}
          </NavLink>
        </div>
      </div>
      <div className="artist_footer_logo">
        <img src={logo} alt="Ink Me Financial Logo" />
      </div>
    </nav>
  );
};

export default ArtistFooter;
