/* eslint-env browser, node */
 import loadImageURL from './load-image-url'
import Compress from 'compress.js'
const compress = new Compress()
export default function loadImageFile(imageFile,maxSize) {
  return new Promise((resolve, reject) => {
    if(imageFile[0].size / 1024 / 1024 > maxSize){
      imageFile = [...imageFile]
      compress.compress(imageFile, {
        size: maxSize, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
      }).then((results) => {
        // console.log(results);
        const img1 = results[0]
        const base64str = img1.data
        const imgExt = img1.ext
        const file = Compress.convertBase64ToFile(base64str, imgExt)
        const reader = new FileReader()
        reader.onload = e => {
          try {
            const image = loadImageURL(e.target.result)
            resolve(image)
          } catch (e) {
            reject(e)
          }
        }
        reader.readAsDataURL(file)
      })
    } else {
        const reader = new FileReader()
        reader.onload = e => {
          try {
            const image = loadImageURL(e.target.result)
            resolve(image)
          } catch (e) {
            reject(e)
          }
        }
        reader.readAsDataURL(imageFile[0])
    }
  })
}
