import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import idVerifyIcon from "../../../img/icon/icon_id_verification_black.svg";
import idVerifyIconGrey from "../../../img/icon/icon_id_verification_grey.svg";
import idVerifyIconGreen from "../../../img/icon/icon_id_verification_green.svg";
import bankAccountIcon from "../../../img/icon/icon_bank_black.svg";
import bankAccountIconGrey from "../../../img/icon/icon_bank_grey.svg";
import bankAccountIconGreen from "../../../img/icon/icon_bank_green.svg";
import loanAgreementIcon from "../../../img/icon/icon_loan_black.svg";
import loanAgreementIconGrey from "../../../img/icon/icon_loan_grey.svg";
import loanAgreementIconGreen from "../../../img/icon/icon_loan_green.svg";
import stripeIcon from "../../../img/icon/icon_card_black.svg";
import stripeIconGrey from "../../../img/icon/icon_card_grey.svg";
import stripeIconGreen from "../../../img/icon/icon_card_green.svg";

import {
  setNewLoanId,
  setTargetLoanStatus,
  toggleInveriteModalState,
  getLoans,
  cancelRequest,
  toggleLoading,
  customerStripeSetup,
} from "../../../store/customerSlice";

import { getDateWithTime } from "../../../utils/helpers";

import "./LoanApprovalCard.css";

const LoanApprovalCard = ({
  status,
  requestedAmount,
  term,
  id,
  artistName,
  created,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancelStatus, setCancelStatus] = useState(false);

  const handleSubmit = (e) => {
    if (status === "Age_Initiated" || status === "Waiting_Verification") {
      dispatch(setNewLoanId(e.target.value));
      dispatch(setTargetLoanStatus(status));
      dispatch(toggleInveriteModalState());
    } else if (
      status === "Risk_Score_Initiated" ||
      status === "Age_Completed"
    ) {
      dispatch(setNewLoanId(e.target.value));
      dispatch(setTargetLoanStatus(status));
      dispatch(toggleInveriteModalState());
    } else if (
      status === "Risk_Score_Completed" ||
      status === "Contract_Initiated"
    ) {
      dispatch(setNewLoanId(e.target.value));
      dispatch(setTargetLoanStatus(status));
      dispatch(toggleInveriteModalState());
    } else if (
      status === "Contract_Completed" ||
      status === "Stripe_Initiated"
    ) {
      dispatch(setNewLoanId(e.target.value));
      const data = {
        id: e.target.value,
      };
      dispatch(customerStripeSetup(data));
      dispatch(setTargetLoanStatus(status));
      dispatch(toggleInveriteModalState());
    }
  };

  const handleCancel = (e) => {
    const data = {
      id: e.target.value,
    };
    dispatch(cancelRequest(data));
    dispatch(toggleLoading());
    setTimeout(() => {
      dispatch(getLoans());
    }, 500);
  };

  useEffect(() => {
    if (
      status === "Age_Denied" ||
      status === "Risk_Score_Denied" ||
      status === "Contract_Denied"
    ) {
      setCancelStatus(true);
    } else {
      setCancelStatus(false);
    }
  }, [status]);

  return (
    <div className="loan_process_container">
      {cancelStatus ? (
        <div className="loan_denied_container "></div>
      ) : (
        <div className="loan_approval_steps_container">
          <div
            className={`loan_agreement_steps ${
              status === "Age_Initiated" || status === "Waiting_Verification"
                ? "loan_agreement_active_steps"
                : ""
            } ${
              status === "Age_Completed" ||
              status === "Age_Denied" ||
              status === "Risk_Score_Initiated" ||
              status === "Risk_Score_Completed" ||
              status === "Risk_Score_Denied" ||
              status === "Risk_Score_Error" ||
              status === "Contract_Initiated" ||
              status === "Contract_Completed" ||
              status === "Stripe_Initiated" ||
              status === "Stripe_Completed"
                ? "loan_agreement_completed_steps"
                : "loan_agreement_not_completed_steps"
            }`}
          >
            <img
              src={
                status === "Age_Initiated" || status === "Waiting_Verification"
                  ? idVerifyIcon
                  : status === "Age_Completed" ||
                    status === "Risk_Score_Initiated" ||
                    status === "Risk_Score_Completed" ||
                    status === "Risk_Score_Error" ||
                    status === "Contract_Initiated" ||
                    status === "Contract_Completed" ||
                    status === "Stripe_Initiated" ||
                    status === "Stripe_Completed"
                  ? idVerifyIconGreen
                  : idVerifyIconGrey
              }
              alt="Id Verification Icon"
            />
            <p>{t("CUSTOMERS.ID_VERIFICATION")}</p>
          </div>
          <div
            className={`loan_agreement_steps ${
              status === "Risk_Score_Initiated" || status === "Age_Completed"
                ? "loan_agreement_active_steps"
                : ""
            } ${
              status === "Risk_Score_Completed" ||
              status === "Risk_Score_Denied" ||
              status === "Risk_Score_Error" ||
              status === "Contract_Initiated" ||
              status === "Contract_Completed" ||
              status === "Stripe_Initiated" ||
              status === "Stripe_Completed"
                ? "loan_agreement_completed_steps"
                : "loan_agreement_not_completed_steps"
            }`}
          >
            <img
              src={
                status === "Risk_Score_Initiated" || status === "Age_Completed"
                  ? bankAccountIcon
                  : status === "Risk_Score_Completed" ||
                    status === "Risk_Score_Error" ||
                    status === "Contract_Initiated" ||
                    status === "Contract_Completed" ||
                    status === "Stripe_Initiated" ||
                    status === "Stripe_Completed"
                  ? bankAccountIconGreen
                  : bankAccountIconGrey
              }
              alt="Bank Verification Icon"
            />
            <p>{t("CUSTOMERS.BANK_VERIFICATION")}</p>
          </div>
          <div
            className={`loan_agreement_steps ${
              status === "Contract_Initiated" ||
              status === "Risk_Score_Completed"
                ? "loan_agreement_active_steps"
                : ""
            } ${
              status === "Contract_Completed" ||
              status === "Stripe_Initiated" ||
              status === "Stripe_Completed"
                ? "loan_agreement_completed_steps"
                : "loan_agreement_not_completed_steps"
            }`}
          >
            <img
              src={
                status === "Contract_Initiated" ||
                status === "Risk_Score_Completed"
                  ? loanAgreementIcon
                  : status === "Contract_Completed" ||
                    status === "Stripe_Initiated" ||
                    status === "Stripe_Completed"
                  ? loanAgreementIconGreen
                  : loanAgreementIconGrey
              }
              alt="Loan Agreement Icon"
            />
            <p>{t("CUSTOMERS.LOAN_AGREEMENT")}</p>
          </div>
          <div
            className={`loan_agreement_steps ${
              status === "Stripe_Initiated" || status === "Contract_Completed"
                ? "loan_agreement_active_steps"
                : ""
            } ${
              status === "Stripe_Completed"
                ? "loan_agreement_completed_steps"
                : "loan_agreement_not_completed_steps"
            }`}
          >
            <img
              src={
                status === "Stripe_Initiated"
                  ? stripeIcon
                  : status === "Stripe_Completed"
                  ? stripeIconGreen
                  : stripeIconGrey
              }
              alt="Stripe Icon"
            />
            <p>{t("CUSTOMERS.STRIPE_SETUP")}</p>
          </div>
        </div>
      )}
      <div className="loan_details_container">
        <h2 className="loan_card_title">{artistName}</h2>
        <div className="loan_details_text_container">
          {cancelStatus ? (
            <div>
              <h3 className="loan_card_status denied">
                {t("CUSTOMERS.LOAN_DENIED_STATUS")}
              </h3>
              <p className="denied">{t("CUSTOMERS.LOAN_DENIED")}</p>
            </div>
          ) : (
            <div className="loan_details_inner_text_container">
              <div className="loan_details">
                <p className="loan_details_title">
                  {t("CUSTOMERS.REQUESTED_AMOUNT")}
                </p>
                <p className="loan_details_values"> ${requestedAmount}</p>
                <p className="loan_details_title">
                  {t("CUSTOMERS.REQUESTED_ON")} {getDateWithTime(created)}
                </p>
              </div>
              <div className="loan_details">
                <p className="loan_details_title">{t("CUSTOMERS.LOAN_TERM")}</p>
                <p className="loan_details_values">
                  {term} {t("CUSTOMERS.MONTHS")}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="loan_detail_buttons">
          {cancelStatus ? (
            " "
          ) : (
            <button
              className="sm_approve"
              value={id}
              onClick={(e) => handleSubmit(e)}
            >
              {t("CUSTOMERS.CONTINUE_APPROVAL")}
            </button>
          )}
          <button
            className="sm_decline"
            value={id}
            onClick={(e) => handleCancel(e)}
          >
            {t("CUSTOMERS.CANCEL")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoanApprovalCard;
