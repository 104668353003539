import React from "react";
import './SmGreenButton.css';

const SmGreenButton = ({ text, onButtonClick }) => {
  return (<button className="sm_green_button" onClick={onButtonClick}>
      {text}
    </button>
    
  );
};

export default SmGreenButton;