import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomerPageWrapper from "../../../components/Customers/CustomerPageWrapper/CustomerPageWrapper";
import LoanCard from "../../../components/Customers/LoanCard/LoanCard";
import { Pagination } from "antd";
import LoanDetails from "../../../components/Customers/LoanDetails/LoanDetails";

import arrowLeftIcon from "../../../img/icon/icon_arrow_white.svg";
import logo from "../../../img/logo/logo_main.png";
import defaultBanner from "../../../img/banner_eye/banner_eye@2x.png";

import {
  getCustomerHistoryData,
  getIndividualLoanViewMoreState,
  toggleIndividualLoanViewMoreState,
  getLoanInfo,
} from "../../../store/customerSlice";

import "./CustomerHistory.css";

const CustomerHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalLoanCount = useSelector((state) => state.customer.loanCount);
  const targetLoan = useSelector(getLoanInfo);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 4;
  const customerHistory = useSelector(
    (state) => state.customer.customerHistory
  );
  const individualLoanViewMoreState = useSelector(
    getIndividualLoanViewMoreState
  );

  useEffect(() => {
    if (individualLoanViewMoreState === true) {
      dispatch(toggleIndividualLoanViewMoreState());
    }
  }, []);

  useEffect(() => {
    const data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    dispatch(getCustomerHistoryData(data));
  }, [pageIndex, pageSize]);

  const onChange = (page, pageSize) => {
    setPageIndex(page);
  };

  const renderTitle = () => {
    if (individualLoanViewMoreState) {
      return (
        <div className="secondary_screen_title_container">
          <div className="individual_artist_title_content">
            <img
              className="navigation_arrow"
              src={arrowLeftIcon}
              alt="left arrow icon"
              onClick={() => dispatch(toggleIndividualLoanViewMoreState())}
            ></img>
            <div className="individual_client_title_details">
              <h2 className="individual_artist_title">
                {targetLoan.artistName}
              </h2>
              <div className="individual_client_details">
                <p>{targetLoan.artistAddress}&nbsp;</p>
                <p>
                  {targetLoan.artistEmail}
                  <span className="grey_dot"></span>
                  {targetLoan.artistPhone}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return t("CUSTOMERS.HISTORY");
    }
  };

  const dashboardContent = () => {
    if (!customerHistory) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("ERRORS.GENERAL_ERROR")}</p>
          </div>
        </div>
      );
    } else if (customerHistory.length === 0) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("CUSTOMERS.NO_COMPLETED_LOANS")}</p>
          </div>
        </div>
      );
    } else {
      return customerHistory.map((loan) => {
        return (
          <LoanCard
            key={loan.loanId}
            status={loan.fullLoanStatus}
            isPaidOff={loan.isPaidOff}
            created={loan.created}
            artistName={loan.artistName}
            requestedAmount={loan.requestedAmount}
            term={loan.term}
            id={loan.loanId}
            approvedAmount={loan.approvedAmount}
            approvedOn={loan.dateApproved}
            usedAmount={loan.amountUsed}
            updatedOn={loan.dateLastUsed}
            missedPaymentDate={loan.failedPayment}
            isHistory={true}
            sentToCollections={loan.sentToCollections}
          />
        );
      });
    }
  };

  return (
    <div>
      <CustomerPageWrapper title={renderTitle()} image={defaultBanner}>
        <div className="customer_history_content_container">
          <div>
            {individualLoanViewMoreState ? <LoanDetails /> : dashboardContent()}
          </div>

          <div className="history_page_numbers">
            {individualLoanViewMoreState ? null : (
              <Pagination
                size="small"
                current={pageIndex}
                pageSize={pageSize}
                total={totalLoanCount}
                onChange={onChange}
              />
            )}
          </div>
        </div>
      </CustomerPageWrapper>
    </div>
  );
};

export default CustomerHistory;
