import React, { useEffect, useState } from "react";
import "./CustomInput.css";

const CustomInput = ({
  type,
  value,
  placeholder,
  onInputChange,
  errorMessage,
  name,
  handleKeyPress,
  customClass = null,
}) => {
  const [inputClasses, setInputClasses] = useState("");
  useEffect(() => {
    if (errorMessage) {
      setInputClasses("custom_error custom_input_ele");
    } else if (customClass) {
      setInputClasses(customClass);
    } else {
      setInputClasses("custom_input_ele");
    }
  }, [customClass, errorMessage]);

  return (
    <div className="custom_input">
      <input
        className={inputClasses}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
        onChange={onInputChange}
      />
      <p className="error_message">{errorMessage}</p>
    </div>
  );
};

export default CustomInput;
