import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import NumberFormat from "react-number-format";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import SuccessModal from "../SuccessModal/SuccessModal";

import {
  getRedeemAmountModalState,
  getTargetLoanId,
  toggleRedeemAmountModalState,
  getClientInfo,
  getClientData,
} from "../../../store/artistSlice";
import { redeemLoanAmount } from "../../../api/artist";

import "./RedeemAmountModal.css";

const RedeemAmountModal = ({ search }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalState = useSelector(getRedeemAmountModalState);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [redeemAmount, setRedeemAmount] = useState();
  const [error, setError] = useState("");
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const targetLoanId = useSelector(getTargetLoanId);
  const currentClients = useSelector(getClientInfo);
  const [targetLoan, setTargetLoan] = useState();

  useEffect(() => {
    const target = currentClients.filter((loan) => {
      return loan.loanApplicationId === targetLoanId;
    });
    setTargetLoan(...target);
  }, [targetLoanId, currentClients]);

  const confirmRedeemAmount = (e) => {
    e.preventDefault();
    if (isNaN(redeemAmount)) {
      setError("ERRORS.NEED_VALUE");
    } else if (redeemAmount > targetLoan.loanAvailableAmount) {
      setError("ERRORS.REDEEM_AMOUNT_OVER");
    } else if (redeemAmount < 10) {
      setError("ERRORS.REDEEM_BELOW_MIN");
    } else {
      setConfirmModalState(true);
    }
  };

  const onSubmitRedeemAmount = (e) => {
    e.preventDefault();
    try {
      const data = {
        loanApplicationId: targetLoan.loanApplicationId,
        amount: redeemAmount,
      };
      const res = redeemLoanAmount(data);
      setToggleSuccess(true);
      dispatch(toggleRedeemAmountModalState());
      setRedeemAmount();
      setTimeout(() => {
        setToggleSuccess(false);
        setConfirmModalState(false);
        const searchTerm = !search ? "" : search;
        const data = {
          searchTerms: searchTerm,
          pageIndex: 1,
          pageSize: 4,
        };
        dispatch(getClientData(data));
      }, 1500);
    } catch (error) {
      console.log(error.response);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  return (
    <div>
      {modalState ? (
        <ModalContainer
          title={
            confirmModalState
              ? t("ARTISTS.CONFIRM_AMOUNT")
              : t("ARTISTS.REDEEM_AMOUNT")
          }
          onButtonClose={
            confirmModalState
              ? () => {
                  setConfirmModalState(false);
                  dispatch(toggleRedeemAmountModalState());
                }
              : () => dispatch(toggleRedeemAmountModalState())
          }
        >
          {confirmModalState ? (
            <div className="confirm_amount_container">
              <p>
                {t("ARTISTS.AVAILABLE_AMOUNT")}
                <span>
                  $
                  {!targetLoan
                    ? null
                    : targetLoan.loanAvailableAmount.toFixed(2)}
                </span>
              </p>
              <p>
                {t("ARTISTS.REDEEMED_AMOUNT")}
                <span>${Number(redeemAmount).toFixed(2)}</span>
              </p>
              <hr />
              <p>
                {t("ARTISTS.REMAINING_BALANCE")}
                <span>
                  $
                  {!targetLoan
                    ? null
                    : (targetLoan.loanAvailableAmount - redeemAmount).toFixed(
                        2
                      )}
                </span>
              </p>
              <LgGreenButton
                text={t("ARTISTS.CONFIRM_AMOUNT")}
                onButtonClick={onSubmitRedeemAmount}
              />
            </div>
          ) : (
            <div className="redeem_amount_container">
              <p>{t("ARTISTS.REDEEM_AMOUNT_MESSAGE")}</p>
              <p className="redeem_amount_available">
                {t("ARTISTS.AVAILABLE_AMOUNT")}
                <span>
                  $
                  {!targetLoan
                    ? null
                    : targetLoan.loanAvailableAmount.toFixed(2)}
                </span>
              </p>
              <NumberFormat
                id="calculator_input"
                name="redeemAmount"
                value={redeemAmount}
                placeholder="$ Enter Amount              CAD"
                displayType="input"
                type="text"
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                prefix={"$"}
                suffix={"         CAD"}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  setRedeemAmount(value);
                }}
              />
              <p className="error_message">{t(error)}</p>
              <LgGreenButton
                text={t("ARTISTS.REDEEM_AMOUNT")}
                onButtonClick={confirmRedeemAmount}
              />
            </div>
          )}
        </ModalContainer>
      ) : null}
      {toggleSuccess ? (
        <SuccessModal
          title={"FORM_FIELDS.REQUEST_SENT"}
          message={"FORM_FIELDS.REQUEST_SENT_MESSAGE"}
        />
      ) : null}
    </div>
  );
};

export default RedeemAmountModal;
