import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { SignatureComponent, Signature } from "@syncfusion/ej2-react-inputs";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";

import { getLoans } from "../../../store/customerSlice";
import {
  getContractFromServer,
  returnSignedContract,
} from "../../../api/customer";

import "./PDFViewer.css";

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const PDFViewer = ({ targetLoanId }) => {
  // Refs
  // ----
  const viewerRef = useRef(null);
  let signObj: Signature = new Signature(null, "#signature");
  const dispatch = useDispatch();

  const saveHandler = async () => {
    console.log(signObj?.isSignatureEmpty);
    if (signObj?.isSignatureEmpty) {
      viewerRef.current.showNotificationPopup("Signature is required");
    } else {
      try {
        var fd = new FormData();

        let signatureBase64 = signObj.saveAsBlob();
        console.log(signatureBase64);
        fd.append("signatureBase64", signatureBase64);
        const blob = await viewerRef.current.saveAsBlob();
        const base64data = await blobToBase64(blob);
        fd.append("contractBase64", base64data);
        fd.append("id", targetLoanId);
        console.log(fd);
        const res = await returnSignedContract(fd);
        setTimeout(() => {
          dispatch(getLoans());
        }, 500);
      } catch (error) {
        console.log(error);
        if (error.response.status === 500) {
          viewerRef.current.showNotificationPopup(
            "Please close this modal and refresh the brower to try again.  If the problem continues please contact the admin."
          );
        }
      }
    }
  };

  const viewerCreatedHandler = async () => {
    const monthNames = [
      t("CUSTOMERS.JANUARY"),
      t("CUSTOMERS.FEBRUARY"),
      t("CUSTOMERS.MARCH"),
      t("CUSTOMERS.APRIL"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUNE"),
      t("CUSTOMERS.JULY"),
      t("CUSTOMERS.AUGUST"),
      t("CUSTOMERS.SEPTEMBER"),
      t("CUSTOMERS.OCTOBER"),
      t("CUSTOMERS.NOVEMBER"),
      t("CUSTOMERS.DECEMBER"),
    ];

    let date = new Date();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let year = String(date.getFullYear());

    const data = {
      id: targetLoanId,
      monthday: `${month} ${day}`,
      year: year,
    };
    try {
      const res = await getContractFromServer(data);
      viewerRef.current.load(`data:application/pdf;base64, ${res}`, null);
      viewerRef.current.fileName = "contract.pdf";
    } catch (error) {
      console.log(error);
    }
  };

  const OnClear = () => {
    signObj?.clear();
  };
  // View
  // ----
  return (
    <div className="pdfviewer_wrapper">
      <p>{t("CUSTOMERS.SIGN_CONTRACT_MESSAGE")}</p>
      <div className="control-section">
        <PdfViewerComponent
          id="container"
          ref={viewerRef}
          serviceUrl={`${process.env.REACT_APP_BACKEND_BASE_URL}/pdfviewer`}
          created={viewerCreatedHandler}
          showNotificationDialog={false}
          style={{ height: "640px" }}
          toolbarSettings={{
            showTooltip: true,
            toolbarItems: [
              "PageNavigationTool",
              "MagnificationTool",
              "PanTool",
              "SelectionTool",
              "SignatureOption",
              "SearchOption",
              "DownloadOption",
            ],
            annotationToolbarItems: [
              "HighlightTool",
              "UnderlineTool",
              "StrikethroughTool",
              "ColorEditTool",
              "OpacityEditTool",
              "AnnotationDeleteTool",
              "StampAnnotationTool",
              "HandWrittenSignatureTool",
              "InkAnnotationTool",
              "ShapeTool",
              "CalibrateTool",
              "StrokeColorEditTool",
              "ThicknessEditTool",
              "FreeTextAnnotationTool",
              "FontFamilyAnnotationTool",
              "FontSizeAnnotationTool",
              "FontStylesAnnotationTool",
              "FontAlignAnnotationTool",
              "FontColorAnnotationTool",
              "CommentPanelTool",
            ],
            formDesignerToolbarItems: [
              "TextboxTool",
              "PasswordTool",
              "CheckBoxTool",
              "RadioButtonTool",
              "DropdownTool",
              "ListboxTool",
              "DrawSignatureTool",
              "DeleteTool",
            ],
          }}
        >
          <Inject
            services={[
              Toolbar,
              Magnification,
              Navigation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              Annotation,
              FormFields,
              FormDesigner,
            ]}
          />
        </PdfViewerComponent>
      </div>
      <div className="accept_contract_container">
        <div className="contract_signature_title">
          <h4>Sign here:</h4>
          <SignatureComponent
            ref={(sign) => (signObj = sign)}
          ></SignatureComponent>
          <button onClick={OnClear}>Clear</button>
        </div>

        <button className="lg_green_button" onClick={saveHandler}>
          {t("CUSTOMERS.I_ACCEPT")}
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;
