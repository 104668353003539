import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./pages/home/home";
import Login from "./pages/login/login";
import ForgetPassword from "./pages/forgotPassword/forgotPassword";
import ResetPassword from "./pages/resetPassword/resetPassword";
import CustomerDashboard from "./pages/Customers/CustomerDashboard/CustomerDashboard";
import ArtistSignUp from "./pages/Artists/ArtistSignUp/ArtistSignUp";
import CustomerSignUp from "./pages/Customers/CustomerSignUp/CustomerSignUp";
import VerifyAccount from "./pages/VerifyAccount/VerifyAccount";
import ArtistDashboard from "./pages/Artists/ArtistDashboard/ArtistDashboard";
import ArtistProfile from "./pages/Artists/ArtistProfile/ArtistProfile";
import CustomerArtists from "./pages/Customers/CustomerArtists/CustomerArtists";
import CustomerProfile from "./pages/Customers/CustomerProfile/CustomerProfile";
import UserArtists from "./pages/UserArtists/UserArtists";
import CustomerHistory from "./pages/Customers/CustomerHistory/CustomerHistory";
import CustomerRatesTerms from "./pages/Customers/CustomerRatesTerms/CustomerRatesTerms";
import CustomerNotifications from "./pages/Customers/CustomerNotifications/CustomerNotifications";
import ArtistHistory from "./pages/Artists/ArtistHistory/ArtistHistory";
import ArtistRatesTerms from "./pages/Artists/ArtistRatesTerms/ArtistRatesTerms";
import ArtistNotifications from "./pages//Artists/ArtistNotifications/ArtistNotifications";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import ErrorBoundary from "./components/ErrorBoundry/ErrorBoundry";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

import { getRole, getAccessToken } from "./store/authSlice";

import "./App.css";

export const logoutUser = () => {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.clear();
};

function App() {
  const role = useSelector(getRole);
  const accessToken = useSelector(getAccessToken);

  return (
    <div className="App">
      <ErrorBoundary>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgetPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/customerSignUp" element={<CustomerSignUp />} />
          <Route path="/artistSignUp" element={<ArtistSignUp />} />
          <Route path="/verifyAccount" element={<VerifyAccount />} />
          <Route path="/userArtist" element={<UserArtists />} />
          <Route path="/userAbout" element={<About />} />
          <Route path="/userContact" element={<Contact />} />
          <Route
            element={
              <ProtectedRoute isAllowed={accessToken && role === "Customer"} />
            }
          >
            <Route path="/customer" element={<CustomerDashboard />} />
            <Route path="/customerArtists" element={<CustomerArtists />} />
            <Route path="/customerProfile" element={<CustomerProfile />} />
            <Route path="/customerHistory" element={<CustomerHistory />} />
            <Route
              path="/customerRatesTerms"
              element={<CustomerRatesTerms />}
            />
            <Route
              path="/customerNotifications"
              element={<CustomerNotifications />}
            />
          </Route>

          <Route
            exact
            element={
              <ProtectedRoute isAllowed={accessToken && role === "Artist"} />
            }
          >
            <Route path="/artist" element={<ArtistDashboard />} />
            <Route path="/artistProfile" element={<ArtistProfile />} />
            <Route path="/artistHistory" element={<ArtistHistory />} />
            <Route path="/artistRatesTerms" element={<ArtistRatesTerms />} />
            <Route
              path="/artistNotifications"
              element={<ArtistNotifications />}
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
