export default function validate(
  password = null,
  confirmPassword = null,
  securityCode = null,
) {
  let errors = {};
  const regexPassword = new RegExp(
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/
  );
  if (securityCode === "") {
    errors.securityCode = "ERRORS.SECURITY_CODE_REQUIRED";
  }
  if (password === "") {
    errors.password = "ERRORS.PASSWORD_REQUIRED";
  }
  if (confirmPassword === "") {
    errors.confirmPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (password !== confirmPassword) {
    errors.confirmPassword = "ERRORS.PASSWORDS_MUST_MATCH";
  }
  if (!regexPassword.test(password)) {
    errors.confirmPassword = "ERRORS.VALID_PASSWORD";
  }
  return errors;
}
