import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalContainer from "../ModalContainer/ModalContainer";
import PasswordInput from "../../ButtonsFormFields/PasswordInput/PasswordInput";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import {
  getErrorMessage,
  changeUserPassword,
  toggleChangePasswordModalState,
} from "../../../store/authSlice";

import validate from "./ChangePasswordValidationRules";
import "./ChangePassword.css";

const ArtistChangePassword = ({ onModalClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const errorMessage = useSelector(getErrorMessage);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        password: oldPassword,
        newPassword: newPassword,
        newPasswordConfirmation: confirmPassword,
      };
      dispatch(changeUserPassword(data));
    }
  }, [
    errors,
    isSubmitting,
    oldPassword,
    newPassword,
    confirmPassword,
    dispatch,
  ]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "oldPassword") {
      setOldPassword(e.target.value);
    }
    if (e.target.name === "newPassword") {
      setNewPassword(e.target.value);
    }
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };

  const saveChanges = async (e) => {
    if (e) e.preventDefault();
    setErrors(validate(oldPassword, newPassword, confirmPassword));
    setIsSubmitting(true);
  };

  return (
    <div>
      <ModalContainer
        title={t("FORM_FIELDS.CHANGE_PASSWORD")}
        onButtonClose={() => dispatch(toggleChangePasswordModalState())}
      >
        <PasswordInput
          customClass="profile_password"
          name="oldPassword"
          value={oldPassword}
          placeholder={t("FORM_FIELDS.OLD_PASSWORD")}
          onInputChange={handleChange}
          errorMessage={t(errors.oldPassword)}
          required
        />
        <PasswordInput
          customClass="profile_password"
          name="newPassword"
          value={newPassword}
          placeholder={t("FORM_FIELDS.ENTER_NEW_PASSWORD")}
          onInputChange={handleChange}
          errorMessage={t(errors.newPassword)}
          required
        />
        <PasswordInput
          customClass="profile_password"
          name="confirmPassword"
          value={confirmPassword}
          placeholder={t("FORM_FIELDS.CONFIRM_NEW_PASSWORD")}
          onInputChange={handleChange}
          errorMessage={t(errors.confirmPassword)}
          required
        />
        <p className={errorMessage ? "general_error" : "hidden"}>
          {errorMessage ? t(errorMessage) : null}
        </p>
        <div className="save_changes_button">
          <LgGreenButton
            text={t("FORM_FIELDS.SAVE_CHANGES")}
            onButtonClick={saveChanges}
          />
        </div>
      </ModalContainer>
    </div>
  );
};

export default ArtistChangePassword;
