import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import ArtistPageContent from "../../components/Customers/ArtistPageContent/ArtistPageContent";
import Footer from "../../components/LandingPageComponent/Footer/Footer";
import Nav from "../../components/LandingPageComponent/Nav/nav";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import LgGreenButton from "../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import Loader from "../../components/Loader/Loader";

import SearchIcon from "../../img/icon/icon_search_white.svg";
import arrowLeftIcon from "../../img/icon/icon_arrow_white.svg";
import defaultBanner from "../../img//banner_eye/banner_eye@2x.png";

import {
  getIndividualArtistPageState,
  toggleIndividualArtistPageState,
  getTargetArtist,
  getTargetArtistsData,
} from "../../store/customerSlice";

import "./UserArtists.css";

const UserArtists = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const individualArtistPageState = useSelector(getIndividualArtistPageState);
  const individualArtist = useSelector(getTargetArtist);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [id, setId] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    if (id) {
      setId(id);
      dispatch(toggleIndividualArtistPageState());
      dispatch(getTargetArtistsData(id));
    }
  }, [dispatch]);

  const handleChange = (event) => {
    if (event.target.name === "search") {
      setSearch(event.target.value);
      setPageIndex(1);
    }
  };

  const handleSubmit = () => {
    navigate("/login", {
      state: { from: location, search: `?id=${individualArtist.artistId}` },
    });
  };

  const image = individualArtistPageState
    ? individualArtist.bannerUrl
    : defaultBanner;

  const individualArtistTitle = (
    <div className="secondary_screen_artist_title_container">
      <div className="individual_artist_title_content">
        <img
          className="navigation_arrow"
          onClick={() => dispatch(toggleIndividualArtistPageState())}
          src={arrowLeftIcon}
          alt="left arrow icon"
        ></img>
        <div>
          <h2 className="individual_artist_title">
            {individualArtist.artistName}
            <span className="individual_studio_title">
              {" "}
              - {individualArtist.studioName}
            </span>
          </h2>
          <p className="individual_address">{individualArtist.address}</p>
        </div>
      </div>
      <LgGreenButton
        text={t("CUSTOMERS.APPLY")}
        value={individualArtist.id}
        onButtonClick={handleSubmit}
      />
    </div>
  );
  return (
    <div>
      <Nav />
      <ErrorModal />
      <Loader />
      <div
        className="customer_header"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(38,38,38,1) 65%, rgba(38,38,38,0) 100%), url(${image})`,
        }}
      >
        <div className="customer_title">
          {individualArtistPageState
            ? individualArtistTitle
            : t("CUSTOMERS.ARTISTS")}
        </div>
      </div>
      <div className="page_wrapper_container">
        {individualArtistPageState ? (
          ""
        ) : (
          <div className="searchbar_container">
            <input
              type="text"
              name="search"
              value={search}
              placeholder={t("CUSTOMERS.SEARCH_ARTISTS")}
              onChange={handleChange}
            />
            <img className="search_icon" src={SearchIcon} alt="Search Icon" />
          </div>
        )}
        <ArtistPageContent
          searchTerm={search}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          id={id}
        />
      </div>
      <Footer />
    </div>
  );
};

export default UserArtists;
