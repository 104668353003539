import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Footer from "../../components/LandingPageComponent/Footer/Footer";
import Nav from "../../components/LandingPageComponent/Nav/nav";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import Loader from "../../components/Loader/Loader";
import SmGreenButton from "../../components/ButtonsFormFields/SmGreenButton/SmGreenButton";
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";

import image from "../../img//banner_eye/banner_eye@2x.png";

import { sendContactUs } from "../../api/user";

import "./Contact.css";

const Contact = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (message.length === 0) {
    } else {
      const data = {
        name: name,
        message: message,
        email: email,
      };
      try {
        const res = await sendContactUs(data);
        setToggleSuccess(true);
        setName("");
        setEmail("");
        setMessage("");
        setTimeout(() => {
          setToggleSuccess(false);
        }, 1500);
      } catch (error) {
        console.log(error.response);
        setError("ERRORS.GENERAL_ERROR");
      }
    }
  };
  return (
    <div>
      <Nav />
      <ErrorModal />
      <Loader />
      <div
        className="customer_header"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(38,38,38,1) 65%, rgba(38,38,38,0) 100%), url(${image})`,
        }}
      >
        <div className="customer_title">{t("NAVBAR.CONTACT")}</div>
      </div>
      <div className="contact_container">
        <div className="contact_content">
          <form className="contact_form" onSubmit={handleSubmit}>
            <div className="contact_sender">
              <div className="contact_form_input">
                <label htmlFor="name">{t("FORM_FIELDS.NAME")}</label>
                <input
                  type="text"
                  value={name}
                  placeholder={t("FORM_FIELDS.NAME")}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="contact_form_input">
                <label htmlFor="email">{t("FORM_FIELDS.EMAIL")}</label>
                <input
                  type="email"
                  value={email}
                  placeholder={t("FORM_FIELDS.EMAIL")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="contact_form_text">
              <label htmlFor="message">{t("FORM_FIELDS.MESSAGE")}</label>
              <textarea
                value={message}
                placeholder={t("FORM_FIELDS.MESSAGE")}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="contact_us_button">
              <SmGreenButton
                text={t("FORM_FIELDS.SEND")}
                onButtonClick={handleSubmit}
              />
            </div>
            {toggleSuccess ? (
              <SuccessModal title={"FORM_FIELDS.MESSAGE_SUBMITTED"} />
            ) : null}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
