import React from "react";
import "./ProfileCustomInput.css";

const ProfileCustomInput = ({
  type,
  value,
  placeholder,
  onInputChange,
  errorMessage,
  name,
}) => {
  return (
    <div className="profile_custom_input">
      <input className={errorMessage?"profile_custom_error profile_custom_input_ele": "profile_custom_input_ele"}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onInputChange}
      />
      <p className="profile_error_message">{errorMessage}</p>
    </div>
  );
};

export default ProfileCustomInput;
