import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import "./StripeForm.css";

const StripeForm = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href + "/",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url
    }
  };

  return (
    <div>
      <p className={"stripe_message"}>{t("FORM_FIELDS.STRIPE_MESSAGE")}</p>
      <form className="stripe_container" onSubmit={handleSubmit}>
        <PaymentElement />
        <button className="sm_green_button" disabled={!stripe}>
          {t("FORM_FIELDS.SUBMIT")}
        </button>
        {/* Show error message to your customers */}
        {errorMessage && (
          <div className="error_message_stripe">{errorMessage}</div>
        )}
      </form>
    </div>
  );
};

export default StripeForm;
