import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomerPageWrapper from "../../../components/Customers/CustomerPageWrapper/CustomerPageWrapper";
import LgGreenButton from "../../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import ArtistPageContent from "../../../components/Customers/ArtistPageContent/ArtistPageContent";
import ApplyLoanModal from "../../../components/Modals/ApplyLoanModal/ApplyLoanModal";

import SearchIcon from "../../../img/icon/icon_search_white.svg";
import arrowLeftIcon from "../../../img/icon/icon_arrow_white.svg";
import defaultBanner from "../../../img/banner_eye/banner_eye@2x.png";

import {
  getIndividualArtistPageState,
  toggleIndividualArtistPageState,
  getTargetArtist,
  toggleApplyForLoanModalState,
  getApplyForLoanModalState,
  getCustomerStatus,
  getCustomer,
  getTargetArtistsData,
} from "../../../store/customerSlice";

import "./CustomerArtists.css";

const CustomerArtists = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const individualArtistPageState = useSelector(getIndividualArtistPageState);
  const individualArtist = useSelector(getTargetArtist);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const applyForLoanModalState = useSelector(getApplyForLoanModalState);
  const status = useSelector(getCustomerStatus);
  const [id, setId] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    if (id) {
      setId(id);
      dispatch(toggleIndividualArtistPageState());
      dispatch(getTargetArtistsData(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (status === "") {
      dispatch(getCustomer());
    }
  }, [dispatch, status]);

  const handleChange = (event) => {
    if (event.target.name === "search") {
      setSearch(event.target.value);
      setPageIndex(1);
    }
  };

  const handleSubmit = (e) => {
    dispatch(toggleApplyForLoanModalState());
  };

  const individualArtistTitle = (
    <div
      className={
        status === "New_Loans_Blocked"
          ? "secondary_screen_title_new_loans_blocked"
          : "secondary_screen_artist_title_container"
      }
    >
      <div className="individual_artist_title_content">
        <img
          className="navigation_arrow"
          onClick={() => dispatch(toggleIndividualArtistPageState())}
          src={arrowLeftIcon}
          alt="left arrow icon"
        ></img>
        <div>
          <h2 className="individual_artist_title">
            {individualArtist.artistName}
            <span className="individual_studio_title">
              - {individualArtist.studioName}
            </span>
          </h2>
          <p className="individual_address">{individualArtist.address}</p>
        </div>
      </div>
      {status === "New_Loans_Blocked" ? (
        <div className="account_blocked">
          <button className="lg_green_inactive" disabled>
            {t("CUSTOMERS.APPLY")}
          </button>
          <p className="account_blocked_message">
            {t("CUSTOMERS.ACCOUNT_BLOCKED")}
          </p>
        </div>
      ) : (
        <LgGreenButton
          text={t("CUSTOMERS.APPLY")}
          onButtonClick={handleSubmit}
        />
      )}
    </div>
  );
  return (
    <div>
      <CustomerPageWrapper
        title={
          individualArtistPageState
            ? individualArtistTitle
            : t("CUSTOMERS.ARTISTS")
        }
        image={
          individualArtistPageState ? individualArtist.bannerUrl : defaultBanner
        }
      >
        {individualArtistPageState ? (
          ""
        ) : (
          <div className="searchbar_container">
            <input
              type="text"
              name="search"
              value={search}
              placeholder={t("CUSTOMERS.SEARCH_ARTISTS")}
              onChange={handleChange}
            />
            <img className="search_icon" src={SearchIcon} alt="Search Icon" />
          </div>
        )}

        <ArtistPageContent
          searchTerm={search}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          id={id}
        />
        {applyForLoanModalState ? <ApplyLoanModal /> : ""}
      </CustomerPageWrapper>
    </div>
  );
};

export default CustomerArtists;
