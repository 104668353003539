import React from "react";
import "./LgGreenButton.css";

const LgGreenButton = ({
  text,
  onButtonClick,
  value = null,
  disabled = false,
  type,
  name = null,
}) => {
  return (
    <button
      className="lg_green_button"
      disabled={disabled}
      type={type}
      value={value}
      onClick={onButtonClick}
      name={name}
    >
      {text}
    </button>
  );
};

export default LgGreenButton;
