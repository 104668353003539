import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import SuccessModal from "../SuccessModal/SuccessModal";
import FailModal from "../FailModal/FailModal";

import {
  resetPaymentComplete,
  resetErrorMessage,
  customerPayoffSubloan,
  getCustomer,
  getloanDetailsHistory,
} from "../../../store/customerSlice";

import "./MakePaymentsModal.css";

const MakePaymentsModal = ({
  makePaymentsDetails,
  toggleMakePayments,
  targetLoanId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [toggleFail, setToggleFail] = useState(false);
  const [error, setError] = useState("");
  const stripeError = useSelector((state) => state.customer.errorMessage);
  const paymentComplete = useSelector(
    (state) => state.customer.paymentComplete
  );

  useEffect(() => {
    if (paymentComplete && stripeError.length === 0) {
      setToggleFail(false);
      setToggleSuccess(true);
      setTimeout(() => {
        setToggleSuccess(false);
        dispatch(resetPaymentComplete());
        toggleMakePayments(false);
        dispatch(getCustomer());
        const data = {
          id: targetLoanId,
        };
        dispatch(getloanDetailsHistory(data));
      }, 1500);
    } else if (paymentComplete && stripeError.length !== 0) {
      setToggleFail(true);
    }
  }, [paymentComplete, stripeError]);

  useEffect(() => {
    const toggleFailMessage = (e) => {
      setToggleFail(false);
      dispatch(resetPaymentComplete());
      dispatch(resetErrorMessage());
      toggleMakePayments(false);
    };
    if (toggleFail) {
      window.addEventListener("click", (e) => toggleFailMessage(e));
    } else {
      window.removeEventListener("click", (e) => toggleFailMessage(e));
    }
    return () =>
      window.removeEventListener("click", (e) => toggleFailMessage(e));
  }, [toggleFail]);

  const handleMakePayment = async () => {
    if (!selectedValue) {
      setError("ERRORS.MAKE_PAYMENT_REQUIRED");
    } else {
      setError("");
      await dispatch(customerPayoffSubloan(selectedValue));
    }
  };

  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
  };

  const getPaymentDateMonth = (isoDate) => {
    const monthNames = [
      t("CUSTOMERS.JAN"),
      t("CUSTOMERS.FEB"),
      t("CUSTOMERS.MAR"),
      t("CUSTOMERS.APR"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUN"),
      t("CUSTOMERS.JUL"),
      t("CUSTOMERS.AUG"),
      t("CUSTOMERS.SEP"),
      t("CUSTOMERS.OCT"),
      t("CUSTOMERS.NOV"),
      t("CUSTOMERS.DEC"),
    ];
    const date = new Date(isoDate);
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    return `${month} ${dt}, ${year}`;
  };

  const renderSubLoans = () => {
    if (!makePaymentsDetails || Object.keys(makePaymentsDetails).length === 0) {
      return;
    } else {
      return makePaymentsDetails.map((subloan) => {
        return (
          <table
            key={subloan.created}
            className="make_payments_subloan_details"
          >
            <tbody>
              <tr>
                <td>{getPaymentDateMonth(subloan.created)}</td>
                <td className="make_payments_bold">
                  ${subloan.totalAmount.toFixed(2)}
                </td>
                <td className="make_payments_bold">
                  ${subloan.remainingAmount.toFixed(2)}
                </td>
                <td>
                  <button
                    className={
                      subloan.subloanId === selectedValue
                        ? "make_payments_select_active"
                        : "make_payments_select"
                    }
                    onClick={(e) => handleSelect(e)}
                    value={subloan.subloanId}
                  ></button>
                </td>
              </tr>
            </tbody>
          </table>
        );
      });
    }
  };

  return (
    <div>
      <ModalContainer
        title={t("CUSTOMERS.SELECT_LOAN")}
        onButtonClose={() => toggleMakePayments(false)}
      >
        <div className="make_payments_container">
          <table className="make_payments_header">
            <thead>
              <tr>
                <th className="first_column">{t("CUSTOMERS.CREATED")}</th>
                <th>{t("CUSTOMERS.PAYMENT_APPROVED_AMOUNT")}</th>
                <th>{t("CUSTOMERS.REMAINING_BALANCE")}</th>
                <th>{t("CUSTOMERS.SELECT")}</th>
              </tr>
            </thead>
          </table>
          {renderSubLoans()}
          {!error ? null : <p className="error_message">{t(error)}</p>}
          <LgGreenButton
            text={t("CUSTOMERS.CONTINUE")}
            onButtonClick={handleMakePayment}
          />
        </div>
      </ModalContainer>
      {toggleSuccess ? (
        <SuccessModal title={"CUSTOMERS.PAYMENT_SUCCESSFUL"} />
      ) : null}
      {toggleFail ? (
        <FailModal title={"ERRORS.STRIPE_ERROR"} message={stripeError} />
      ) : null}
    </div>
  );
};

export default MakePaymentsModal;
