export default function validate(bankingEmail) {
  let errors = {};
  const regexEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!bankingEmail) {
    errors.bankingEmail = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(bankingEmail)) {
    errors.bankingEmail = "ERRORS.VALID_EMAIL";
  }
  return errors;
}
