import React, { Component } from "react";
import ReactDOM from "react-dom";
import AvatarEditor from "./AvatarEditor/index";
import { withTranslation } from "react-i18next";
import xIcon from "../../img/icon/icon_x_white.svg";
import "./ImageEditor.css";

class ImageEditor extends Component {
  state = {
    image: "https://picsum.photos/536/354",
    preview: null,
    width: 300,
    height: 300,
    scale: 1,
    border: 50,
    borderRadius: 0,
    color: [255, 255, 255, 0.6],
    maxSize: 2,
    rotate: 0,
  };
  editor = React.createRef();
  noSupportCallback = () => {
    alert("This format is not supported");
  };

  componentDidMount = () => {
    this.setState({
      width: this.props.width,
      height: this.props.height,
      borderRadius: 10,
    });
  };

  onFileChange = (evt) => {
    var fileList = evt.target.files;
    var file = fileList;
    this.setState({ image: file });
  };
  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };
  handleWidth = (e) => {
    const width = parseInt(e.target.value);
    this.setState({ width });
  };

  handleHeight = (e) => {
    const height = parseInt(e.target.value);
    this.setState({ height });
  };
  handleBorder = (e) => {
    const border = parseInt(e.target.value);
    this.setState({ border });
  };
  handleMaxSize = (e) => {
    const maxSize = parseInt(e.target.value);
    this.setState({ maxSize });
  };
  handleBorderRadius = (e) => {
    const borderRadius = parseInt(e.target.value);
    this.setState({ borderRadius });
  };
  rotateScale = (e) => {
    const scale = parseFloat(e.target.value);
    e.preventDefault();
    this.setState({
      rotate: scale,
    });
  };
  handleSave = (data) => {
    const img = this.editor.current.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.current.getCroppingRect();

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    });
  };

  handleSaveToDatabase = (data) => {
    const img = this.editor.current.getImageScaledToCanvas().toDataURL();
    this.props.handleImageUpload(img);
  };

  render() {
    const { t } = this.props;
    return (
      <div className="image_editor_background_container">
        <div className="image_editor_container">
          <div className="image_editor">
            <div className="image_editor_title">
              <h3>{t(this.props.title)}</h3>
              <img
                src={xIcon}
                alt="x icon"
                onClick={this.props.closeBannerInput}
              />
            </div>
            <div className="image_editor_content">
              <div className="image_editor_content_preview">
                <AvatarEditor
                  ref={this.editor}
                  image={this.state.image}
                  scale={this.state.scale}
                  width={this.state.width}
                  height={this.state.height}
                  border={this.state.border}
                  borderRadius={this.state.borderRadius}
                  color={this.state.color} // RGBA
                  rotate={this.state.rotate}
                  crossOrigin="anonymous"
                  maxSize={this.state.maxSize}
                  noSupportCallback={this.noSupportCallback}
                  filetype={["png", "jpg", "jpeg"]}
                />

                {!!this.state.preview && (
                  <>
                    <h1 className="preview_image">{t('ARTISTS.PREVIEW_IMAGE')}</h1>
                    <img
                      src={this.state.preview.img}
                      alt="preview"
                      style={{
                        borderRadius: `${
                          (Math.min(
                            this.state.preview.height,
                            this.state.preview.width
                          ) +
                            10) *
                          (this.state.preview.borderRadius / 2 / 100)
                        }px`,
                      }}
                    />
                  </>
                )}
              </div>

              <div className="image_editor_controls">
                <label htmlFor="file-upload" className="image_file_input">
                  {t("ARTISTS.CHOOSE_FILE")}
                </label>
                <input
                  style={{ display: "none" }}
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  onChange={this.onFileChange}
                />
                {/* avatar width:{" "}
                <input
                  className="mt-2 p-1"
                  type="text"
                  onChange={this.handleWidth}
                  value={this.state.width}
                /> */}
                {/* avatar height:{" "}
                <input
                  className="mt-2 p-1"
                  type="text"
                  onChange={this.handleHeight}
                  value={this.state.height}
                /> */}
                {/* border :{" "}
                <input
                  className="mt-2 p-1"
                  type="text"
                  onChange={this.handleBorder}
                  value={this.state.border}
                /> */}
                {/* maxSize :{" "}
                <input
                  className="mt-2 p-1"
                  type="text"
                  onChange={this.handleMaxSize}
                  value={this.state.maxSize}
                /> */}
                {/* border radius:
                <input
                  className="mt-2"
                  type="range"
                  onChange={this.handleBorderRadius}
                  min="1"
                  max="500"
                  step="1"
                  defaultValue="0"
                />{" "} */}
                <br />
                {t('ARTISTS.ROTATE_SCALE')}:{" "}
                <input
                  className="image_range"
                  type="range"
                  onChange={this.rotateScale}
                  min="0"
                  max="180"
                  step="1"
                  defaultValue="0"
                />{" "}
                <br />
                {t('ARTISTS.SCALE')}: <br />
                <input
                  className="image_range"
                  type="range"
                  onChange={this.handleScale}
                  min={"1"}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />{" "}
                <br />
                <input
                  className="image_editor_button"
                  type="button"
                  onClick={this.handleSave}
                  value={t('ARTISTS.PREVIEW')}
                />
                <input
                  className="image_editor_button"
                  type="button"
                  onClick={this.handleSaveToDatabase}
                  value={t('ARTISTS.SAVE_PHOTO')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ImageEditor);
