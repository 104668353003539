import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FormContainer from "../../components/FormContainer/FormContainer";
import CustomInput from "../../components/ButtonsFormFields/CustomInput/CustomInput";
import LgGreenButton from "../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import PasswordInput from "../../components/ButtonsFormFields/PasswordInput/PasswordInput";
import ErrorBoundary from "../../components/ErrorBoundry/ErrorBoundry";
import TosModal from "../../components/Modals/TOSModal/TosModal";
import PPModal from "../../components/Modals/PPModal/PPModal";

import validate from "./LoginFormValidationRules";

import {
  loginUser,
  addEmail,
  getErrorMessage,
  getRole,
  getAccessToken,
  getIsLoggedIn,
  getTosUpToDate,
  getTosModalState,
  getPPModalState,
} from "../../store/authSlice";

import "./login.css";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const role = useSelector(getRole);
  const accessToken = useSelector(getAccessToken);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const errorMessage = useSelector(getErrorMessage);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const tosUpToDate = useSelector(getTosUpToDate);
  const tosModalState = useSelector(getTosModalState);
  const ppModalState = useSelector(getPPModalState);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn === true && tosUpToDate === true) {
      if (accessToken && role === "Artist") {
        navigate("/artist");
      }
      if (accessToken && role === "Customer") {
        if (location.state?.from) {
          if (
            location.state.from.pathname === "/userArtist/" ||
            location.state.from.pathname === "/userArtist"
          ) {
            navigate(`/customerArtists/${location.state.from.search}`);
          }
        } else {
          navigate("/customer");
        }
      }
    } else {
      if (errorMessage === "ERRORS.UNCONFIRMED_STATUS") {
        navigate("/verifyAccount");
      } else if (tosUpToDate === false) {
      }
    }
  }, [
    accessToken,
    isLoggedIn,
    role,
    errorMessage,
    tosUpToDate,
    navigate,
    location.state,
  ]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      let data = {
        email: email.trim(),
        password: password.trim(),
      };
      sessionStorage.setItem("email", email);
      dispatch(addEmail(email));
      dispatch(loginUser(data));
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting, email, password, dispatch]);

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(email, password));
  };

  return (
    <div className="login">
      <ErrorBoundary>
        <FormContainer
          title={t("FORM_FIELDS.LOGIN")}
          onFormSubmit={handleSubmit}
          navigatelink={() => navigate(-1 || "/")}
        >
          <CustomInput
            type="email"
            name="email"
            value={email}
            placeholder={t("FORM_FIELDS.EMAIL")}
            onInputChange={handleChange}
            errorMessage={t(errors.email)}
            required
          />
          <PasswordInput
            name="password"
            value={password}
            placeholder={t("FORM_FIELDS.PASSWORD")}
            onInputChange={handleChange}
            errorMessage={t(errors.password)}
            required
          />
          <p className={errorMessage ? "general_error" : "hidden"}>
            {errorMessage ? t(errorMessage) : null}
          </p>
          <div className="login_button">
            <LgGreenButton
              text={t("FORM_FIELDS.LOGIN")}
              onButtonClick={handleSubmit}
            />
          </div>
          <div className="login_secondary_buttons">
            <button onClick={() => navigate("/")}>{t("NAVBAR.SIGN_UP")}</button>
            <button onClick={() => navigate("/forgotpassword")}>
              {t("NAVBAR.FORGOT_PASSWORD")}
            </button>
          </div>
        </FormContainer>
        {tosModalState ? <TosModal /> : ""}
        {ppModalState ? <PPModal /> : ""}
      </ErrorBoundary>
    </div>
  );
};

export default Login;
