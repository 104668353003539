import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import CustomerPageWrapper from "../../../components/Customers/CustomerPageWrapper/CustomerPageWrapper";
import CustomerEditInfo from "../../../components/Modals/CustomerEditInfo/CustomerEditInfo";
import ChangePassword from "../../../components/Modals/ChangePassword/ChangePassword";
import Feedback from "../../../components/Feedback/Feedback";
import ModalContainer from "../../../components/Modals/ModalContainer/ModalContainer";
import SuccessModal from "../../../components/Modals/SuccessModal/SuccessModal";
import StripeForm from "../../../components/Modals/StripeForm/StripeForm";

import defaultBanner from "../../../img/banner_eye/banner_eye@2x.png";
import rightIcon from "../../../img/icon/icon_right.svg";
import arrowLeftIcon from "../../../img/icon/icon_arrow_white.svg";

import {
  getCustomerEditInfoModalState,
  getCustomerInfo,
  toggleCustomerEditInfoModalState,
  getCustomer,
} from "../../../store/customerSlice";
import {
  reset,
  getChangePasswordModalState,
  toggleChangePasswordModalState,
} from "../../../store/authSlice";
import {
  getAbout,
  getAboutMessage,
  getTOS,
  getTOSMessage,
  getPP,
  getPPMessage,
} from "../../../store/contentSlice";
import { editCustomerPaymentSecret } from "../../../api/customer";

import { logoutUser } from "../../../App";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPEKEY } from "../../../utils/stripe";
import { loadStripe } from "@stripe/stripe-js";

import "./CustomerProfile.css";

const stripePromise = loadStripe(STRIPEKEY);

const CustomerProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const customerInfo = useSelector(getCustomerInfo);
  const customerEditInfoModalState = useSelector(getCustomerEditInfoModalState);
  const changePasswordModalState = useSelector(getChangePasswordModalState);
  const [toggleAbout, setToggleAbout] = useState(false);
  const [toggleTOS, setToggleTOS] = useState(false);
  const [togglePP, setTogglePP] = useState(false);
  const [toggleFeedback, setToggleFeedback] = useState(false);
  const [toggleStripe, setToggleStripe] = useState(false);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const aboutContent = useSelector(getAboutMessage);
  const termsOfServiceContent = useSelector(getTOSMessage);
  const privacyPolicyContent = useSelector(getPPMessage);
  const [clientSecret, setClientSecret] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const section = queryParams.get("section");

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",

      variables: {
        colorPrimary: "#37c452",
        colorBackground: "#D6D6D6",
        colorText: "#30313d",
        colorDanger: "#ff5a5a",
        fontFamily: "roboto, system-ui, sans-serif",
        spacingUnit: "4px",
        borderRadius: "6px",
        // See all possible variables below
      },
    },
  };

  useEffect(() => {
    if (section === "about") {
      setToggleAbout(true);
      dispatch(getAbout());
    } else if (section === "feedback") {
      setToggleAbout(false);
      setToggleFeedback(true);
    }
  }, [location.search, section]);

  useEffect(() => {
    if (toggleAbout) {
      dispatch(getAbout());
    } else if (toggleTOS) {
      dispatch(getTOS());
    } else if (togglePP) {
      dispatch(getPP());
    }
  }, [toggleAbout, toggleTOS, togglePP, toggleFeedback, dispatch]);

  useEffect(() => {
    dispatch(getCustomer());
  }, [dispatch]);

  useEffect(() => {
    let timer1;
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("redirect_status");
    if (term === "succeeded") {
      navigate("/customerProfile");
      setToggleSuccess(true);
      timer1 = setTimeout(() => {
        setToggleSuccess(false);
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [setToggleSuccess]);

  const updateStripe = async () => {
    const res = await editCustomerPaymentSecret();
    setToggleStripe(true);
    setClientSecret(res);
  };

  const renderStripe = () => {
    if (toggleStripe && clientSecret) {
      return (
        <div className="stripe_container">
          <ModalContainer
            title={t("CUSTOMERS.STRIPE_SETUP")}
            onButtonClose={() => setToggleStripe(!toggleStripe)}
          >
            <Elements
              stripe={stripePromise}
              options={options}
              key={clientSecret}
            >
              <StripeForm />
            </Elements>
          </ModalContainer>
        </div>
      );
    }
  };
  const handleLogout = (e) => {
    e.preventDefault();
    logoutUser();
    dispatch(reset());
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const renderTitle = () => {
    if (toggleAbout) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => {
              setToggleAbout(!toggleAbout);
              navigate("/CustomerProfile");
            }}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">{t("NAVBAR.ABOUT")}</h2>
        </div>
      );
    } else if (toggleTOS) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => setToggleTOS(!toggleTOS)}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">
            {t("MODALS.TERMS_OF_SERVICE")}
          </h2>
        </div>
      );
    } else if (togglePP) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => setTogglePP(!togglePP)}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">
            {t("MODALS.PRIVACY_POLICY")}
          </h2>
        </div>
      );
    } else if (toggleFeedback) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => {
              setToggleFeedback(!toggleFeedback);
              navigate("/CustomerProfile");
            }}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="customer_secondary_title">{t("NAVBAR.FEEDBACK")}</h2>
        </div>
      );
    } else {
      return t("CUSTOMERS.ACCOUNT");
    }
  };

  const renderProfile = (
    <div className="profile_content">
      <div className="profile_details_container">
        <div className="profile_details_content_container">
          <div className="profile_details_content">
            <h2 className="customer_profile_subtitles">
              {t("CUSTOMERS.PERSONAL_DETAILS")}
            </h2>
            <p className="customer_profile_details">
              {customerInfo.firstName} {customerInfo.lastName}
            </p>
            <p className="customer_profile_details">{customerInfo.phone}</p>
          </div>
          <button
            className="edit_information_button"
            onClick={() => dispatch(toggleCustomerEditInfoModalState())}
          >
            {t("FORM_FIELDS.EDIT_INFO")}
          </button>
        </div>
      </div>
      {customerEditInfoModalState ? (
        <CustomerEditInfo
          onModalClose={() => dispatch(toggleCustomerEditInfoModalState())}
        />
      ) : (
        ""
      )}
      <div className="customer_password_wrapper">
        <div className="profile_details_content">
          <h2 className="customer_profile_subtitles">
            {t("ARTISTS.PASSWORD")}
          </h2>
          <p className="customer_profile_details">{customerInfo.email}</p>
          <p className="customer_profile_details">
            <span className="password_dot">●●●●●●●●●●</span>
          </p>
          <p></p>
        </div>
        <button
          className="change_password_button"
          onClick={() => dispatch(toggleChangePasswordModalState())}
        >
          {t("FORM_FIELDS.CHANGE_PASSWORD")}
        </button>
      </div>
      {changePasswordModalState ? <ChangePassword /> : ""}
      <div className="customer_banking_wrapper">
        <div className="customer_password_header">
          <h2 className="customer_profile_subtitles">
            {t("CUSTOMERS.PAYMENT_METHOD")}
          </h2>
          <p className="customer_profile_details">
            {t("CUSTOMERS.CREDIT_CARD")}
          </p>
        </div>
        <div className="edit_banking_button_container">
          <button className="edit_banking_button" onClick={updateStripe}>
            {t("FORM_FIELDS.EDIT_INFO")}
          </button>
        </div>
      </div>
      {/* toggle edit amounts button
  {

  
  ) : (
    ""
  )} */}
      <div className="artist_profile_links">
        <h2 className="profile_subtitles">{t("NAVBAR.ABOUT")}</h2>
        <div onClick={() => setToggleAbout(!toggleAbout)}>
          <img src={rightIcon} alt="right icon" />
        </div>
      </div>
      <div className="artist_profile_links">
        <h2 className="profile_subtitles">{t("NAVBAR.FEEDBACK")}</h2>
        <div onClick={() => setToggleFeedback(!toggleFeedback)}>
          <img src={rightIcon} alt="right icon" />
        </div>
      </div>
      <div className="artist_profile_links">
        <h2 className="profile_subtitles">{t("MODALS.TERMS_OF_SERVICE")}</h2>
        <div onClick={() => setToggleTOS(!toggleTOS)}>
          <img src={rightIcon} alt="right icon" />
        </div>
      </div>
      <div className="artist_profile_links">
        <h2 className="profile_subtitles">{t("MODALS.PRIVACY_POLICY")}</h2>
        <div onClick={() => setTogglePP(!togglePP)}>
          <img src={rightIcon} alt="right icon" />
        </div>
      </div>
      <div className="logout_button_container">
        <button className="logout_button" onClick={handleLogout}>
          {t("FORM_FIELDS.LOGOUT")}
        </button>
      </div>
    </div>
  );

  const renderAbout = aboutContent ? (
    <div
      className="content_container"
      dangerouslySetInnerHTML={{ __html: aboutContent.htmlContent }}
    ></div>
  ) : (
    ""
  );

  const renderTOS = termsOfServiceContent ? (
    <div
      className="content_container"
      dangerouslySetInnerHTML={{ __html: termsOfServiceContent.htmlContent }}
    ></div>
  ) : (
    ""
  );

  const renderPP = privacyPolicyContent ? (
    <div
      className="content_container"
      dangerouslySetInnerHTML={{ __html: privacyPolicyContent.htmlContent }}
    ></div>
  ) : (
    ""
  );

  const renderFeedback = <Feedback />;

  const renderContent = () => {
    if (toggleAbout) {
      return renderAbout;
    } else if (toggleTOS) {
      return renderTOS;
    } else if (togglePP) {
      return renderPP;
    } else if (toggleFeedback) {
      return renderFeedback;
    } else {
      return renderProfile;
    }
  };

  return (
    <div>
      <CustomerPageWrapper title={renderTitle()} image={defaultBanner}>
        <div className="profile_container">
          {renderContent()}
          {renderStripe()}
        </div>
        {toggleSuccess ? (
          <SuccessModal title={"CUSTOMERS.CARD_INPUT_SUCCESSFUL"} />
        ) : null}
      </CustomerPageWrapper>
    </div>
  );
};

export default CustomerProfile;
