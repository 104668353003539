import i18next from "../../../i18n";

export default function validate(
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  phone,
  howDidHear,
  howDidHearOther
) {
  let errors = {};
  const regexEmail = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const regexPhone = new RegExp(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  );
  const regexPassword = new RegExp(
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/
  );
  const regexName = new RegExp(/^[0-9a-zA-Z]+$/);
  if (!email) {
    errors.email = i18next.t("ERRORS.EMAIL_REQUIRED");
  } else if (!regexEmail.test(email)) {
    errors.email = i18next.t("ERRORS.VALID_EMAIL");
  }
  if (!password) {
    errors.password = i18next.t("ERRORS.PASSWORD_REQUIRED");
  }
  if (confirmPassword === "") {
    errors.confirmPassword = i18next.t("ERRORS.PASSWORD_REQUIRED");
  }
  if (password !== confirmPassword) {
    errors.confirmPassword = i18next.t("ERRORS.PASSWORDS_MUST_MATCH");
  }
  if (!regexPassword.test(password)) {
    errors.confirmPassword = i18next.t("ERRORS.VALID_PASSWORD");
  }
  if (!firstName) {
    errors.firstName = i18next.t("ERRORS.FIRSTNAME_REQUIRED");
  } else if (!regexName.test(firstName)) {
    errors.firstName = i18next.t("ERRORS.VALID_NAME");
  }
  if (!lastName) {
    errors.lastName = i18next.t("ERRORS.LASTNAME_REQUIRED");
  } else if (!regexName.test(lastName)) {
    errors.lastName = i18next.t("ERRORS.VALID_NAME");
  }
  if (!phone) {
    errors.phone = i18next.t("ERRORS.PHONE_REQUIRED");
  } else if (!regexPhone.test(phone)) {
    errors.phone = i18next.t("ERRORS.VALID_PHONE");
  }
  if (!howDidHear || howDidHear === "howDidHear") {
    errors.howDidHear = i18next.t("ERRORS.HOW_DID_HEAR_REQUIRED");
  }
  if (howDidHear === "other") {
    if (!howDidHearOther) {
      errors.howDidHearOther = i18next.t("ERRORS.HOW_DID_HEAR_REQUIRED");
    }
  }

  return errors;
}
