import axios from "./api";

export function registerCustomer(data) {
  return axios({
    url: `/Customer`,
    method: "post",
    data,
  });
}

export function getArtistsData(data) {
  return axios({
    url: `/Artist/publicProfile`,
    method: "post",
    data,
  });
}

export function getIndividualArtist(id) {
  return axios({
    url: `/Artist/${id}`,
    method: "get",
  });
}

export function getLoanFee() {
  return axios({
    url: `/Loan/calculatorValues`,
    method: "get",
  });
}
export function getCustomerLoans() {
  return axios({
    url: `/Loan`,
    method: "get",
  });
}

export function loanApplicationRequest(data) {
  return axios({
    url: `/Loan/loanApplicationRequest`,
    method: "post",
    data,
  });
}

export function startAgeRequest(data) {
  return axios({
    url: `/Loan/ageRequest`,
    method: "post",
    data,
  });
}

export function startBankRequest(data) {
  return axios({
    url: `/Loan/bankRequest`,
    method: "post",
    data,
  });
}

export function startSignatureRequest(data) {
  return axios({
    url: `/Loan/signatureRequest`,
    method: "post",
    data,
  });
}

export function startCancelRequest(data) {
  return axios({
    url: `/Loan/cancelRequest`,
    method: "post",
    data,
  });
}

export function getCustomerStripeSecret(data) {
  return axios({
    url: `/Loan/paymentAccount`,
    method: "post",
    data,
  });
}

export function setLoanApproved(data) {
  return axios({
    url: `/Loan/Approved`,
    method: "post",
    data,
  });
}

export function getCustomerData() {
  return axios({
    url: `/Customer`,
    method: "get",
  });
}

export function editCustomer(data) {
  return axios({
    url: `/Customer`,
    method: "put",
    data,
  });
}

export function editCustomerPaymentSecret() {
  return axios({
    url: `/Customer/paymentDetails`,
    method: "put",
  });
}

export function getContractFromServer(data) {
  return axios({
    url: `/Loan/contractRequest`,
    method: "post",
    data,
  });
}

export function returnSignedContract(data) {
  return axios({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: `/Loan/newSignatureRequest`,
    method: "put",
    data,
  });
}

export function approveSubLoan(data) {
  return axios({
    url: `/SubLoan/approval`,
    method: "post",
    data,
  });
}

export function getLoanHistory(data) {
  return axios({
    url: `/Loan/loanhistory`,
    method: "post",
    data,
  });
}

export function getLoanUpcomingPayments(data) {
  return axios({
    url: `/Loan/loanupcoming`,
    method: "post",
    data,
  });
}

export function getPaymentDetails(data) {
  return axios({
    url: `/Loan/paymentdetails`,
    method: "post",
    data,
  });
}

export function getMakePaymentDetailsFromServer(data) {
  return axios({
    url: `/Loan/subLoan/amountRemaining`,
    method: "post",
    data,
  });
}

export function getContractForViewing(data) {
  return axios({
    url: `/Loan/signedContract`,
    method: "put",
    data,
  });
}

export function payOffSubloanWithMakePayment(id) {
  return axios({
    url: `/Loan/subLoan/payOff/${id}`,
    method: "post",
  });
}

export function getMissedPaymentInfo(data) {
  return axios({
    url: `/Loan/late/getinfo`,
    method: "post",
    data,
  });
}

export function payOffMissedPaymentWithMakePayment(data) {
  return axios({
    url: `/Loan/late/payOff`,
    method: "post",
    data,
  });
}

export function getCustomerHistory(data) {
  return axios({
    url: `/Loan/Customer/History`,
    method: "post",
    data,
  });
}


