import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import SuccessModal from "../SuccessModal/SuccessModal";

import {
  getTargetLoanId,
  toggleCompleteTattooModalState,
  getCompleteTattooModalState,
  getClientData,
} from "../../../store/artistSlice";
import { completeTattoo } from "../../../api/artist";

import "./CompleteTattooModal.css";


const CompleteTattooModal = ({ search }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const completeTattooModalState = useSelector(getCompleteTattooModalState);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [error, setError] = useState("");
  const targetLoanId = useSelector(getTargetLoanId);

  const onCompleteTattoo = async (e) => {
    e.preventDefault();
    try {
      const data = {
        id: targetLoanId,
      };
      const res = await completeTattoo(data);
      setToggleSuccess(true);
      dispatch(toggleCompleteTattooModalState());
      setTimeout(() => {
        setToggleSuccess(false);
        const searchTerm = !search ? "" : search;
        const data = {
          searchTerms: searchTerm,
          pageIndex: 1,
          pageSize: 4,
        };
        dispatch(getClientData(data));
      }, 1500);
    } catch (error) {
      console.log(error.response);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  return (
    <div>
      {completeTattooModalState ? (
        <ModalContainer
          title={t("ARTISTS.COMPLETE_TATTOO")}
          onButtonClose={() => dispatch(toggleCompleteTattooModalState())}
        >
          <div className="complete_tattoo_container">
            <p>{t("ARTISTS.COMPLETE_TATTOO_MESSAGE")}</p>
            <LgGreenButton
              text={t("ARTISTS.COMPLETE_TATTOO")}
              onButtonClick={onCompleteTattoo}
            />
          </div>
        </ModalContainer>
      ) : null}
      {toggleSuccess ? (
        <SuccessModal
          title={"FORM_FIELDS.TATTOO_COMPLETED"}
          message={"FORM_FIELDS.TATTOO_COMPLETED_MESSAGE"}
        />
      ) : null}
    </div>
  );
};

export default CompleteTattooModal;
