import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormContainer from "../../../components/FormContainer/FormContainer";
import CustomInput from "../../../components/ButtonsFormFields/CustomInput/CustomInput";
import LgGreenButton from "../../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import PasswordInput from "../../../components/ButtonsFormFields/PasswordInput/PasswordInput";

import {
  getCustomerError,
  getCustomerStatus,
  signUpCustomer,
} from "../../../store/customerSlice";
import { addEmail } from "../../../store/authSlice";

import validate from "./CustomerSignUpFormValidationRules";
import "./CustomerSignUp.css";

const CustomerSignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [howDidHear, setHowDidHear] = useState("");
  const [howDidHearOther, setHowDidHearOther] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const status = useSelector(getCustomerStatus);
  const errorMessage = useSelector(getCustomerError);

  useEffect(() => {
    if (!status) {
    } else {
      if (status === "Email_Not_Verified") {
        navigate("/verifyAccount");
      }
    }
  }, [status, navigate]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      let howHeard;
      if (howDidHear === "other") {
        howHeard = howDidHearOther;
      } else {
        howHeard = howDidHear;
      }
      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        password: password,
        email: email,
        passwordConfirmation: confirmPassword,
        howHeard: howHeard,
      };
      addEmail(email);
      sessionStorage.setItem("email", email);
      dispatch(signUpCustomer(data));
    }
  }, [
    errors,
    isSubmitting,
    firstName,
    lastName,
    phone,
    password,
    email,
    confirmPassword,
    howDidHear,
    howDidHearOther,
    dispatch,
  ]);

  const handleChange = (event) => {
    event.persist();
    event.target.value.trim();
    setIsSubmitting(false);
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
    if (event.target.name === "confirmPassword") {
      setConfirmPassword(event.target.value);
    }
    if (event.target.name === "firstName") {
      if (event.target.value.length <= 20) {
        setFirstName(event.target.value);
      }
    }
    if (event.target.name === "lastName") {
      if (event.target.value.length <= 20) {
        setLastName(event.target.value);
      }
    }
    if (event.target.name === "howDidHear") {
      setHowDidHear(event.target.value);
    }
    if (event.target.name === "howDidHearOther") {
      if (event.target.value.length < 40) {
        setHowDidHearOther(event.target.value);
      }
    }
    if (event.target.name === "phone") {
      const input = event.target.value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 11);
      let phoneNumber;
      if (input.length > 6) {
        phoneNumber = `(${areaCode})${middle}-${last}`;
      } else if (input.length > 3) {
        phoneNumber = `(${areaCode})${middle}`;
      } else if (input.length > 0) {
        phoneNumber = `(${areaCode}`;
      }
      setPhone(phoneNumber);
    }
  };

  const signUpCustomerSubmit = async (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    setErrors(
      validate(
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        phone,
        howDidHear,
        howDidHearOther
      )
    );
  };

  return (
    <div className="artist_sign_up">
      <FormContainer
        title={t("FORM_FIELDS.SIGN_UP")}
        onFormSubmit={signUpCustomerSubmit}
        navigatelink={() => navigate(-1)}
      >
        <CustomInput
          type="text"
          name="firstName"
          value={firstName}
          placeholder={t("FORM_FIELDS.FIRST_NAME")}
          onInputChange={handleChange}
          errorMessage={errors.firstName}
          required
        />
        <CustomInput
          type="text"
          name="lastName"
          value={lastName}
          placeholder={t("FORM_FIELDS.LAST_NAME")}
          onInputChange={handleChange}
          errorMessage={errors.lastName}
          required
        />
        <CustomInput
          type="tel"
          name="phone"
          value={phone}
          placeholder={t("FORM_FIELDS.CUST_PHONE")}
          onInputChange={handleChange}
          errorMessage={errors.phone}
          required
        />
        <CustomInput
          type="email"
          name="email"
          value={email}
          placeholder={t("FORM_FIELDS.CUST_EMAIL")}
          onInputChange={handleChange}
          errorMessage={errors.email}
          required
        />
        <PasswordInput
          name="password"
          value={password}
          placeholder={t("FORM_FIELDS.PASSWORD")}
          onInputChange={handleChange}
          errorMessage={errors.password}
          required
        />
        <PasswordInput
          name="confirmPassword"
          value={confirmPassword}
          placeholder={t("FORM_FIELDS.CONFIRM_PASSWORD")}
          onInputChange={handleChange}
          errorMessage={errors.confirmPassword}
          required
        />
        <select
          className="province_select"
          name="howDidHear"
          defaultValue={howDidHear}
          onChange={handleChange}
        >
          <option value="howDidHear">{t("FORM_FIELDS.HOW_DID_HEAR")}</option>
          <option value="social media">{t("FORM_FIELDS.SOCIAL_MEDIA")}</option>
          <option value="in person">{t("FORM_FIELDS.IN_PERSON")}</option>
          <option value="search">{t("FORM_FIELDS.ONLINE_SEARCH")}</option>
          <option value="other">{t("FORM_FIELDS.OTHER")}</option>
        </select>
        <p className={errors.howDidHear ? "general_error" : "hidden"}>
          {errors.howDidHear}
        </p>
        {howDidHear === "other" ? (
          <CustomInput
            type="test"
            name="howDidHearOther"
            value={howDidHearOther}
            placeholder={t("FORM_FIELDS.HOW_DID_HEAR")}
            onInputChange={handleChange}
            errorMessage={errors.howDidHearOther}
            required
          />
        ) : null}
        <p className={errorMessage ? "general_error" : "hidden"}>
          {errorMessage ? t(errorMessage) : ""}
        </p>
        <div className="register_button">
          <LgGreenButton
            text={t("FORM_FIELDS.REGISTER")}
            onButtonClick={signUpCustomerSubmit}
          />
        </div>
        <div className="customer_secondary_buttons">
          <p>{t("CUSTOMERS.ALREADY_ASSISTANCE")}</p>
          <button className="resend_button" onClick={() => navigate("/login")}>
            {t("CUSTOMERS.APPLY_ANOTHER")}
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default CustomerSignUp;
