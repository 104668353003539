import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import {
  getPPModalState,
  togglePPModalState,
  updateTOSandPPAcceptance,
} from "../../../store/authSlice";
import { getPPMessage, getPP } from "../../../store/contentSlice";
import { getDate } from "../../../utils/helpers";


const PPModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ppModalState = useSelector(getPPModalState);
  const privacyPolicyContent = useSelector(getPPMessage);

  useEffect(() => {
    if (ppModalState) {
      dispatch(getPP());
    }
  }, [ppModalState, dispatch]);

  const confirmAcceptance = () => {
    dispatch(updateTOSandPPAcceptance());
    dispatch(togglePPModalState());
  };

  return (
    <ModalContainer
      className="privacy_policy"
      title={t("MODALS.PRIVACY_POLICY")}
      onButtonClose={() => dispatch(togglePPModalState())}
    >
      <div className="pp_version">
        <p>
          {t("LANDING_PAGE.VERSION")} {privacyPolicyContent.version}
        </p>
        <p>
          {t("CUSTOMERS.UPDATED_ON")} {getDate(privacyPolicyContent.created)}
        </p>
      </div>
      <div
        className="pp_content"
        dangerouslySetInnerHTML={{
          __html: privacyPolicyContent.htmlContent,
        }}
      ></div>
      <LgGreenButton
        text={t("FORM_FIELDS.I_ACCEPT")}
        onButtonClick={confirmAcceptance}
      />
    </ModalContainer>
  );
};

export default PPModal;
