import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ArtistPageWrapper from "../../../components/Artists/ArtistPageWrapper/ArtistPageWrapper";

import logo from "../../../img/logo/logo_main.png";

import { getArtistData, getArtistInfo } from "../../../store/artistSlice";
import { getContentType } from "../../../api/content";
import { htmlDecode } from "../../../utils/helpers";

import "./ArtistRatesTerms.css";

const ArtistRatesTerms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { artistInfo } = useSelector(getArtistInfo);
  const [content, setContent] = useState("");

  useEffect(() => {
    const getRatesTerms = async () => {
      try {
        let userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes("en")) {
          userLang = "en";
        }
        let res = await getContentType("Rates_Terms", userLang);
        let content = htmlDecode(res.htmlContent);
        setContent(content);
        return res;
      } catch (error) {
        console.log(error);
      }
    };
    getRatesTerms();
  }, []);

  useEffect(() => {
    dispatch(getArtistData());
  }, [dispatch]);

  const dashboardContent = () => {
    if (!artistInfo) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("ERRORS.GENERAL_ERROR")}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="not_verified">
          <div
            className="content_container"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      );
    }
  };

  return (
    <div>
      <ArtistPageWrapper title={t("NAVBAR.RATES_TERMS")}>
        <div>{dashboardContent()}</div>
      </ArtistPageWrapper>
    </div>
  );
};

export default ArtistRatesTerms;
