import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from "./store/store";
import { Suspense } from "react";
import { injectStore } from "./api/api";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { registerLicense } from "@syncfusion/ej2-base";

//adding sentry for error monitoring
if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    environment: process.env.REACT_APP_ENV,
  });
}
// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);


injectStore(store);
ReactDOM.render(
    <Suspense fallback="...is loading">
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </ReduxProvider>
    </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
