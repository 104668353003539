import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CustomerPageWrapper from "../../../components/Customers/CustomerPageWrapper/CustomerPageWrapper";
import LgGreenButton from "../../../components/ButtonsFormFields/LgGreenButton/LgGreenButton";
import ApprovalCalc from "../../../components/Customers/ApprovalCalc/ApprovalCalc";
import LoanCard from "../../../components/Customers/LoanCard/LoanCard";
import InveriteModal from "../../../components/Modals/LoanProcessModal/LoanProcessModal";
import LoanApprovalCard from "../../../components/Customers/LoanApprovalCard/LoanApprovalCard";
import ErrorBoundary from "../../../components/ErrorBoundry/ErrorBoundry";
import ApproveLoanModal from "../../../components/Modals/ApproveLoanModal/ApproveLoanModal";
import DeclineLoanModal from "../../../components/Modals/DeclineLoanModal/DeclineLoanModal";
import LoanDetails from "../../../components/Customers/LoanDetails/LoanDetails";
import FailModal from "../../../components/Modals/FailModal/FailModal";

import arrowLeftIcon from "../../../img/icon/icon_arrow_white.svg";
import logo from "../../../img/logo/logo_main.png";
import defaultBanner from "../../../img//banner_eye/banner_eye@2x.png";

import {
  getLoans,
  getCustomerLoansInfo,
  getInveriteModalState,
  getCustomerError,
  getIndividualLoanViewMoreState,
  getLoanInfo,
  toggleIndividualLoanViewMoreState,
  resetErrorMessage,
} from "../../../store/customerSlice";

import "./CustomerDashboard.css";

const UserDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggleCalculator, setToggleCalculator] = useState(false);
  const customerLoans = useSelector(getCustomerLoansInfo);
  const inveriteModalState = useSelector(getInveriteModalState);
  const targetLoan = useSelector(getLoanInfo);
  const errorMessage = useSelector(getCustomerError);
  const individualLoanViewMoreState = useSelector(
    getIndividualLoanViewMoreState
  );
  const [toggleFail, setToggleFail] = useState(false);

  useEffect(() => {
    if (individualLoanViewMoreState === true) {
      dispatch(toggleIndividualLoanViewMoreState());
    }
  }, []);

  useEffect(() => {
    const getInitialLoans = () => {
      dispatch(getLoans());
    };
    getInitialLoans();
    if (
      errorMessage === "ERRORS.AGE_DENIED" ||
      errorMessage === "ERRORS.REFRESH_PAGE"
    ) {
      dispatch(getLoans());
    }
    if (errorMessage === "ERRORS.TOO_MANY_TRIES") {
      setToggleFail(true);
    }
  }, [dispatch, errorMessage]);

  const handleCalcSubmit = () => {};

  useEffect(() => {
    const toggleFailMessage = (e) => {
      setToggleFail(false);
      dispatch(resetErrorMessage());
    };
    if (toggleFail) {
      window.addEventListener("click", (e) => toggleFailMessage(e));
    } else {
      window.removeEventListener("click", (e) => toggleFailMessage(e));
    }
    return () =>
      window.removeEventListener("click", (e) => toggleFailMessage(e));
  }, [toggleFail]);

  const dashboardContent = () => {
    if (!customerLoans) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>{t("ERRORS.GENERAL_ERROR")}</p>
          </div>
        </div>
      );
    } else if (customerLoans.length === 0) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
          <div className="not_verified_content">
            <p>
              {t("CUSTOMERS.NO_CURRENT_LOANS_MESSAGE1")}
              <Link to="/customerArtists">
                {t("CUSTOMERS.NO_CURRENT_LOANS_MESSAGE2")}
              </Link>
              {t("CUSTOMERS.NO_CURRENT_LOANS_MESSAGE3")}
            </p>
          </div>
        </div>
      );
    } else {
      return customerLoans.map((loan) => {
        if (
          loan.fullLoanStatus === "Waiting_Verification" ||
          loan.fullLoanStatus === "Age_Initiated" ||
          loan.fullLoanStatus === "Age_Completed" ||
          loan.fullLoanStatus === "Age_Denied" ||
          loan.fullLoanStatus === "Risk_Score_Initiated" ||
          loan.fullLoanStatus === "Risk_Score_Completed" ||
          loan.fullLoanStatus === "Risk_Score_Denied" ||
          loan.fullLoanStatus === "Risk_Score_Error" ||
          loan.fullLoanStatus === "Contract_Initiated" ||
          loan.fullLoanStatus === "Contract_Completed" ||
          loan.fullLoanStatus === "Contract_Denied" ||
          loan.fullLoanStatus === "Stripe_Initiated" ||
          loan.fullLoanStatus === "Stripe_Completed"
        ) {
          return (
            <LoanApprovalCard
              key={loan.loanId}
              status={loan.fullLoanStatus}
              created={loan.created}
              artistName={loan.artistName}
              requestedAmount={loan.requestedAmount}
              term={loan.term}
              id={loan.loanId}
            />
          );
        } else {
          return (
            <LoanCard
              key={loan.loanId}
              status={loan.fullLoanStatus}
              isPaidOff={loan.isPaidOff}
              created={loan.created}
              artistName={loan.artistName}
              requestedAmount={loan.requestedAmount}
              term={loan.term}
              id={loan.loanId}
              approvedAmount={loan.approvedAmount}
              approvedOn={loan.dateApproved}
              usedAmount={loan.amountUsed}
              updatedOn={loan.dateLastUsed}
              missedPaymentDate={loan.failedPayment}
              subLoanRequestedAmount={!loan.subLoan ? 0 : loan.subLoan.amount}
              subLoanRequestedDate={
                !loan.subLoan ? "0" : loan.subLoan.dateAmountRequested
              }
              subLoanFee={!loan.subLoan ? 0 : loan.subLoan.fee}
              subLoanTotal={!loan.subLoan ? 0 : loan.subLoan.total}
              subLoanMonthlyPayment={
                !loan.subLoan ? 0 : loan.subLoan.monthlyPayment
              }
              subLoanId={!loan.subLoan ? 0 : loan.subLoan.subLoanId}
              subLoanNextChargeDate={
                !loan.subLoan ? 0 : loan.subLoan.datePaymentCharges
              }
              isHistory={false}
              sentToCollections={loan.sentToCollections}
            />
          );
        }
      });
    }
  };

  const renderTitle = () => {
    if (toggleCalculator) {
      return (
        <div className="secondary_screen_title_container">
          <img
            className="navigation_arrow"
            onClick={() => setToggleCalculator(!toggleCalculator)}
            src={arrowLeftIcon}
            alt="left arrow icon"
          ></img>
          <h2 className="secondary_screen_title">
            {t("CUSTOMERS.APPROVAL_CALC")}
          </h2>
        </div>
      );
    } else if (individualLoanViewMoreState && targetLoan) {
      return (
        <div className="secondary_screen_title_container">
          <div className="individual_artist_title_content">
            <img
              className="navigation_arrow"
              src={arrowLeftIcon}
              alt="left arrow icon"
              onClick={() => dispatch(toggleIndividualLoanViewMoreState())}
            ></img>
            <div className="individual_client_title_details">
              <h2 className="individual_artist_title">
                {targetLoan.artistName}
              </h2>
              <div className="individual_client_details">
                <p>{targetLoan.artistAddress}&nbsp;</p>
                <p>
                  {targetLoan.artistEmail}
                  <span className="grey_dot"></span>
                  {targetLoan.artistPhone}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return t("CUSTOMERS.HOME");
    }
  };

  return (
    <div>
      <CustomerPageWrapper title={renderTitle()} image={defaultBanner}>
        <div
          className={
            toggleCalculator || individualLoanViewMoreState
              ? "hidden"
              : "approval_calc_button"
          }
        >
          <LgGreenButton
            text={t("CUSTOMERS.APPROVAL_CALC")}
            onButtonClick={() => setToggleCalculator(!toggleCalculator)}
          />
        </div>
        <div className="customer_content_container">
          {toggleCalculator ? (
            <div className="approval_calc_main_container">
              <ApprovalCalc
                buttonTitle={t("FORM_FIELDS.CALCULATE")}
                onCalcSubmit={handleCalcSubmit}
                discountCodeHidden={true}
              />
            </div>
          ) : (
            <div>
              {individualLoanViewMoreState ? (
                <LoanDetails />
              ) : (
                dashboardContent()
              )}
            </div>
          )}
        </div>
        <ErrorBoundary>
          {inveriteModalState ? <InveriteModal /> : ""}
          <ApproveLoanModal />
          <DeclineLoanModal />
          {toggleFail ? (
            <FailModal title={"ERRORS.ERROR"} message={errorMessage} />
          ) : null}
        </ErrorBoundary>
      </CustomerPageWrapper>
    </div>
  );
};

export default UserDashboard;
