import React from "react";
import { useTranslation } from "react-i18next";

import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import rightIcon from "../../../img/icon/icon_right_white.svg";
import { getDateWithTime } from "../../../utils/helpers";

import "./ClientCard.css";
import { useSelector } from "react-redux";

const ClientCard = ({
  loanId,
  name,
  email,
  phone,
  loanStatus,
  approvedAmount,
  approvedOn,
  usedAmount,
  updatedOn,
  onRedeemAmount,
  onCompleteTattoo,
  handleViewMore,
}) => {
  const { t } = useTranslation();
  const status = useSelector((state) => state.artist.artistInfo.status);

  const renderButtons = () => {
    if (loanStatus === "Completed") {
      return (
        <div className="client_card_buttons">
          <button className="lg_button_inactive" disabled>
            {t("CUSTOMERS.COMPLETED")}
          </button>
          <button
            className="button_view_more"
            value={loanId}
            onClick={(e) => handleViewMore(e)}
          >
            {t("CUSTOMERS.VIEW_MORE")} <img src={rightIcon} alt="right Icon" />
          </button>
        </div>
      );
    } else if (loanStatus === "SubLoan_Waiting_Approval") {
      return (
        <div className="client_card_buttons">
          <button className="lg_button_inactive" disabled>
            {t("CUSTOMERS.AWAITING_APPROVAL")}
          </button>
        </div>
      );
    } else {
      return (
        <div className="client_card_buttons">
          <LgGreenButton
            disabled={status === "Blocked" ? true : false}
            text={t("ARTISTS.REDEEM_AMOUNT")}
            value={loanId}
            onButtonClick={(e) => onRedeemAmount(e)}
          />
          <button
            disabled={status === "Blocked" ? true : false}
            className="complete_tattoo_button"
            value={loanId}
            onClick={(e) => onCompleteTattoo(e)}
          >
            {t("ARTISTS.COMPLETE_TATTOO")}
          </button>
          <button
            className="button_view_more"
            value={loanId}
            onClick={(e) => handleViewMore(e)}
          >
            {t("CUSTOMERS.VIEW_MORE")} <img src={rightIcon} alt="right Icon" />
          </button>
        </div>
      );
    }
  };

  const renderStatus = () => {
    if (loanStatus === "Completed") {
      return "ARTISTS.APPROVED";
    } else if (loanStatus === "SubLoan_Waiting_Approval") {
      return "ARTISTS.AWAITING_APPROVAL";
    } else {
      return "ARTISTS.APPROVED";
    }
  };

  return (
    <div className="client_card_container">
      <div className="client_card_content_container">
        <div className="client_card_details_container">
          <div className="client_card_header">
            <h2 className="client_card_title">{name}</h2>
            <div className="client_contact_details">
              <p>{email}</p>
              <span className="dot"></span>
              <p>{phone}</p>
            </div>
          </div>
          <div className="client_card_status">{t(renderStatus())}</div>
          <div className="client_card_details_text_container">
            <div className="client_card_details">
              <p className="client_card_details_title">
                {t("CUSTOMERS.APPROVED_AMOUNT")}
              </p>
              <p className="client_card_details_values">
                ${Number(approvedAmount).toFixed(2)}
              </p>
              <p className="client_card_details_title">
                {t("CUSTOMERS.APPROVED_ON")} {getDateWithTime(approvedOn)}
              </p>
            </div>
            <div className="client_card_details">
              <p className="client_card_details_title">
                {t("ARTISTS.AMOUNT_USED")}
              </p>
              <p className="client_card_details_values">
                ${Number(usedAmount).toFixed(2)}
              </p>
              <p className="client_card_details_title">
                {!updatedOn ? null : t("CUSTOMERS.UPDATED_ON")}
                {!updatedOn ? null : getDateWithTime(updatedOn)}
              </p>
            </div>
          </div>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default ClientCard;
