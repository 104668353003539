import React from "react";
import { useTranslation } from "react-i18next";
import "./ArtistCard.css";

const ArtistCard = ({
  id,
  bannerUrl,
  artistName,
  studioName,
  address,
  website,
  instagram,
  onButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="artist_card_container">
      <div className="artist_card_banner_img">
        <img src={bannerUrl} alt="" />
      </div>
      <div className="artist_card_content">
        <div className="artist_card_text">
          <h3 className="artist_card_title">{artistName}</h3>
          <p className="artist_card_secondary_info">{studioName}</p>
          <p className="artist_card_secondary_info">{address}</p>
          <p className="artist_card_social">
            {website} {instagram}
          </p>
        </div>
        <button className="med_green_button"  value={id} onClick={(e)=>onButtonClick(e)}>
          {t("CUSTOMERS.MORE_INFO")}
        </button>
      </div>
    </div>
  );
};

export default ArtistCard;
