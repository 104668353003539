import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import ModalContainer from "../ModalContainer/ModalContainer";
import ApprovalCalc from "../../Customers/ApprovalCalc/ApprovalCalc";

import {
  toggleApplyForLoanModalState,
  getTargetArtist,
  createLoanApplicationRequest,
  getLoans,
} from "../../../store/customerSlice";

import "./ApplyLoanModal.css";

const ApplyLoanModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const individualArtist = useSelector(getTargetArtist);

  const handleSubmit = (e, amount, length, limit, minLimit) => {
    if (e) e.preventDefault();
    if (amount <= limit && amount >= minLimit) {
      const data = {
        artistId: individualArtist.artistId,
        amount: parseInt(amount),
        term: parseInt(length),
      };
      dispatch(createLoanApplicationRequest(data));
      dispatch(getLoans());
      navigate("/customer");
    }
  };
  return (
    <div>
      <ModalContainer
        title={t("CUSTOMERS.APPLY")}
        onButtonClose={() => dispatch(toggleApplyForLoanModalState())}
      >
        <div className="apply_for_loan_container">
          {!individualArtist.bannerUrl ? (
            <div className="profile_default_banner"></div>
          ) : (
            <img
              className="profile_banner_image"
              src={individualArtist.bannerUrl}
              alt="banner"
            />
          )}
          <h2>{individualArtist.artistName}</h2>
          <p>{individualArtist.address}</p>
          <div className="apply_for_loan_contacts">
            <p>{individualArtist.profileEmail}</p> <span className="dot"></span>
            <p> {individualArtist.phone}</p>
          </div>
        </div>
        <ApprovalCalc
          buttonTitle={t("CUSTOMERS.APPLY_FOR_APPROVAL")}
          onCalcSubmit={(e, amount, length, limit, minLimit) =>
            handleSubmit(e, amount, length, limit, minLimit)
          }
        />
      </ModalContainer>
    </div>
  );
};

export default ApplyLoanModal;
