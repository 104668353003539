import axios from "./api";

export function login(data) {
  return axios({
    url: `/User/SignIn`,
    method: "post",
    data,
  });
}

export function verifyEmail(data) {
  return axios({
    url: `/User/verifyEmail`,
    method: "post",
    data,
  });
}

export function resendConfirmationCode(data) {
  return axios({
    url: `/User/ResendEmailConfirmationCode`,
    method: "post",
    data,
  });
}

export function changePassword(data) {
  return axios({
    url: `/User/ChangePassword`,
    method: "post",
    data,
  });
}

export function forgotPassword(data) {
  return axios({
    url: `/User/ForgotPassword`,
    method: "post",
    data,
  });
}

export function changePasswordCodeRequired(data) {
  return axios({
    url: `/User/ChangePasswordCodeRequired`,
    method: "post",
    data,
  });
}

export function updateAcceptance() {
  return axios({
    url: `/User/content/UpToDate`,
    method: "post",
  });
}


export function sendContactUs(data) {
  return axios({
    url: `/User/contactus`,
    method: "post",
    data
  });
}

export function getNotifications(data) {
  return axios({
    url: `/User/Notifications/Get`,
    method: "post",
    data
  });
}

export function readNotifications(data) {
  return axios({
    url: `/User/Notifications/Read`,
    method: "post",
    data
  });
}

