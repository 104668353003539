import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import NumberFormat from "react-number-format";

import { getLoanFee } from "../../../api/customer";

import "./ApprovalCalc.css";

const calculateTotal = async (loanAmount, fees, setTotal) => {
  let num = parseInt(loanAmount) + parseInt(fees);
  num = num.toFixed(2);
  if (isNaN(num)) {
    num = 0;
    setTotal(num.toFixed(2));
  } else {
    setTotal(num);
  }
};

const ApprovalCalc = ({ buttonTitle, onCalcSubmit }) => {
  const { t } = useTranslation();
  const [termLength, setTermLength] = useState("3");
  const [loanAmount, setLoanAmount] = useState("");
  const [fees, setFees] = useState([]);
  const [limit, setLimit] = useState(500);
  const [selectedFees, setSelectedFees] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [limitError, setLimitError] = useState("");
  const [total, setTotal] = useState(0);
  const [minLimit, setMinLimit] = useState(100);
  const [errorLimit, setErrorLimit] = useState(null);
  const [length, setLength] = useState(3);

  useEffect(() => {
    const getLoanDetails = async () => {
      let res = await getLoanFee();
      setFees([...res.loanAdminFees]);
      setLimit(res.loanMaxSize);
      setMinLimit(res.loanMinSize);
    };
    getLoanDetails();
  }, [limitError]);

  useEffect(() => {
    let length;
    if (fees.length === 0) {
      setSelectedFees(0);
    } else {
      if (termLength === "3") {
        length = 3;
        let index = fees.findIndex((fee) => {
          return fee.term === 3;
        });
        setSelectedFees(fees[index].fee);
      }
      if (termLength === "6") {
        length = 6;
        let index = fees.findIndex((fee) => {
          return fee.term === 6;
        });
        setSelectedFees(fees[index].fee);
      } else if (termLength === "9") {
        length = 9;
        let index = fees.findIndex((fee) => {
          return fee.term === 9;
        });
        setSelectedFees(fees[index].fee);
      } else if (termLength === "12") {
        length = 12;
        let index = fees.findIndex((fee) => {
          return fee.term === 12;
        });
        setSelectedFees(fees[index].fee);
      }
    }
    if (!isNaN(total)) {
      calculateTotal(loanAmount, selectedFees, setTotal);
      setMonthlyPayment((total / length).toFixed(2));
    }
  }, [loanAmount, fees, termLength, selectedFees, total]);

  useEffect(() => {
    if (isNaN(monthlyPayment)) {
      setMonthlyPayment(0);
    }
  }, []);

  const handleChange = (value, name) => {
    if (name === "termLength") {
      setTermLength(value);
      calculateTotal(loanAmount, selectedFees, setTotal);
    }
  };

  const handleLoanAmount = (value) => {
    if (value > limit) {
      setLimitError(t("ERRORS.LOAN_LIMIT"));
      setErrorLimit(limit);
      setLoanAmount(value);
      calculateTotal(value, selectedFees, setTotal);
    } else if (value < minLimit && value) {
      setLimitError(t("ERRORS.LOAN_MIN_LIMIT"));
      setErrorLimit(minLimit);
      setLoanAmount(value);
      calculateTotal(value, selectedFees, setTotal);
    } else {
      setLimitError("");
      setLoanAmount(value);
      calculateTotal(value, selectedFees, setTotal);
    }
  };

  const renderLimitError = (
    <p>
      {limitError}
      {errorLimit}
    </p>
  );

  return (
    <div className="calculator_container">
      <div className="calculator_inputs">
        <select
          className="term_length_select"
          name="termLength"
          onChange={(event) =>
            handleChange(event.target.value, event.target.name)
          }
          defaultValue={"3"}
        >
          <option value="3">{t("FORM_FIELDS.3_MONTHS")}</option>
          <option value="6">{t("FORM_FIELDS.6_MONTHS")}</option>
          <option disabled value="9">
            {t("FORM_FIELDS.9_MONTHS")}
          </option>
          <option disabled value="12">
            {t("FORM_FIELDS.12_MONTHS")}
          </option>
        </select>
        <NumberFormat
          id="calculator_input"
          name="loanAmount"
          value={loanAmount}
          placeholder={t("CUSTOMERS.LOAN_AMOUNT")}
          decimalSeparator="."
          displayType="input"
          type="text"
          thousandSeparator={true}
          prefix={"$"}
          suffix={"         CAD"}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            // formattedValue = $2,223
            // value ie, 2223
            handleLoanAmount(value);
          }}
        />
      </div>
      <div className="calc_error">{limitError ? renderLimitError : null}</div>

      <div className="calculator_results_container">
        <div className="calculated_values">
          <p className="calculated_values_name">{t("CUSTOMERS.DURATION")}</p>
          <p className="calculated_values_value">
            {termLength} {t("FORM_FIELDS.MONTHS")}
          </p>
        </div>
        <div className="calculated_values">
          <p className="calculated_values_name">{t("CUSTOMERS.FEES")}</p>
          <p className="calculated_values_value">$ {selectedFees}</p>
        </div>
        <div className="calculated_values">
          <p className="calculated_values_name">
            {t("CUSTOMERS.MONTHLY_PAYMENT")}
          </p>
          <p className="calculated_values_value">$ {monthlyPayment}</p>
        </div>
      </div>
      <div className="calculated_total">
        <p>{t("CUSTOMERS.TOTAL")}</p>
        <p className="total_message">
          $
          {t("CUSTOMERS.TOTAL_MESSAGE", {
            total: total,
            termLength: termLength,
          })}
        </p>
      </div>
      <LgGreenButton
        text={buttonTitle}
        onButtonClick={(e) => {
          onCalcSubmit(e, loanAmount, termLength, limit, minLimit);
        }}
      />
    </div>
  );
};

export default ApprovalCalc;
