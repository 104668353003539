export default function validate(
  email = null,
  password = null,
  confirmPassword = null,
  securityCode = null,
  firstName = null
) {
  let errors = {};
  const regexEmail = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  if (!email) {
    errors.email = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(email)) {
    errors.email = "ERRORS.VALID_EMAIL";
  }
  return errors;
}
