import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../../../img/logo/logo_white.svg";

import "./CustomerFooter.css";

const CustomerFooter = () => {
  const { t } = useTranslation();

  return (
    <nav className="customer_footer">
      <div className="customer_footer_content">
        <div className="customer_footer_links">
          <NavLink className="customer_footer_link" to="/customer">
            {t("NAVBAR.HOME")}
          </NavLink>
          <NavLink className="customer_footer_link" to="/customerArtists">
            {t("NAVBAR.ARTISTS")}
          </NavLink>
          <NavLink className="customer_footer_link" to="/customerHistory">
            {t("NAVBAR.HISTORY")}
          </NavLink>
          <NavLink className="customer_footer_link" to="/customerProfile">
            {t("NAVBAR.ACCOUNT")}
          </NavLink>
        </div>
        <div className="customer_footer_links">
          <NavLink className="customer_footer_link" to="/customerProfile/?section=about">
            {t("NAVBAR.ABOUT")}
          </NavLink>{" "}
          <NavLink className="customer_footer_link" to="/customerProfile/?section=feedback">
            {t("NAVBAR.FEEDBACK")}
          </NavLink>
        </div>
      </div>
      <div className="customer_footer_logo">
        <img src={logo} alt="Ink Me Financial Logo" />
      </div>
    </nav>
  );
};

export default CustomerFooter;
