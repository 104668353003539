import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getArtist,
  editDescription,
  editProfile,
  editProfileImages,
  uploadImage,
  updateBannerImage,
  registerArtist,
  getCurrentClients,
  editPaymentEmail,
  getClientHistory,
} from "../api/artist";
import { htmlDecode } from "../utils/helpers";

const initialState = {
  loading: false,
  artistInfo: {
    artistName: "",
    studioName: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    phone: "",
    id: "",
    profileEmail: "",
    loginEmail: "",
    status: "",
    website: "",
    instagram: "",
    tags: "",
    bannerUrl: "",
    description: "",
    portfolioImages: [],
  },
  errorMessage: "",
  editArtistInfoModalState: false,
  editArtistDescriptionModalState: false,
  editArtistBankingModalState: false,
  artistGeneralError: false,
  artistGeneralErrorMessage: "",
  redeemAmountModalState: false,
  completeTattooModalState: false,
  clientInfo: { artistLoanVMs: [], totalLoanCount: 0 },
  targetLoanId: "",
  payout: { payout: 0, payoutTimingVerbage: "" },
};

export const getArtistData = createAsyncThunk(
  "artist/myprofile",
  async (thunkAPI) => {
    try {
      const res = await getArtist();
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const signUpArtist = createAsyncThunk(
  "/Artist",
  async (data, thunkAPI) => {
    try {
      const res = await registerArtist(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "INVALID_ADDRESS") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_ADDRESS");
        }
        if (error.response.data.message === "DUPLICATE_EMAIL") {
          return thunkAPI.rejectWithValue("ERRORS.DUPLICATE_EMAIL");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const editArtistDescription = createAsyncThunk(
  "Artist/description",
  async (data, thunkAPI) => {
    try {
      const res = await editDescription(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const editArtistProfile = createAsyncThunk(
  "Artist/profile",
  async (data, thunkAPI) => {
    try {
      const res = await editProfile(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "INVALID_ADDRESS") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_ADDRESS");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const updatePortfolioImages = createAsyncThunk(
  "Artist/profileImages",
  async (data, thunkAPI) => {
    try {
      const res = await editProfileImages(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "NO_IMAGE_IDS_ATTACHED") {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const addPortfolioImage = createAsyncThunk(
  "/Artist/profileImages",
  async (info, thunkAPI) => {
    try {
      const res = await uploadImage(info.data);
      let uploadId = [];
      info.portfolioImages.forEach((image) => {
        uploadId.push(image.id);
      });
      uploadId.push(res[0].id);
      const imageIds = { uploadIds: uploadId };
      const res2 = await editProfileImages(imageIds);
      return res2;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.message === "MAX_4_IMAGES_ALLOWED") {
          return thunkAPI.rejectWithValue("ERRORS.IMAGE_LIMIT");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const bannerUpload = createAsyncThunk(
  "/Artist/banner/id",
  async (data, thunkAPI) => {
    try {
      const res = await uploadImage(data);
      const url = await updateBannerImage(res[0].id);
      return url;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const getClientData = createAsyncThunk(
  "/getClientData",
  async (data, thunkAPI) => {
    try {
      const res = await getCurrentClients(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const editBankingInfo = createAsyncThunk(
  "/editBankingInfo",
  async (data, thunkAPI) => {
    try {
      const res = await editPaymentEmail(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const getArtistHistory = createAsyncThunk(
  "/getArtistHistory",
  async (data, thunkAPI) => {
    try {
      const res = await getClientHistory(data);
      return res;
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const artistSlice = createSlice({
  name: "artist",
  initialState: initialState,
  reducers: {
    toggleEditArtistInfoModalState: (state) => {
      state.editArtistInfoModalState = !state.editArtistInfoModalState;
    },
    toggleEditArtistDescriptionModalState: (state) => {
      state.editArtistDescriptionModalState =
        !state.editArtistDescriptionModalState;
    },
    setErrorMessage: (state, action) => {
      state.artistGeneralError = true;
      state.artistGeneralErrorMessage = action.payload;
    },
    resetArtistGeneralError: (state) => {
      state.artistGeneralErrorMessage = "";
      state.artistGeneralError = false;
    },
    toggleRedeemAmountModalState: (state) => {
      state.redeemAmountModalState = !state.redeemAmountModalState;
    },
    toggleCompleteTattooModalState: (state) => {
      state.completeTattooModalState = !state.completeTattooModalState;
    },
    setTargetLoanId: (state, action) => {
      state.targetLoanId = action.payload;
    },
    toggleEditArtistBankingModalState: (state) => {
      state.editArtistBankingModalState = !state.editArtistBankingModalState;
    },
    resetArtistInfo: (state) => {
      state.artistInfo = {};
    },
  },
  extraReducers: {
    [getArtistData.pending]: (state) => {
      state.loading = true;
      state.errorMessage = "";
    },
    [getArtistData.rejected]: (state, action) => {
      state.loading = false;
    },
    [getArtistData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo = payload;
    },
    [editArtistDescription.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [editArtistDescription.rejected]: (state, action) => {
      state.loading = false;
    },
    [editArtistDescription.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo.description = payload.description;
      state.editArtistDescriptionModalState = false;
    },
    [editArtistProfile.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [editArtistProfile.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [editArtistProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo.artistName = payload.artistName;
      state.artistInfo.studioName = payload.studioName;
      state.artistInfo.firstName = payload.firstName;
      state.artistInfo.lastName = payload.lastName;
      state.artistInfo.profileEmail = payload.profileEmail;
      state.artistInfo.phone = payload.phone;
      state.artistInfo.street = payload.street;
      state.artistInfo.city = payload.city;
      state.artistInfo.province = payload.province;
      state.artistInfo.postalCode = payload.postalCode;
      state.artistInfo.website = payload.website;
      state.artistInfo.instagram = payload.instagram;
      state.artistInfo.tags = payload.tags;
      state.editArtistInfoModalState = false;
    },
    [updatePortfolioImages.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [updatePortfolioImages.rejected]: (state, action) => {
      state.loading = false;
      state.artistGeneralError = true;
      if (action.payload === "ERRORS.IMAGE_LIMIT") {
        state.artistGeneralErrorMessage = "ERRORS.IMAGE_LIMIT";
      }
    },
    [updatePortfolioImages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo.portfolioImages = payload;
    },
    [addPortfolioImage.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [addPortfolioImage.rejected]: (state, action) => {
      state.loading = false;
    },
    [addPortfolioImage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo.portfolioImages = payload;
    },
    [bannerUpload.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [bannerUpload.rejected]: (state, action) => {
      state.loading = false;
    },
    [bannerUpload.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo.bannerUrl = payload.bannerUrl;
    },
    [signUpArtist.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [signUpArtist.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [signUpArtist.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo = payload;
    },
    [getClientData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getClientData.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getClientData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.clientInfo = payload.loanApplications;
      state.payout.payout = payload.artistPayout.payout;
      state.payout.payoutTimingVerbage = htmlDecode(
        payload.artistPayout.payoutTimingVerbage
      );
    },
    [editBankingInfo.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [editBankingInfo.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [editBankingInfo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artistInfo.paymentEmail = payload;
    },
    [getArtistHistory.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getArtistHistory.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getArtistHistory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.clientInfo = payload.loanApplications;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleEditArtistInfoModalState,
  toggleEditArtistDescriptionModalState,
  setErrorMessage,
  resetArtistGeneralError,
  toggleRedeemAmountModalState,
  setTargetLoanId,
  toggleCompleteTattooModalState,
  toggleEditArtistBankingModalState,
  resetArtistInfo,
} = artistSlice.actions;
export const getArtistInfo = (state) => state.artist;
export const getArtistStatus = (state) => state.artist.artistInfo.status;
export const getId = (state) => state.artist.artistInfo.id;
export const getArtistError = (state) => state.artist.errorMessage;
export const getArtistLoading = (state) => state.artist.loading;
export const getArtistInfoModalState = (state) =>
  state.artist.editArtistInfoModalState;
export const getArtistDescriptionModalState = (state) =>
  state.artist.editArtistDescriptionModalState;
export const getArtistGeneralError = (state) => state.artist.artistGeneralError;
export const getArtistGeneralErrorMessage = (state) =>
  state.artist.artistGeneralErrorMessage;
export const getRedeemAmountModalState = (state) =>
  state.artist.redeemAmountModalState;
export const getCompleteTattooModalState = (state) =>
  state.artist.completeTattooModalState;
export const getClientCount = (state) => state.artist.clientInfo.totalLoanCount;
export const getClientInfo = (state) => state.artist.clientInfo.artistLoanVMs;
export const getTargetLoanId = (state) => state.artist.targetLoanId;
export const getPayoutInfo = (state) => state.artist.payout;
export default artistSlice.reducer;
