import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import xIcon from "../../../img/icon/icon_x_white.svg";

import { getGeneralError, toggleGeneralError } from "../../../store/authSlice";
import {
  getArtistGeneralError,
  resetArtistGeneralError,
  getArtistGeneralErrorMessage,
} from "../../../store/artistSlice";
import {
  getCustomerGeneralError,
  getCustomerGeneralErrorMessage,
  resetCustomerGeneralError,
} from "../../../store/customerSlice";

import "./ErrorModal.css";

const ErrorModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const generalError = useSelector(getGeneralError);
  const artistGeneralError = useSelector(getArtistGeneralError);
  const artistGeneralErrorMessage = useSelector(getArtistGeneralErrorMessage);
  const customerGeneralError = useSelector(getCustomerGeneralError);
  const customerGeneralErrorMessage = useSelector(
    getCustomerGeneralErrorMessage
  );

  useEffect(() => {
    const renderError = () => {
      if (generalError) {
        setError("ERRORS.GENERAL_ERROR");
      }
      if (artistGeneralError) {
        setError(artistGeneralErrorMessage);
      }
      if (customerGeneralError) {
        setError(customerGeneralErrorMessage);
      }
    };
    renderError();
  }, [
    generalError,
    artistGeneralErrorMessage,
    artistGeneralError,
    customerGeneralError,
    customerGeneralErrorMessage,
  ]);

  const onModalClose = () => {
    setError("");
    if (generalError) {
      dispatch(toggleGeneralError());
    }
    if (artistGeneralError) {
      dispatch(resetArtistGeneralError());
    }
    if (customerGeneralError) {
      dispatch(resetCustomerGeneralError());
    }
  };

  return (
    <div
      className={
        generalError || artistGeneralError || customerGeneralError
          ? "show_general_error"
          : "hidden"
      }
    >
      <div className="error_modal_background_container">
        <div className="error_modal_container">
          <div className="modal_title">
            <h3>{t("ERRORS.ERROR")}</h3>
            <img src={xIcon} alt="x icon" onClick={onModalClose} />
          </div>
          <div className="modal_content">
            <p>{error ? t(error) : null}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
