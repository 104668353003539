import React from "react";
import { useTranslation } from "react-i18next";

import successIcon from "../../../img/icon/icon_success.svg";

import "./SuccessModal.css";

const SuccessModal = ({ title, message = null }) => {
  const { t } = useTranslation();
  return (
    <div className="success_modal_background_container">
      <div className="success_modal_container">
        <div className="success_modal_image">
          <img src={successIcon} alt="Check Icon" />
        </div>
        <div className="success_modal_title">
          <h3>{t(title)}</h3>
          <p>{t(message)}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
