import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Inject,
  Annotation,
  FormDesigner,
  FormFields,
} from "@syncfusion/ej2-react-pdfviewer";
import MakePaymentsModal from "../../Modals/MakePaymentsModal/MakePaymentsModal";
import MissedPaymentsModal from "../../Modals/MissedPaymentsModal/MissedPaymentsModal";
import PaymentDetailsModal from "../../Modals/PaymentDetailsModal/PaymentDetailsModal";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import ModalContainer from "../../Modals/ModalContainer/ModalContainer";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import iconTattooGun from "../../../img/icon/icon_background_tattoo_gun.svg";

import {
  getloanDetailsHistory,
  getLoanDetailsHistoryData,
  getLoanInfo,
  getLoanDetailsUpcomingPaymentsData,
  getloanDetailsUpcomingPayment,
  getLoanMakePaymentsData,
  getMakePaymentDetails,
  getMissedPaymentDetails,
} from "../../../store/customerSlice";

import {
  getPaymentDetails,
  getContractForViewing,
} from "../../../api/customer";

import { getDateWithTime } from "../../../utils/helpers";

import "react-circular-progressbar/dist/styles.css";
import "./LoanDetails.css";

const LoanDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const targetLoan = useSelector(getLoanInfo);
  const loanHistory = useSelector(getLoanDetailsHistoryData);
  const loanUpcomingPayments = useSelector(getLoanDetailsUpcomingPaymentsData);
  const [upcomingPaymentState, setUpcomingPaymentState] = useState(false);
  const [error, setError] = useState("");
  const [togglePaymentDetails, setTogglePaymentDetails] = useState(false);
  const [toggleMakePayments, setToggleMakePayments] = useState(false);
  const [toggleMissedPayments, setToggleMissedPayments] = useState(false);
  const [toggleViewContract, setToggleViewContract] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();
  const makePayments = useSelector(getLoanMakePaymentsData);
  const viewerRef = useRef(null);

  useEffect(() => {
    const getUpcomingPaymentInfo = async () => {
      const data = {
        id: targetLoan.loanId,
      };
      dispatch(getloanDetailsUpcomingPayment(data));
    };
    if (upcomingPaymentState) {
      getUpcomingPaymentInfo();
    }
  }, [dispatch, targetLoan.loanId, upcomingPaymentState]);

  useEffect(() => {
    const getHistoryInfo = async () => {
      const data = {
        id: targetLoan.loanId,
      };
      dispatch(getloanDetailsHistory(data));
    };
    getHistoryInfo();
  }, [dispatch, targetLoan.loanId]);

  const handleViewContract = () => {
    setToggleViewContract(true);
  };

  const seePaymentDetails = async (e) => {
    e.preventDefault();
    const data = {
      loanId: targetLoan.loanId,
      date: e.target.value,
    };
    try {
      const res = await getPaymentDetails(data);
      setPaymentDetails(res);
      setTogglePaymentDetails(true);
    } catch (error) {
      console.log(error.response);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  const handleMakePayment = () => {
    const data = {
      id: targetLoan.loanId,
    };
    dispatch(getMakePaymentDetails(data));
    setToggleMakePayments(true);
  };

  const handleMissedPayment = () => {
    const data = {
      id: targetLoan.loanId,
    };
    dispatch(getMissedPaymentDetails(data));
    setToggleMissedPayments(true);
  };

  const viewerCreatedHandler = async () => {
    const data = {
      id: targetLoan.loanId,
    };
    try {
      const res = await getContractForViewing(data);
      viewerRef.current.load(`data:application/pdf;base64, ${res}`, null);
      viewerRef.current.fileName = "contract.pdf";
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentDateMonth = (isoDate) => {
    const monthNames = [
      t("CUSTOMERS.JANUARY"),
      t("CUSTOMERS.FEBRUARY"),
      t("CUSTOMERS.MARCH"),
      t("CUSTOMERS.APRIL"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUNE"),
      t("CUSTOMERS.JULY"),
      t("CUSTOMERS.AUGUST"),
      t("CUSTOMERS.SEPTEMBER"),
      t("CUSTOMERS.OCTOBER"),
      t("CUSTOMERS.NOVEMBER"),
      t("CUSTOMERS.DECEMBER"),
    ];
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];

    return `${month} ${dt}, ${year}`;
  };

  const getPaymentDateMonthNoTime = (isoDate) => {
    const monthNames = [
      t("CUSTOMERS.JAN"),
      t("CUSTOMERS.FEB"),
      t("CUSTOMERS.MAR"),
      t("CUSTOMERS.APR"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUN"),
      t("CUSTOMERS.JUL"),
      t("CUSTOMERS.AUG"),
      t("CUSTOMERS.SEP"),
      t("CUSTOMERS.OCT"),
      t("CUSTOMERS.NOV"),
      t("CUSTOMERS.DEC"),
    ];
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];

    return `${month} ${dt}, ${year}`;
  };

  const getPaymentDateMonthWithTime = (isoDate) => {
    const monthNames = [
      t("CUSTOMERS.JAN"),
      t("CUSTOMERS.FEB"),
      t("CUSTOMERS.MAR"),
      t("CUSTOMERS.APR"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUN"),
      t("CUSTOMERS.JUL"),
      t("CUSTOMERS.AUG"),
      t("CUSTOMERS.SEP"),
      t("CUSTOMERS.OCT"),
      t("CUSTOMERS.NOV"),
      t("CUSTOMERS.DEC"),
    ];
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];
    let hour = date.getHours();
    let minute = date.getMinutes();

    if (month < 10) {
      month = "0" + month;
    }

    let amPm = "AM";
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (hour >= 12) {
      amPm = "PM";
    }
    hour = hour % 12;
    hour = hour ? hour : 12;

    if (minute.toString().length === 1) {
      minute = `0${minute}`;
    }

    return `${month} ${dt}, ${year} ${hour}:${minute} ${amPm}`;
  };

  const renderStatus = () => {
    if (
      targetLoan.fullLoanStatus === "Loan_Awaiting_Approval" ||
      targetLoan.fullLoanStatus === "Risk_Score_Waiting"
    ) {
      return (
        <h3 className="loan_card_status">
          {t("CUSTOMERS.STATUS_AWAITING_APPROVAL")}
        </h3>
      );
    } else if (
      targetLoan.fullLoanStatus === "Loan_Approved" ||
      targetLoan.fullLoanStatus === "Active" ||
      targetLoan.fullLoanStatus === "SubLoan_Waiting_Approval" ||
      targetLoan.fullLoanStatus === "Completed"
    ) {
      return (
        <h3 className="loan_card_status">{t("CUSTOMERS.STATUS_APPROVED")}</h3>
      );
    } else if (
      targetLoan.fullLoanStatus === "In_Collections" &&
      !targetLoan.sentToCollections
    ) {
      return (
        <h3 className="loan_card_in_collections">
          {t("CUSTOMERS.STATUS_ADMIN_HOLD")}
        </h3>
      );
    } else if (
      targetLoan.fullLoanStatus === "In_Collections" &&
      targetLoan.sentToCollections
    ) {
      return (
        <h3 className="loan_card_in_collections">
          {t("CUSTOMERS.STATUS_IN_COLLECTIONS")}
        </h3>
      );
    }
  };

  const renderUpcomingPayments = () => {
    if (!loanHistory) {
      return (
        <div className="loan_details_history_no_content">
          <img src={iconTattooGun} alt="tattoo_gun_icon" />
          <p>{t("CUSTOMERS.NO_UPCOMING_PAYMENT")}</p>
        </div>
      );
    } else if (
      Object.keys(loanHistory).length === 0 ||
      !loanHistory.payments ||
      !loanUpcomingPayments ||
      Object.keys(loanUpcomingPayments).length === 0 ||
      !loanUpcomingPayments.payments
    ) {
      return (
        <div className="loan_details_history_no_content">
          <img src={iconTattooGun} alt="tattoo_gun_icon" />
          <p>{t("CUSTOMERS.NO_UPCOMING_PAYMENT")}</p>
        </div>
      );
    } else {
      return loanUpcomingPayments.payments.map((payment, index) => {
        return (
          <div key={index} className="loan_details_history_item">
            <div className="loan_details_history_main_details">
              <p className="loan_detail_payment_date">
                {getPaymentDateMonthNoTime(payment.lineDate)}
              </p>
              <p className="loan_detail_payment_description">
                {t("CUSTOMERS.PAYMENT_DUE")}
              </p>
              <p className="loan_detail_payment_amount">
                ${payment.lineAmount.toFixed(2)}
              </p>
            </div>
            <button
              onClick={seePaymentDetails}
              value={payment.realDate}
              className="payment_details_button"
            >
              {t("CUSTOMERS.PAYMENT_DETAILS")}
            </button>
          </div>
        );
      });
    }
  };

  const renderHistory = () => {
    if (
      !loanHistory ||
      Object.keys(loanHistory).length === 0 ||
      !loanHistory.payments
    ) {
      return (
        <div className="loan_details_history_no_content">
          <img src={iconTattooGun} alt="tattoo_gun_icon" />
          <p>{t("CUSTOMERS.NO_PAYMENT_YET")}</p>
        </div>
      );
    } else {
      return loanHistory.payments.map((payment, index) => {
        if (payment.isPayment && !payment.isLatePayment) {
          return (
            <div
              key={payment.lineDate + payment.realDate + payment.lineAmount}
              className="loan_details_history_item"
            >
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("CUSTOMERS.PAYMENT_MADE")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
              <button
                onClick={seePaymentDetails}
                value={payment.realDate}
                className="payment_details_button"
              >
                {t("CUSTOMERS.PAYMENT_DETAILS")}
              </button>
            </div>
          );
        } else if (payment.isPayment && payment.isLatePayment) {
          return (
            <div
              key={payment.lineDate + payment.realDate + payment.lineAmount}
              className="loan_details_history_item"
            >
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("CUSTOMERS.MISSED_PAYMENT_DATE")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
              <button
                onClick={seePaymentDetails}
                value={payment.realDate}
                className="payment_details_button"
              >
                {t("CUSTOMERS.PAYMENT_DETAILS")}
              </button>
            </div>
          );
        } else if (payment.wasSentToCollections) {
          return (
            <div
              key={payment.lineDate + payment.realDate + payment.lineAmount}
              className="loan_details_history_item"
            >
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("CUSTOMERS.COLLECTIONS_PAYMENT")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={payment.lineDate + payment.realDate + payment.lineAmount}
              className="loan_details_history_item"
            >
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("CUSTOMERS.BALANCE_USED")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
            </div>
          );
        }
      });
    }
  };

  const renderMissedPayment = () => {
    if (!loanHistory.hasMissedPayment || upcomingPaymentState) {
      return;
    } else {
      return (
        <div className="loan_details_missed_payment_item">
          <div className="loan_details_history_main_details">
            <p className="loan_detail_missed_payment">
              {getPaymentDateMonthWithTime(
                loanHistory.nextPayment === null ||
                  loanHistory.missedPaymentAmount === 0
                  ? loanHistory.payments[0].lineDate
                  : loanHistory.nextPayment
              )}
            </p>
            <p className="loan_detail_missed_payment">
              {loanHistory.missedPaymentAmount === 0
                ? t("CUSTOMERS.MISSED_PAYMENT_SHORT")
                : t("CUSTOMERS.MISSED_PAYMENT")}
            </p>
            <p className="loan_detail_missed_payment bold">
              +${loanHistory.missedPaymentAmount.toFixed(2)}
            </p>
          </div>
          {targetLoan.sentToCollections ? null : (
            <button
              className="med_green_button make_missed_payment"
              onClick={handleMissedPayment}
            >
              {t("CUSTOMERS.MAKE_MISSED_PAYMENT")}
            </button>
          )}
        </div>
      );
    }
  };

  const renderButtonorText = () => {
    if (!loanHistory || Object.keys(loanHistory).length === 0) {
      return;
    } else if (!loanHistory.payments) {
      return (
        <div className="loan_approve_message">
          <p>
            {t("CUSTOMERS.APPROVED_MESSAGE1")}
            {loanHistory.approvedAmount.toFixed(2)}
            <Trans i18nKey="CUSTOMERS.APPROVED_MESSAGE2">
              through Ink Me Financials. You have
              <strong>30 days</strong>
              to begin your tattoo
            </Trans>
          </p>
        </div>
      );
    } else if (
      (targetLoan.fullLoanStatus === "Completed" && targetLoan.isPaidOff) ||
      (targetLoan.fullLoanStatus === "In_Collections" &&
        targetLoan.sentToCollections)
    ) {
      return;
    } else {
      return (
        <LgGreenButton
          text={t("CUSTOMERS.MAKE_PAYMENT")}
          onButtonClick={handleMakePayment}
        />
      );
    }
  };

  return (
    <div className="individual_loan_details_container">
      <div className="individual_loan_details_left">
        <div className="individual_loan_cicle_container">
          <div className="loan_circle_size">
            <CircularProgressbarWithChildren
              strokeWidth={3}
              counterClockwise={true}
              value={targetLoan.amountUsed}
              maxValue={targetLoan.approvedAmount}
              styles={buildStyles({
                pathColor: "var(--green)",
                trailColor: "#7c7c7c",
              })}
            >
              <h3 className="circle_amount_used">
                ${targetLoan.amountUsed.toFixed(2)}
              </h3>
              <p className="individual_circle_requested_amount">
                {t("CUSTOMERS.CIRCLE_MESSAGE", {
                  total: targetLoan.approvedAmount.toFixed(2),
                })}
              </p>
            </CircularProgressbarWithChildren>
          </div>
        </div>
        <div className="payment_text_container">
          {!loanHistory ||
          Object.keys(loanHistory).length === 0 ||
          !loanHistory.payments ||
          (targetLoan.fullLoanStatus === "Completed" && targetLoan.isPaidOff) ||
          (targetLoan.fullLoanStatus === "In_Collections" &&
            targetLoan.sentToCollections) ? null : (
            <React.Fragment>
              <div className="individual_loan_card_details">
                <p className="client_details_title">
                  {t("CUSTOMERS.UPCOMING_PAYMENT")}
                </p>
                <p className="client_card_details_values nextPayment">
                  {loanHistory.nextPayment === null
                    ? t("CUSTOMERS.NO_UPCOMING_PAYMENT")
                    : getPaymentDateMonth(loanHistory.nextPayment)}
                </p>
                <p className="client_details_title">
                  {t("CUSTOMERS.UPCOMING_PAYMENT_MESSAGE")}
                </p>
              </div>
              <div className="individual_loan_card_details">
                <p className="client_details_title">
                  {t("CUSTOMERS.NEXT_PAYMENT_AMOUNT")}
                </p>
                <p className="client_card_details_values">
                  ${Number(loanHistory.nextPaymentAmount).toFixed(2)}
                </p>
                <p className="client_details_title">
                  {t("CUSTOMERS.NEXT_PAYMENT_MESSAGE")}
                </p>
              </div>
            </React.Fragment>
          )}
          <button className="view_contract_button" onClick={handleViewContract}>
            {t("CUSTOMERS.VIEW_CONTRACT")}
          </button>
        </div>
      </div>
      <div className="individual_loan_details_right">
        <div className="individual_loan_details_header">
          <div className="client_card_status">{renderStatus()}</div>
          <div className="loan_details_header_details">
            <div className="client_details_header_text">
              <div className="client_card_details">
                <p className="client_details_title">
                  {t("CUSTOMERS.APPROVED_AMOUNT")}
                </p>
                <p className="client_card_details_values">
                  ${Number(targetLoan.approvedAmount).toFixed(2)}
                </p>
                <p className="client_details_date">
                  {t("CUSTOMERS.APPROVED_ON")}{" "}
                  {getDateWithTime(targetLoan.dateApproved)}
                </p>
              </div>
              <div className="client_card_details">
                <p className="client_details_title">
                  {t("ARTISTS.AMOUNT_USED")}
                </p>
                <p className="client_card_details_values">
                  ${Number(targetLoan.amountUsed).toFixed(2)}
                </p>
                {targetLoan.dateLastUsed === null ? null : (
                  <p className="client_details_date">
                    {t("CUSTOMERS.UPDATED_ON")}
                    {getDateWithTime(targetLoan.dateLastUsed)}
                  </p>
                )}
              </div>
              <div className="client_card_details">
                <p className="client_details_title">{t("CUSTOMERS.FEES")}</p>
                <p className="client_card_details_values">
                  ${Number(loanHistory.totalFees).toFixed(2)}
                </p>
                <p className="client_details_date">
                  {t("CUSTOMERS.FEES_MESSAGE")}
                </p>
              </div>
            </div>
            {renderButtonorText()}
          </div>
        </div>
        <div className="loan_details_history_container">
          <div className="loan_details_history_title">
            <h2
              className={
                upcomingPaymentState ? "loan_details_history_tab_inactive" : ""
              }
              onClick={() => setUpcomingPaymentState(false)}
            >
              {t("CUSTOMERS.HISTORY")}
            </h2>
            <h2
              onClick={() => setUpcomingPaymentState(true)}
              className={
                upcomingPaymentState ? "" : "loan_details_history_tab_inactive"
              }
            >
              {t("CUSTOMERS.UPCOMING_PAYMENTS")}
            </h2>
          </div>
          <div className="client_details_history_content">
            {loanHistory.hasMissedPayment ? renderMissedPayment() : null}
            {upcomingPaymentState ? renderUpcomingPayments() : renderHistory()}
            {togglePaymentDetails ? (
              <PaymentDetailsModal
                togglePaymentDetails={setTogglePaymentDetails}
                paymentDetails={paymentDetails}
              />
            ) : null}
            {toggleMakePayments ? (
              <MakePaymentsModal
                toggleMakePayments={setToggleMakePayments}
                makePaymentsDetails={makePayments}
                targetLoanId={targetLoan.loanId}
              />
            ) : null}
            {toggleMissedPayments ? (
              <MissedPaymentsModal
                toggleMissedPayments={setToggleMissedPayments}
                targetLoanId={targetLoan.loanId}
              />
            ) : null}
            {toggleViewContract ? (
              <div>
                <ModalContainer
                  title={t("CUSTOMERS.VIEW_CONTRACT")}
                  onButtonClose={() => setToggleViewContract(false)}
                >
                  <div className="contract_container">
                    <div className="control-section">
                      <PdfViewerComponent
                        id="container"
                        ref={viewerRef}
                        serviceUrl={`${process.env.REACT_APP_BACKEND_BASE_URL}/pdfviewer`}
                        created={viewerCreatedHandler}
                        showNotificationDialog={false}
                        style={{ height: "640px" }}
                        toolbarSettings={{
                          showTooltip: true,
                          toolbarItems: [
                            "OpenOption",
                            "PageNavigationTool",
                            "MagnificationTool",
                            "PanTool",
                            "SelectionTool",
                            "SignatureOption",
                            "SearchOption",
                            "DownloadOption",
                          ],
                          annotationToolbarItems: [
                            "HighlightTool",
                            "UnderlineTool",
                            "StrikethroughTool",
                            "ColorEditTool",
                            "OpacityEditTool",
                            "AnnotationDeleteTool",
                            "StampAnnotationTool",
                            "HandWrittenSignatureTool",
                            "InkAnnotationTool",
                            "ShapeTool",
                            "CalibrateTool",
                            "StrokeColorEditTool",
                            "ThicknessEditTool",
                            "FreeTextAnnotationTool",
                            "FontFamilyAnnotationTool",
                            "FontSizeAnnotationTool",
                            "FontStylesAnnotationTool",
                            "FontAlignAnnotationTool",
                            "FontColorAnnotationTool",
                            "CommentPanelTool",
                          ],
                          formDesignerToolbarItems: [
                            "TextboxTool",
                            "PasswordTool",
                            "CheckBoxTool",
                            "RadioButtonTool",
                            "DropdownTool",
                            "ListboxTool",
                            "DrawSignatureTool",
                            "DeleteTool",
                          ],
                        }}
                      >
                        <Inject
                          services={[
                            Toolbar,
                            Magnification,
                            Annotation,
                            Navigation,
                            LinkAnnotation,
                            BookmarkView,
                            ThumbnailView,
                            Print,
                            TextSelection,
                            TextSearch,
                            FormFields,
                            FormDesigner,
                          ]}
                        />
                      </PdfViewerComponent>
                    </div>
                  </div>
                </ModalContainer>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDetails;
