import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import {
  closeTosModalState,
  getTosModalState,
  togglePPModalState,
} from "../../../store/authSlice";
import { getTOSMessage, getTOS } from "../../../store/contentSlice";

import { getDate } from "../../../utils/helpers";
import "./TosModal.css";

const TosModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tosModalState = useSelector(getTosModalState);
  const termsOfServiceContent = useSelector(getTOSMessage);

  useEffect(() => {
    if (tosModalState) {
      dispatch(getTOS());
    }
  }, [tosModalState, dispatch]);

  const promptPP = () => {
    dispatch(closeTosModalState());
    dispatch(togglePPModalState());
  };

  return (
    <ModalContainer
      className="tos"
      title={t("MODALS.TERMS_OF_SERVICE")}
      onButtonClose={() => dispatch(closeTosModalState())}
    >
      <p className="tos_updated_message">{t("MODALS.TOS_UPDATED_MESSAGE")}</p>
      <div className="tos_version">
        <p>
          {t("LANDING_PAGE.VERSION")} {termsOfServiceContent.version}
        </p>
        <p>
          {t("CUSTOMERS.UPDATED_ON")} {getDate(termsOfServiceContent.created)}
        </p>
      </div>
      <div
        className="tos_content"
        dangerouslySetInnerHTML={{
          __html: termsOfServiceContent.htmlContent,
        }}
      ></div>
      <LgGreenButton
        text={t("FORM_FIELDS.I_ACCEPT")}
        onButtonClick={promptPP}
      />
    </ModalContainer>
  );
};

export default TosModal;
