import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getArtistsData,
  registerCustomer,
  getIndividualArtist,
  loanApplicationRequest,
  startAgeRequest,
  getCustomerLoans,
  startBankRequest,
  startSignatureRequest,
  startCancelRequest,
  getCustomerStripeSecret,
  getCustomerData,
  editCustomer,
  setLoanApproved,
  approveSubLoan,
  getLoanHistory,
  getLoanUpcomingPayments,
  getMakePaymentDetailsFromServer,
  payOffSubloanWithMakePayment,
  getMissedPaymentInfo,
  payOffMissedPaymentWithMakePayment,
  getCustomerHistory,
} from "../api/customer";

const initialState = {
  loading: false,
  generalError: false,
  generalErrorMessage: "",
  customerInfo: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: "",
  },
  errorMessage: "",
  artists: {},
  targetArtist: {},
  individualArtistPageState: false,
  applyForLoanModalState: false,
  inveriteModalState: false,
  inveriteUrl: "",
  customerEditInfoModalState: false,
  targetLoan: {
    loanId: "",
    artistName: "",
    fullLoanStatus: "",
    created: "",
    requestedAmount: 0,
    term: 0,
    iframeUrl: "",
  },
  loanHistory: {},
  loanUpcomingPayments: {},
  loanMakePayments: {},
  loanMissedPayment: null,
  stripeClientSecret: "",
  loans: [],
  customerHistory: [],
  loanCount: 0,
  approveLoanModalState: false,
  declineLoanModalState: false,
  successModal: false,
  individualLoanViewMoreState: false,
  paymentComplete: false,
};

export const signUpCustomer = createAsyncThunk(
  "/signUpCustomer",
  async (data, thunkAPI) => {
    try {
      const res = await registerCustomer(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "DUPLICATE_EMAIL") {
          return thunkAPI.rejectWithValue("ERRORS.DUPLICATE_EMAIL");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        return thunkAPI.rejectWithValue(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getCustomer = createAsyncThunk("/Customer", async (thunkAPI) => {
  try {
    const res = await getCustomerData();
    return res;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      if (error.response.data.message === "DUPLICATE_EMAIL") {
        return thunkAPI.rejectWithValue("ERRORS.DUPLICATE_EMAIL");
      }
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response.status);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
  }
});

export const getArtistsInfo = createAsyncThunk(
  "/Artists/getArtistsInfo",
  async (data, thunkAPI) => {
    try {
      const res = await getArtistsData(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const getTargetArtistsData = createAsyncThunk(
  "/Artists/{artistId}",
  async (id, thunkAPI) => {
    try {
      const res = await getIndividualArtist(id);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const createLoanApplicationRequest = createAsyncThunk(
  "/Loan/loanApplicationRequest",
  async (data, thunkAPI) => {
    try {
      const res = await loanApplicationRequest(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "INVALID_LOAN_VALUE") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_LOAN_VALUE");
        }
        if (error.response.data.message === "NOT_ALLOWED_SECOND_LOAN") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_ALLOWED_SECOND_LOAN");
        }
        if (error.response.data.message === "LOAN_APPLICATION_IN_PROGRESS") {
          return thunkAPI.rejectWithValue(
            "ERRORS.LOAN_APPLICATION_IN_PROGRESS"
          );
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const ageRequest = createAsyncThunk(
  "/Loan/ageRequest",
  async (data, thunkAPI) => {
    try {
      const res = await startAgeRequest(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "REFRESH_PAGE") {
          return thunkAPI.rejectWithValue("ERRORS.REFRESH_PAGE");
        }
        if (error.response.data.message === "TOO_MANY_TRIES") {
          return thunkAPI.rejectWithValue("ERRORS.TOO_MANY_TRIES");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const bankRequest = createAsyncThunk(
  "/Loan/bankRequest",
  async (data, thunkAPI) => {
    try {
      const res = await startBankRequest(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "AGE_DENIED") {
          return thunkAPI.rejectWithValue("ERRORS.AGE_DENIED");
        }
        if (error.response.data.message === "TOO_MANY_TRIES") {
          return thunkAPI.rejectWithValue("ERRORS.TOO_MANY_TRIES");
        }
        if (error.response.data.message === "INVERITE_PROCESSING") {
          return thunkAPI.rejectWithValue("ERRORS.INVERITE_PROCESSING");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const signatureRequest = createAsyncThunk(
  "/Loan/signatureRequest",
  async (data, thunkAPI) => {
    try {
      const res = await startSignatureRequest(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const cancelRequest = createAsyncThunk(
  "/Loan/cancelRequest",
  async (data, thunkAPI) => {
    try {
      const res = await startCancelRequest(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const customerStripeSetup = createAsyncThunk(
  "/Loan/customerStripeSetup",
  async (data, thunkAPI) => {
    try {
      const res = await getCustomerStripeSecret(data);
      if (res === "CREDIT_CARD_ALREADY_ON_FILE") {
        const approvedRes = await setLoanApproved(data);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.message === "LOANS_FROZEN" ||
          error.response.data.message === "FLOAT_BELOW_MIN"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.FLOAT_ERROR");
        }
        if (error.response.data.message === "NOT_ALLOWED_SECOND_LOAN") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_ALLOWED_SECOND_LOAN");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (
          error.response.status === 500 &&
          error.response.data.message === "Sign Contract First"
        ) {
          return thunkAPI.rejectWithValue("Sign_Contract_First");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const editCustomerProfile = createAsyncThunk(
  "/Loan/editCustomerProfile",
  async (data, thunkAPI) => {
    try {
      const res = await editCustomer(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const getLoans = createAsyncThunk("/Loan", async (thunkAPI) => {
  try {
    const res = await getCustomerLoans();
    return res;
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response.status);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
  }
});

export const stripeComplete = createAsyncThunk(
  "/Loan/stripeComplete",
  async (data, thunkAPI) => {
    try {
      const res = await setLoanApproved(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (
          error.response.data.message === "LOANS_FROZEN" ||
          error.response.data.message === "FLOAT_BELOW_MIN"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.FLOAT_ERROR");
        }
        if (error.response.data.message === "NOT_ALLOWED_SECOND_LOAN") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_ALLOWED_SECOND_LOAN");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const customerApproveSubLoan = createAsyncThunk(
  "/Loan/customerApproveSubLoan",
  async (data, thunkAPI) => {
    try {
      const res = await approveSubLoan(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "STRIPE_INCORRECT_CVC") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_INCORRECT_CVC");
        } else if (
          error.response.data.message === "STRIPE_INSUFFICIENT_FUNDS"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_INSUFFICIENT_FUNDS");
        } else if (error.response.data.message === "STRIPE_EXPIRED_CARD") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_EXPIRED_CARD");
        } else if (error.response.data.message === "STRIPE_PROCESSING_ERROR") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_PROCESSING_ERROR");
        } else if (
          error.response.data.message === "STRIPE_CARD_DECLINED" ||
          error.response.data.message === "STRIPE_CARD_ERROR" ||
          error.response.data.message.includes(
            "STRIPE_PAYMENT_ERROR_UNHANDLED_EXCEPTION"
          )
        ) {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_GENERAL_ERROR");
        } else if (error.response.data.message === "CUSTOMER_BLOCKED") {
          return thunkAPI.rejectWithValue("ERRORS.CUSTOMER_BLOCKED");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const customerPayoffSubloan = createAsyncThunk(
  "/Loan/customerPayoffSubloan",
  async (id, thunkAPI) => {
    try {
      const res = await payOffSubloanWithMakePayment(id);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "STRIPE_INCORRECT_CVC") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_INCORRECT_CVC");
        } else if (
          error.response.data.message === "STRIPE_INSUFFICIENT_FUNDS"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_INSUFFICIENT_FUNDS");
        } else if (error.response.data.message === "STRIPE_EXPIRED_CARD") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_EXPIRED_CARD");
        } else if (error.response.data.message === "STRIPE_PROCESSING_ERROR") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_PROCESSING_ERROR");
        } else if (
          error.response.data.message === "STRIPE_CARD_DECLINED" ||
          error.response.data.message === "STRIPE_CARD_ERROR" ||
          error.response.data.message.includes(
            "STRIPE_PAYMENT_ERROR_UNHANDLED_EXCEPTION"
          )
        ) {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_GENERAL_ERROR");
        } else if (error.response.data.message === "CUSTOMER_BLOCKED") {
          return thunkAPI.rejectWithValue("ERRORS.CUSTOMER_BLOCKED");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const customerPayoffMissedPayments = createAsyncThunk(
  "/Loan/customerPayoffMissedPayments",
  async (data, thunkAPI) => {
    try {
      const res = await payOffMissedPaymentWithMakePayment(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "STRIPE_INCORRECT_CVC") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_INCORRECT_CVC");
        } else if (
          error.response.data.message === "STRIPE_INSUFFICIENT_FUNDS"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_INSUFFICIENT_FUNDS");
        } else if (error.response.data.message === "NO_LATE_INVOICES") {
          return thunkAPI.rejectWithValue("ERRORS.NO_LATE_INVOICES");
        } else if (error.response.data.message === "STRIPE_EXPIRED_CARD") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_EXPIRED_CARD");
        } else if (error.response.data.message === "STRIPE_PROCESSING_ERROR") {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_PROCESSING_ERROR");
        } else if (
          error.response.data.message === "STRIPE_CARD_DECLINED" ||
          error.response.data.message === "STRIPE_CARD_ERROR" ||
          error.response.data.message.includes(
            "STRIPE_PAYMENT_ERROR_UNHANDLED_EXCEPTION"
          )
        ) {
          return thunkAPI.rejectWithValue("ERRORS.STRIPE_GENERAL_ERROR");
        } else if (error.response.data.message === "CUSTOMER_BLOCKED") {
          return thunkAPI.rejectWithValue("ERRORS.CUSTOMER_BLOCKED");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getloanDetailsHistory = createAsyncThunk(
  "/Loan/getloanDetailsHistory",
  async (data, thunkAPI) => {
    try {
      const res = await getLoanHistory(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getloanDetailsUpcomingPayment = createAsyncThunk(
  "/Loan/getloanDetailsUpcomingPayment",
  async (data, thunkAPI) => {
    try {
      const res = await getLoanUpcomingPayments(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getMakePaymentDetails = createAsyncThunk(
  "/Loan/getMakePaymentDetails",
  async (data, thunkAPI) => {
    try {
      const res = await getMakePaymentDetailsFromServer(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);
export const getMissedPaymentDetails = createAsyncThunk(
  "/Loan/getMissedPaymentDetails",
  async (data, thunkAPI) => {
    try {
      const res = await getMissedPaymentInfo(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
        // Request made and server responded
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getCustomerHistoryData = createAsyncThunk(
  "/getCustomerHistoryData",
  async (data, thunkAPI) => {
    try {
      const res = await getCustomerHistory(data);
      return res;
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.data.message === "DUPLICATE_EMAIL") {
          return thunkAPI.rejectWithValue("ERRORS.DUPLICATE_EMAIL");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response.status);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    toggleIndividualArtistPageState: (state) => {
      state.individualArtistPageState = !state.individualArtistPageState;
    },
    toggleApplyForLoanModalState: (state) => {
      state.applyForLoanModalState = !state.applyForLoanModalState;
    },
    toggleInveriteModalState: (state) => {
      state.inveriteModalState = !state.inveriteModalState;
    },
    setNewLoanId: (state, action) => {
      state.targetLoan.loanId = action.payload;
    },
    setTargetLoanStatus: (state, action) => {
      state.targetLoan.fullLoanStatus = action.payload;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    setGeneralError: (state) => {
      state.generalError = true;
      state.generalErrorMessage = "ERRORS.GENERAL_ERROR";
    },
    resetCustomerGeneralError: (state) => {
      state.generalError = false;
      state.generalErrorMessage = "";
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    resetPaymentComplete: (state) => {
      state.paymentComplete = false;
    },
    resetClientSecret: (state) => {
      state.stripeClientSecret = "";
    },
    toggleCustomerEditInfoModalState: (state) => {
      state.customerEditInfoModalState = !state.customerEditInfoModalState;
    },
    toggleApproveLoanModalState: (state) => {
      state.approveLoanModalState = !state.approveLoanModalState;
    },
    toggleDeclineLoanModalState: (state) => {
      state.declineLoanModalState = !state.declineLoanModalState;
    },
    setTargetLoan: (state, action) => {
      state.targetLoan = action.payload;
    },
    resetSuccessModal: (state) => {
      state.successModal = false;
    },
    toggleIndividualLoanViewMoreState: (state) => {
      state.individualLoanViewMoreState = !state.individualLoanViewMoreState;
    },
    resetCustomerInfo: (state) => {
      state.customerInfo = {};
    },
  },
  extraReducers: {
    [signUpCustomer.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [signUpCustomer.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [signUpCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.errorMessage = "";
      state.customerInfo = action.payload;
    },
    [getArtistsInfo.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getArtistsInfo.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getArtistsInfo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.artists = payload;
    },
    [getTargetArtistsData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getTargetArtistsData.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getTargetArtistsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.targetArtist = payload;
      state.individualArtistPageState = true;
    },
    [createLoanApplicationRequest.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [createLoanApplicationRequest.rejected]: (state, action) => {
      state.loading = false;
      if (
        action.payload === "ERRORS.GENERAL_ERROR" ||
        action.payload === "ERRORS.NOT_ALLOWED_SECOND_LOAN" ||
        action.payload === "ERRORS.LOAN_APPLICATION_IN_PROGRESS"
      ) {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [createLoanApplicationRequest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.targetLoan.loanId = payload.id;
      state.targetLoan.fullLoanStatus = "Waiting_Verification";
      state.individualArtistPageState = false;
      state.applyForLoanModalState = false;
      state.inveriteModalState = true;
    },
    [ageRequest.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [ageRequest.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      if (action.payload === "ERRORS.TOO_MANY_TRIES") {
        state.inveriteModalState = false;
      }
      state.errorMessage = action.payload;
    },
    [ageRequest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.targetLoan.iframeUrl = payload.iframeUrl;
      state.targetLoan.fullLoanStatus = payload.fullLoanStatus;
      state.inveriteModalState = true;
    },
    [getLoans.pending]: (state) => {
      state.loading = true;
    },
    [getLoans.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getLoans.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loans = payload;
      const index = payload.findIndex((object) => {
        return object.loanId === state.targetLoan.loanId;
      });
      if (index >= 0) {
        state.targetLoan.fullLoanStatus = payload[index].fullLoanStatus;
        if (
          (payload[index].fullLoanStatus === "Waiting_Verification" ||
            payload[index].fullLoanStatus === "Age_Initiated" ||
            payload[index].fullLoanStatus === "Age_Completed" ||
            payload[index].fullLoanStatus === "Risk_Score_Initiated" ||
            payload[index].fullLoanStatus === "Risk_Score_Completed" ||
            payload[index].fullLoanStatus === "Contract_Initiated" ||
            payload[index].fullLoanStatus === "Contract_Completed") &&
          state.generalError === false
        ) {
          state.inveriteModalState = true;
        } else {
          state.inveriteModalState = false;
        }
      }
    },
    [bankRequest.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [bankRequest.rejected]: (state, action) => {
      state.loading = false;
      if (
        action.payload === "ERRORS.AGE_DENIED" ||
        action.payload === "ERRORS.TOO_MANY_TRIES" ||
        action.payload === "ERRORS.INVERITE_PROCESSING"
      ) {
        state.inveriteModalState = false;
      }
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [bankRequest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.targetLoan.iframeUrl = payload.iframeUrl;
      state.targetLoan.fullLoanStatus = payload.fullLoanStatus;
      state.inveriteModalState = true;
    },
    [signatureRequest.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [signatureRequest.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [signatureRequest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.errorMessage = "";
      state.inveriteModalState = false;
    },
    [cancelRequest.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [cancelRequest.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [cancelRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.errorMessage = "";
    },
    [customerStripeSetup.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [customerStripeSetup.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "Sign_Contract_First") {
      }
      if (
        action.payload === "ERRORS.GENERAL_ERROR" ||
        action.payload === "ERRORS.NOT_ALLOWED_SECOND_LOAN"
      ) {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
        state.inveriteModalState = false;
      }
      state.errorMessage = action.payload;
    },
    [customerStripeSetup.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload === "CREDIT_CARD_ALREADY_ON_FILE") {
        state.errorMessage = "";
        state.loading = false;
        state.targetLoan = {
          loanId: "",
          artistName: "",
          fullLoanStatus: "",
          created: "",
          requestedAmount: 0,
          term: 0,
          iframeUrl: "",
        };
        state.successModal = true;
      } else {
        state.stripeClientSecret = action.payload;
      }
    },
    [getCustomer.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getCustomer.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerInfo = action.payload;
    },
    [editCustomerProfile.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [editCustomerProfile.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [editCustomerProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerInfo = action.payload;
      state.customerEditInfoModalState = false;
    },
    [stripeComplete.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
      state.inveriteModalState = false;
      state.targetLoan = {
        loanId: "",
        artistName: "",
        fullLoanStatus: "",
        created: "",
        requestedAmount: 0,
        term: 0,
        iframeUrl: "",
      };
    },
    [stripeComplete.rejected]: (state, action) => {
      state.loading = false;
      if (
        action.payload === "ERRORS.GENERAL_ERROR" ||
        action.payload === "ERRORS.NOT_ALLOWED_SECOND_LOAN"
      ) {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
        state.inveriteModalState = false;
      }
      state.errorMessage = action.payload;
    },
    [stripeComplete.fulfilled]: (state, action) => {
      state.loading = false;
      state.inveriteModalState = false;
    },
    [customerApproveSubLoan.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
      state.approveLoanModalState = false;
    },
    [customerApproveSubLoan.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
      state.paymentComplete = true;
    },
    [customerApproveSubLoan.fulfilled]: (state, action) => {
      state.loading = false;
      state.approveLoanModalState = false;
      state.paymentComplete = true;
    },
    [getloanDetailsHistory.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getloanDetailsHistory.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getloanDetailsHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.loanHistory = action.payload;
    },
    [getloanDetailsUpcomingPayment.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getloanDetailsUpcomingPayment.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getloanDetailsUpcomingPayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.loanUpcomingPayments = action.payload;
    },
    [getMakePaymentDetails.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getMakePaymentDetails.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getMakePaymentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.loanMakePayments = action.payload;
    },
    [customerPayoffSubloan.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [customerPayoffSubloan.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
      state.paymentComplete = true;
    },
    [customerPayoffSubloan.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentComplete = true;
    },
    [getMissedPaymentDetails.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getMissedPaymentDetails.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getMissedPaymentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.loanMissedPayment = action.payload.paymentAmount;
    },
    [customerPayoffMissedPayments.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [customerPayoffMissedPayments.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
      state.paymentComplete = true;
    },
    [customerPayoffMissedPayments.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentComplete = true;
    },
    [getCustomerHistoryData.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerHistoryData.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
        state.generalErrorMessage = action.payload;
      }
      state.errorMessage = action.payload;
    },
    [getCustomerHistoryData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerHistory = payload.loanApplications;
      state.loanCount = payload.loanCount;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIndividualArtistPageState,
  toggleApplyForLoanModalState,
  toggleInveriteModalState,
  setNewLoanId,
  setTargetLoanStatus,
  toggleLoading,
  setGeneralError,
  resetCustomerGeneralError,
  resetClientSecret,
  toggleCustomerEditInfoModalState,
  toggleApproveLoanModalState,
  toggleDeclineLoanModalState,
  setTargetLoan,
  resetSuccessModal,
  toggleIndividualLoanViewMoreState,
  resetErrorMessage,
  resetPaymentComplete,
  resetCustomerInfo,
} = customerSlice.actions;
export const getCustomerGeneralError = (state) => state.customer.generalError;
export const getCustomerGeneralErrorMessage = (state) =>
  state.customer.generalErrorMessage;
export const getCustomerStatus = (state) => state.customer.customerInfo.status;
export const getCustomerError = (state) => state.customer.errorMessage;
export const getCustomerLoading = (state) => state.customer.loading;
export const getCustomerInfo = (state) => state.customer.customerInfo;
export const getArtists = (state) =>
  state.customer.artists?.artistPublicProfileVMs;
export const getArtistCount = (state) =>
  state.customer.artists.totalArtistCount;
export const getIndividualArtistPageState = (state) =>
  state.customer.individualArtistPageState;
export const getTargetArtist = (state) => state.customer.targetArtist;
export const getApplyForLoanModalState = (state) =>
  state.customer.applyForLoanModalState;
export const getLoanInfo = (state) => state.customer.targetLoan;
export const getInveriteModalState = (state) =>
  state.customer.inveriteModalState;
export const getCustomerLoansInfo = (state) => state.customer.loans;
export const getClientSecret = (state) => state.customer.stripeClientSecret;
export const getCustomerEditInfoModalState = (state) =>
  state.customer.customerEditInfoModalState;
export const getApproveLoanModalState = (state) =>
  state.customer.approveLoanModalState;
export const getDeclineLoanModalState = (state) =>
  state.customer.declineLoanModalState;
export const getSuccessModalState = (state) => state.customer.successModal;
export const getIndividualLoanViewMoreState = (state) =>
  state.customer.individualLoanViewMoreState;
export const getLoanDetailsHistoryData = (state) => state.customer.loanHistory;
export const getLoanDetailsUpcomingPaymentsData = (state) =>
  state.customer.loanUpcomingPayments;
export const getLoanMakePaymentsData = (state) =>
  state.customer.loanMakePayments;
export default customerSlice.reducer;
