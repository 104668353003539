import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import SuccessModal from "../SuccessModal/SuccessModal";

import {
  getLoanInfo,
  getDeclineLoanModalState,
  toggleDeclineLoanModalState,
  getLoans,
} from "../../../store/customerSlice";
import { approveSubLoan } from "../../../api/customer";

import "./DeclineLoanModal.css";

const DeclineLoanModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const declineLoanModalState = useSelector(getDeclineLoanModalState);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [error, setError] = useState("");
  const targetLoan = useSelector(getLoanInfo);

  const onDeclineSubmit = async (e) => {
    e.preventDefault();
    const data = {
      subLoanId: targetLoan.subLoan.subLoanId,
      approved: false,
    };
    try {
      const res = await approveSubLoan(data);
      setToggleSuccess(true);
      dispatch(toggleDeclineLoanModalState());
      setTimeout(() => {
        setToggleSuccess(false);
        dispatch(getLoans());
      }, 1500);
    } catch (error) {
      console.log(error.response);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  return (
    <div>
      {declineLoanModalState ? (
        <ModalContainer
          title={t("CUSTOMERS.DECLINE_REQUEST")}
          onButtonClose={() => dispatch(toggleDeclineLoanModalState())}
        >
          <div className="decline_loan_container">
            <p>{t("CUSTOMERS.DECLINE_REQUEST_MESSAGE")}</p>
            <div className="decline_modal_details">
              <p>
                {t("CUSTOMERS.REQUESTED_AMOUNT")}
                <span>${Number(targetLoan.subLoan.amount).toFixed(2)}</span>
              </p>
            </div>
            <button className="lg_decline" onClick={onDeclineSubmit}>
              {t("CUSTOMERS.DECLINE")}
            </button>
          </div>
        </ModalContainer>
      ) : null}
      ;
      {toggleSuccess ? (
        <SuccessModal title={"FORM_FIELDS.DECLINE_SUCCESSFUL"} />
      ) : null}
    </div>
  );
};

export default DeclineLoanModal;
