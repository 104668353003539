import React from "react";
import { useTranslation } from "react-i18next";

import ModalContainer from "../ModalContainer/ModalContainer";

import "./PaymentDetailsModal.css";

const PaymentDetailsModal = ({ paymentDetails, togglePaymentDetails }) => {
  const { t } = useTranslation();

  const getPaymentDateMonth = (isoDate) => {
    const monthNames = [
      t("CUSTOMERS.JAN"),
      t("CUSTOMERS.FEB"),
      t("CUSTOMERS.MAR"),
      t("CUSTOMERS.APR"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUN"),
      t("CUSTOMERS.JUL"),
      t("CUSTOMERS.AUG"),
      t("CUSTOMERS.SEP"),
      t("CUSTOMERS.OCT"),
      t("CUSTOMERS.NOV"),
      t("CUSTOMERS.DEC"),
    ];
    const date = new Date(isoDate);
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];

    return `${month} ${dt}`;
  };

  const renderSubLoans = () => {
    if (!paymentDetails) {
      return;
    } else {
      return paymentDetails.subloans.map((subloan) => {
        return (
          <p key={subloan.created}>
            {getPaymentDateMonth(subloan.created)}-{" "}
            {t("CUSTOMERS.PAYMENT_APPROVED_AMOUNT")} ${subloan.amount}
            <span>${subloan.monthlyPayment.toFixed(2)}</span>
          </p>
        );
      });
    }
  };

  return (
    <div>
      <ModalContainer
        title={t("CUSTOMERS.PAYMENT_DETAILS")}
        onButtonClose={() => togglePaymentDetails(false)}
      >
        <div className="payment_details_container">
          <div className="payment_details_modal_details">
            {renderSubLoans()}
            <p>
              {t("CUSTOMERS.FEES")}
              <span>${paymentDetails.totalFee.toFixed(2)}</span>
            </p>
            {paymentDetails.lateFee === 0 ? null : (
              <p>
                {t("CUSTOMERS.LATE_FEES")}
                <span>${paymentDetails.lateFee.toFixed(2)}</span>
              </p>
            )}
            <hr />
            <p>
              {t("CUSTOMERS.TOTAL")}
              <span>${paymentDetails.totalAmount.toFixed(2)}</span>
            </p>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default PaymentDetailsModal;
