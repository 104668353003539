import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getClientCardDetails } from "../../../api/artist";
import { getDateWithTime } from "../../../utils/helpers";
import "./ClientDetails.css";

const ClientDetails = ({ targetLoan, onCompleteTattoo }) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState(null);
  const [error, setError] = useState("");
  const status = useSelector((state) => state.artist.artistInfo.status);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const data = {
          id: targetLoan.loanApplicationId,
        };
        const res = await getClientCardDetails(data);
        setHistory(res);
      } catch (error) {
        console.log(error.response);
        setError("ERRORS.GENERAL_ERROR");
      }
    };
    getDetails();
  }, [targetLoan]);

  const getPaymentDateMonthWithTime = (isoDate) => {
    const monthNames = [
      t("CUSTOMERS.JAN"),
      t("CUSTOMERS.FEB"),
      t("CUSTOMERS.MAR"),
      t("CUSTOMERS.APR"),
      t("CUSTOMERS.MAY"),
      t("CUSTOMERS.JUN"),
      t("CUSTOMERS.JUL"),
      t("CUSTOMERS.AUG"),
      t("CUSTOMERS.SEP"),
      t("CUSTOMERS.OCT"),
      t("CUSTOMERS.NOV"),
      t("CUSTOMERS.DEC"),
    ];
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];
    let hour = date.getHours();
    let minute = date.getMinutes();

    if (month < 10) {
      month = "0" + month;
    }

    let amPm = "AM";
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (hour >= 12) {
      amPm = "PM";
    }
    hour = hour % 12;
    hour = hour ? hour : 12;

    if (minute.toString().length === 1) {
      minute = `0${minute}`;
    }

    return `${month} ${dt}, ${year} ${hour}:${minute} ${amPm}`;
  };

  const renderStatus = () => {
    if (targetLoan.fullLoanStatus === "Completed") {
      return "ARTISTS.APPROVED";
    } else if (targetLoan.fullLoanStatus === "SubLoan_Waiting_Approval") {
      return "ARTISTS.AWAITING_APPROVAL";
    } else {
      return "ARTISTS.APPROVED";
    }
  };

  const renderHistory = () => {
    if (!history || history.length === 0 || history.payments.length === 0) {
      return (
        <div className="client_details_history_item_no_content">
          <p>{t("ARTISTS.NO_HISTORY")}</p>
        </div>
      );
    } else {
      return history.payments.map((payment) => {
        return (
          <div key={payment.lineDate} className="client_details_history_item">
            <p className="loan_detail_payment_date">
              {getPaymentDateMonthWithTime(payment.lineDate)}
            </p>
            <p className="client_detail_payment_description">
              {t("ARTISTS.BALANCE_REDEEMED")}
            </p>
            <p className="loan_detail_payment_amount">
              ${payment.lineAmount.toFixed(2)}
            </p>
          </div>
        );
      });
    }
  };

  return (
    <div className="client_details_container">
      <div className="client_details_header">
        <div className="client_card_status">{t(renderStatus())}</div>
        <div className="client_details_header_details">
          <div className="client_details_header_text">
            <div className="client_card_details">
              <p className="client_details_title">
                {t("CUSTOMERS.APPROVED_AMOUNT")}
              </p>
              <p className="client_card_details_values">
                ${Number(targetLoan.loanApprovedAmount).toFixed(2)}
              </p>
              <p className="client_details_date">
                {t("CUSTOMERS.APPROVED_ON")}{" "}
                {getDateWithTime(targetLoan.approvedAmountDate)}
              </p>
            </div>
            <div className="client_card_details">
              <p className="client_details_title">{t("ARTISTS.AMOUNT_USED")}</p>
              <p className="client_card_details_values">
                ${Number(targetLoan.loanUsedAmount).toFixed(2)}
              </p>
              {targetLoan.usedAmountDate === null ? null : (
                <p className="client_details_date">
                  {t("CUSTOMERS.UPDATED_ON")}{" "}
                  {getDateWithTime(targetLoan.usedAmountDate)}
                </p>
              )}
            </div>
          </div>
          {targetLoan.fullLoanStatus === "Completed" ? null : (
            <button
              className="complete_tattoo_button"
              disabled={status === "Blocked" ? true : false}
              value={targetLoan.loanApplicationId}
              onClick={onCompleteTattoo}
            >
              {t("ARTISTS.COMPLETE_TATTOO")}
            </button>
          )}
        </div>
      </div>
      <div className="client_details_history_container">
        <div className="client_details_history_title">
          <h2>{t("ARTISTS.HISTORY")}</h2>
        </div>
        <div className="client_details_history_content">{renderHistory()}</div>
      </div>
    </div>
  );
};

export default ClientDetails;
