import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import SmGreenButton from "../../ButtonsFormFields/SmGreenButton/SmGreenButton";
import SuccessModal from "../../Modals/SuccessModal/SuccessModal";

import { sendContactUs } from "../../../api/user";

import "./ContactUs.css";

const ContactUs = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (message.length === 0) {
    } else {
      const data = {
        name: name,
        message: message,
        email: email,
      };
      try {
        const res = await sendContactUs(data);
        setToggleSuccess(true);
        setName("");
        setEmail("");
        setMessage("");
        setTimeout(() => {
          setToggleSuccess(false);
        }, 1500);
      } catch (error) {
        console.log(error.response);
        setError("ERRORS.GENERAL_ERROR");
      }
    }
  };
  return (
    <div className="contact_us" id="contact_section">
      <div className="contact_us_content">
        <h3 className="contact_us_title">
          {t("FORM_FIELDS.CONTACT_US_TITLE")}
        </h3>
        <form className="contact_us_form" onSubmit={handleSubmit}>
          <div className="contact_us_sender">
            <div className="contact_us_form_input">
              <label htmlFor="name">{t("FORM_FIELDS.NAME")}</label>
              <input
                type="text"
                value={name}
                placeholder={t("FORM_FIELDS.NAME")}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="contact_us_form_input">
              <label htmlFor="email">{t("FORM_FIELDS.EMAIL")}</label>
              <input
                type="email"
                value={email}
                placeholder={t("FORM_FIELDS.EMAIL")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="contact_us_form_text">
            <label htmlFor="message">{t("FORM_FIELDS.MESSAGE")}</label>
            <textarea
              value={message}
              placeholder={t("FORM_FIELDS.MESSAGE")}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="contact_us_button">
            <SmGreenButton
              text={t("FORM_FIELDS.SEND")}
              onButtonClick={handleSubmit}
            />
          </div>
          {toggleSuccess ? (
            <SuccessModal title={"FORM_FIELDS.MESSAGE_SUBMITTED"} />
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
