import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import ProfileCustomInput from "../../ButtonsFormFields/ProfileCustomInput/ProfileCustomInput";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import { getCustomerInfo, editCustomerProfile } from "../../../store/customerSlice";

import validate from "./EditInfoValidationRules";
import "./CustomerEditInfo.css";


const CustomerEditInfo = ({ onModalClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerInfo);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setPhone(customerInfo.phone);
    setFirstName(customerInfo.firstName);
    setLastName(customerInfo.lastName);
  }, [customerInfo.phone, customerInfo.firstName, customerInfo.lastName]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      };
      dispatch(editCustomerProfile(data));
    }
  }, [errors, isSubmitting, firstName, lastName, phone, dispatch]);

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    if (event.target.name === "firstName") {
      if (event.target.value.length <= 20) {
        setFirstName(event.target.value);
      }
    }
    if (event.target.name === "lastName") {
      if (event.target.value.length <= 20) {
        setLastName(event.target.value);
      }
    }
    if (event.target.name === "phone") {
      const input = event.target.value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 11);
      let phoneNumber;
      if (input.length > 6) {
        phoneNumber = `(${areaCode})${middle}-${last}`;
      } else if (input.length > 3) {
        phoneNumber = `(${areaCode})${middle}`;
      } else if (input.length > 0) {
        phoneNumber = `(${areaCode}`;
      }
      setPhone(phoneNumber);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors({});
    setErrors(
      validate(
        phone,
        firstName,
        lastName,
      )
    );
    setIsSubmitting(true);
  };

  return (
    <div>
      <ModalContainer
        title={t("ARTISTS.EDIT_PROFILE_DETAILS")}
        onButtonClose={onModalClose}
      >
        <ProfileCustomInput
          type="text"
          name="firstName"
          value={firstName}
          placeholder={t("FORM_FIELDS.FIRST_NAME")}
          onInputChange={handleChange}
          errorMessage={t(errors.firstName)}
          required
        />
        <ProfileCustomInput
          type="text"
          name="lastName"
          value={lastName}
          placeholder={t("FORM_FIELDS.LAST_NAME")}
          onInputChange={handleChange}
          errorMessage={t(errors.lastName)}
          required
        />
        <ProfileCustomInput
          type="tel"
          name="phone"
          value={phone}
          placeholder={t("FORM_FIELDS.PHONE")}
          onInputChange={handleChange}
          errorMessage={t(errors.phone)}
          required
        />
        <div className="save_changes_button">
          <LgGreenButton
            text={t("FORM_FIELDS.SAVE_CHANGES")}
            onButtonClick={handleSubmit}
          />
        </div>
      </ModalContainer>
    </div>
  );
};

export default CustomerEditInfo;
