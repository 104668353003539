import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../api/user";

const initialState = {
  notifications: null,
  generalError: false,
  errorMessage: "",
  loading: false,
};

export const getNotificationsFromServer = createAsyncThunk(
  "getNotificationsFromServer",
  async (data, thunkAPI) => {
    try {
      const res = await getNotifications(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        return thunkAPI.rejectWithValue(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getNotificationsFromServer.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [getNotificationsFromServer.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getNotificationsFromServer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.notifications = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = notificationSlice.actions;

export default notificationSlice.reducer;
