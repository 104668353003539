import React from 'react';
import './AboutUsDetail.css';
import { useTranslation } from "react-i18next";

const AboutUsDetail=({title, content})=>{
    const{t} = useTranslation();
    return(
        <div className="about_us_detail">
            <h3>{t(title)}</h3>
            <p>{t(content)}</p>
        </div>
    );
};

export default AboutUsDetail;