import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContentType } from "../api/content";
import { htmlDecode } from "../utils/helpers";

const initialState = {
  about: "",
  termsOfService: "",
  privacyPolicy: "",
};

export const getAbout = createAsyncThunk("getAbout", async (thunkAPI) => {
  try {
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes("en")) {
      userLang = "en";
    }
    let res = await getContentType("About_Us", userLang);
    let content = htmlDecode(res.htmlContent);
    res.htmlContent = content;
    return res;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    } else if (error.request) {
      // The request was made but no response was received
      return thunkAPI.rejectWithValue(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const getTOS = createAsyncThunk("getTOS", async (thunkAPI) => {
  try {
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes("en")) {
      userLang = "en";
    }
    let res = await getContentType("Terms_of_Service", userLang);
    let content = htmlDecode(res.htmlContent);
    res.htmlContent = content;
    return res;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    } else if (error.request) {
      // The request was made but no response was received
      return thunkAPI.rejectWithValue(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const getPP = createAsyncThunk("getPP", async (thunkAPI) => {
  try {
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes("en")) {
      userLang = "en";
    }
    let res = await getContentType("Privacy_Policy", userLang);
    let content = htmlDecode(res.htmlContent);
    res.htmlContent = content;
    return res;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    } else if (error.request) {
      // The request was made but no response was received
      return thunkAPI.rejectWithValue(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const contentSlice = createSlice({
  name: "content",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getAbout.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [getAbout.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getAbout.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.about = payload;
    },
    [getTOS.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [getTOS.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getTOS.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.termsOfService = payload;
    },
    [getPP.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [getPP.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getPP.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.privacyPolicy = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = contentSlice.actions;
export const getAboutMessage = (state) => state.content.about;
export const getTOSMessage = (state) => state.content.termsOfService;
export const getPPMessage = (state) => state.content.privacyPolicy;
export default contentSlice.reducer;
