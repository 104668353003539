import React from "react";
import { useTranslation } from "react-i18next";
import "./HomeArtistDetail.css";

const HomeArtistDetail = ({ title, content, image }) => {
  const { t } = useTranslation();
  return (
    <div className="home_artist_detail">
      <img className="home_artist_detail_img" src={image} alt="" />
      <h3 className="home_artist_detail_title">{t(title)}</h3>
      <p className="home_artist_detail_content">{t(content)}</p>
    </div>
  );
};

export default HomeArtistDetail;
