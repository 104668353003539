export default function validate(oldPassword, newPassword, confirmPassword) {
  let errors = {};
  const regexPassword = new RegExp(
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/
  );
  if (oldPassword === "") {
    errors.oldPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (newPassword === "") {
    errors.newPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (confirmPassword === "") {
    errors.confirmPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (newPassword !== confirmPassword) {
    errors.confirmPassword = "ERRORS.PASSWORDS_MUST_MATCH";
  }
  if (!regexPassword.test(newPassword)) {
    errors.confirmPassword = "ERRORS.VALID_PASSWORD";
  }
  return errors;
}
