import axios from "./api";

export function getArtist() {
  return axios({
    url: `/Artist/myprofile`,
    method: "get",
  });
}

export function editDescription(data) {
  return axios({
    url: `Artist/description`,
    method: "put",
    data,
  });
}

export function editProfile(data) {
  return axios({
    url: `Artist/profile`,
    method: "put",
    data,
  });
}

export function editPaymentEmail(data) {
  return axios({
    url: `Artist/updatepaymentemail`,
    method: "put",
    data,
  });
}

export function editProfileImages(data) {
  return axios({
    url: `/Artist/profileImages`,
    method: "put",
    data,
  });
}

export function uploadImage(data) {
  return axios({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: `/Upload`,
    method: "post",
    data,
  });
}

export function updateBannerImage(id) {
  return axios({
    url: `/Artist/banner/${id}`,
    method: "post",
  });
}

export function registerArtist(data) {
  return axios({
    url: `/Artist`,
    method: "post",
    data,
  });
}

export function getCurrentClients(data) {
  return axios({
    url: `/Loan/LoanSearch`,
    method: "post",
    data,
  });
}

export function redeemLoanAmount(data) {
  return axios({
    url: `/SubLoan`,
    method: "post",
    data,
  });
}

export function completeTattoo(data) {
  return axios({
    url: `/Loan/tattooComplete`,
    method: "put",
    data,
  });
}

export function getClientCardDetails(data) {
  return axios({
    url: `/Loan/artistviewmore`,
    method: "post",
    data,
  });
}

export function getClientHistory(data) {
  return axios({
    url: `/Loan/Artist/History`,
    method: "post",
    data,
  });
}




