import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import homeIcon from "../../../img/icon/icon_home.svg";
import homeIconGreen from "../../../img/icon/icon_home_green.svg";
import artistIcon from "../../../img/icon/icon_tattoo.svg";
import artistIconGreen from "../../../img/icon/icon_tattoo_green.svg";
import menuIcon from "../../../img/icon/icon_collapse.svg";
import aboutIcon from "../../../img/icon/icon_about.svg";
import aboutIconGreen from "../../../img/icon/icon_about_green.svg";
import contactIcon from "../../../img/icon/icon_requests.svg";
import contactIconGreen from "../../../img/icon/icon_contact_green.svg";
import xIcon from "../../../img/icon/icon_x.svg";

import SignUpModal from "../../Modals/SignUpModal/SignUpModal";

import "./nav.css";

const Nav = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [signUpModalState, setSignUpModalState] = useState(false);

  const toggleSignUpModal = () => {
    setSignUpModalState(!signUpModalState);
  };

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [activeLink, location.pathname, location.search]);

  return (
    <nav className="nav">
      <div className={toggleMenu ? "navbar show-nav" : "navbar"}>
        <div className="nav-link">
          <NavLink
            className={activeLink === "/" ? "active" : "nav-icon-link"}
            to="/"
            end
          >
            <img
              className="icon"
              src={activeLink === "/" ? homeIconGreen : homeIcon}
              alt="home icon"
            />
            {t("NAVBAR.HOME")}
          </NavLink>
        </div>
        <div className="nav-link">
          <NavLink
            className={
              activeLink === "/userArtist" || activeLink === "/userArtist/"
                ? "active"
                : "nav-icon-link"
            }
            to="/userArtist"
            replace
          >
            <img
              className="icon"
              src={
                activeLink === "/userArtist" || activeLink === "/userArtist/"
                  ? artistIconGreen
                  : artistIcon
              }
              alt="artist icon"
            />
            {t("NAVBAR.ARTISTS")}
          </NavLink>
        </div>
        <div className="nav-link">
          <NavLink className="nav-icon-link" to="/userAbout" replace>
            <img
              className="icon"
              src={
                activeLink === "/userAbout" || activeLink === "/userAbout/"
                  ? aboutIconGreen
                  : aboutIcon
              }
              alt="about icon"
            />
            {t("NAVBAR.ABOUT")}
          </NavLink>
        </div>
        <div className="nav-link">
          <NavLink className="nav-icon-link" to="/userContact" replace>
            <img
              className="icon"
              src={
                activeLink === "/userContact" || activeLink === "/userContact/"
                  ? contactIconGreen
                  : contactIcon
              }
              alt="contact icon"
            />
            {t("NAVBAR.CONTACT")}
          </NavLink>
        </div>
        <div
          onClick={toggleSignUpModal}
          className={
            toggleMenu
              ? "nav-button-link text-black"
              : "nav-button-link text-black nav_hidden"
          }
        >
          <div className="nav-link nav_sign-up">{t("NAVBAR.SIGN_UP")}</div>
        </div>
        <Link
          className={
            toggleMenu
              ? "nav-button-link text-white"
              : "nav-button-link text-white nav_hidden"
          }
          to="/login"
        >
          <div className="nav-link nav_login">{t("NAVBAR.LOGIN")}</div>
        </Link>
      </div>
      <button
        className="nav_collapse"
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        {toggleMenu ? (
          <img className="icon" src={xIcon} alt="menu close icon" />
        ) : (
          <img className="icon" src={menuIcon} alt="menu icon" />
        )}
      </button>
      <SignUpModal
        modalState={signUpModalState}
        onModalClose={toggleSignUpModal}
      />
    </nav>
  );
};

export default Nav;
