import React from "react";
import failIcon from "../../../img/icon/icon_fail.svg";
import { useTranslation } from "react-i18next";

const FailModal = ({ title, message = null }) => {
  const { t } = useTranslation();
  return (
    <div className="success_modal_background_container">
      <div className="success_modal_container">
        <div className="success_modal_image">
          <img src={failIcon} alt="Check Icon" />
        </div>
        <div className="success_modal_title">
          <h3>{t(title)}</h3>
          <p>{t(message)}</p>
        </div>
      </div>
    </div>
  );
};

export default FailModal;
