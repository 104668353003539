import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getArtistLoading } from "../../store/artistSlice";
import { getLoading } from "../../store/authSlice";
import { getCustomerLoading } from "../../store/customerSlice";

import "./Loader.css";

const Loader = () => {
  const loading = useSelector(getLoading);
  const artistLoading = useSelector(getArtistLoading);
  const customerLoading = useSelector(getCustomerLoading);

  useEffect(() => {
    console.log(loading, artistLoading, customerLoading);
  }, [loading, artistLoading, customerLoading]);

  return (
    <div className={loading || artistLoading || customerLoading ? "show_loading" : "hidden"}>
      <div className="loading_background_container">
        <div className="loading_container">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
