import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import CustomerPageWrapper from "../../../components/Customers/CustomerPageWrapper/CustomerPageWrapper";
import { Pagination } from "antd";

import logo from "../../../img/logo/logo_main.png";
import defaultBanner from "../../../img/banner_eye/banner_eye@2x.png";

import { getNotificationsFromServer } from "../../../store/notificationSlice";
import { readNotifications } from "../../../api/user";

import {
  getDateWithTime,
  notificationTitle,
  notificationMessage,
} from "../../../utils/helpers";

import "./CustomerNotifications.css";

const CustomerNotifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationCount = useSelector(
    (state) => state.notifications.notifications.totalNoteCount
  );
  const notifications = useSelector(
    (state) => state.notifications.notifications.noteVMs
  );
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 6;

  useEffect(() => {
    const data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    dispatch(getNotificationsFromServer(data));
  }, [pageIndex]);

  useEffect(() => {
    const readNotificationsOnDropdown = async (data) => {
      try {
        const res = await readNotifications(data);
      } catch (error) {
        console.log(error.response);
      }
    };
    const data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    readNotificationsOnDropdown(data);
  }, [pageIndex]);

  const onChange = (page, pageSize) => {
    setPageIndex(page);
  };

  const dashboardContent = () => {
    if (notifications.length === 0) {
      return (
        <div className="not_verified">
          <img src={logo} alt="Ink Me Financial logo" />
        </div>
      );
    } else {
      return notifications.map((notification) => {
        const message = notificationMessage(notification);
        return (
          <div
            key={notification.id}
            className={`notification_content_container ${
              notification.read === "0001-01-01T00:00:00" ? "unread" : ""
            }`}
          >
            <div className="notification_title_container">
              <h3>{t(notificationTitle(notification.messageId))}</h3>
              <div className="notification_status">{notification.status}</div>
              <p>{getDateWithTime(notification.created)}</p>
            </div>
            <p className="notification_dropdown_message">
              {t(message.message, message.data)}
            </p>
          </div>
        );
      });
    }
  };

  return (
    <div>
      <CustomerPageWrapper
        title={t("NAVBAR.NOTIFICATIONS")}
        image={defaultBanner}
      >
        <div className="notification_container">
          {dashboardContent()}
          <div className="notification_page_numbers">
            <Pagination
              size="small"
              current={pageIndex}
              pageSize={pageSize}
              total={notificationCount}
              onChange={onChange}
              responsive={true}
            />
          </div>
        </div>
      </CustomerPageWrapper>
    </div>
  );
};

export default CustomerNotifications;
