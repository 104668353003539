import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import SuccessModal from "../SuccessModal/SuccessModal";
import FailModal from "../FailModal/FailModal";

import {
  getApproveLoanModalState,
  getLoanInfo,
  toggleApproveLoanModalState,
  customerApproveSubLoan,
  resetErrorMessage,
  resetPaymentComplete,
  getLoans,
} from "../../../store/customerSlice";

import "./ApproveLoanModal.css";

const ApproveLoanModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const approveLoanModalState = useSelector(getApproveLoanModalState);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [toggleFail, setToggleFail] = useState(false);
  const stripeError = useSelector((state) => state.customer.errorMessage);
  const targetLoan = useSelector(getLoanInfo);
  const paymentComplete = useSelector(
    (state) => state.customer.paymentComplete
  );

  useEffect(() => {
    if (paymentComplete && stripeError.length === 0) {
      setToggleFail(false);
      setToggleSuccess(true);
      setTimeout(() => {
        setToggleSuccess(false);
        dispatch(resetPaymentComplete());
        dispatch(getLoans());
      }, 1500);
    } else if (paymentComplete && stripeError.length !== 0) {
      setToggleFail(true);
    }
  }, [paymentComplete, stripeError]);

  useEffect(() => {
    const toggleFailMessage = (e) => {
      setToggleFail(false);
      dispatch(resetPaymentComplete());
      dispatch(resetErrorMessage());
    };
    if (toggleFail) {
      window.addEventListener("click", (e) => toggleFailMessage(e));
    } else {
      window.removeEventListener("click", (e) => toggleFailMessage(e));
    }
    return () =>
      window.removeEventListener("click", (e) => toggleFailMessage(e));
  }, [toggleFail]);

  const onApproveLoan = async (e) => {
    e.preventDefault();
    const data = {
      subLoanId: targetLoan.subLoan.subLoanId,
      approved: true,
    };
    await dispatch(customerApproveSubLoan(data));
  };

  const renderModal = () => {
    if (!targetLoan) {
      return;
    } else {
      return (
        <div className="approve_loan_container">
          <p>
            {t("CUSTOMERS.APPROVE_LOAN_MESSAGE", {
              artistName: targetLoan.artistName,
            })}
          </p>
          <div className="approve_loan_modal_details">
            <p>
              {t("CUSTOMERS.ARTIST_REQUESTED_AMOUNT")}
              <span>${Number(targetLoan.subLoan.amount).toFixed(2)}</span>
            </p>
            <p>
              {t("CUSTOMERS.FEES")}
              <span>${Number(targetLoan.subLoan.fee).toFixed(2)}</span>
            </p>
            <p>
              {t("CUSTOMERS.TOTAL")}
              <span>${Number(targetLoan.subLoan.total).toFixed(2)}</span>
            </p>
            <hr />
            <p>
              {t("ARTISTS.REMAINING_BALANCE")}
              <span>${targetLoan.subLoan.remainingAmount.toFixed(2)}</span>
            </p>
          </div>
          <LgGreenButton
            text={t("CUSTOMERS.APPROVE")}
            onButtonClick={onApproveLoan}
          />
        </div>
      );
    }
  };
  return (
    <div>
      {approveLoanModalState ? (
        <ModalContainer
          title={t("CUSTOMERS.APPROVE")}
          onButtonClose={() => dispatch(toggleApproveLoanModalState())}
        >
          {renderModal()}
        </ModalContainer>
      ) : null}
      ;
      {toggleSuccess ? (
        <SuccessModal title={"FORM_FIELDS.AMOUNT_APPROVED"} />
      ) : null}
      {toggleFail ? (
        <FailModal
          title={
            stripeError === "ERRORS.CUSTOMER_BLOCKED"
              ? "ERRORS.BLOCKED_CUSTOMER"
              : "ERRORS.STRIPE_ERROR"
          }
          message={stripeError}
        />
      ) : null}
    </div>
  );
};

export default ApproveLoanModal;
