import React, { useState } from "react";

import CustomInput from "../CustomInput/CustomInput";

import ShowIcon from "../../../img/icon/icon_show.svg";
import HideIcon from "../../../img/icon/icon_hide.svg";

import "./PasswordInput.css";

const PasswordInput = ({
  value,
  placeholder,
  onInputChange,
  errorMessage,
  name,
  handleKeyPress,
  customClass,
}) => {
  const [showPassword, toggleShowPassword] = useState(false);

  return (
    <div className="password_wrapper">
      <CustomInput
        customClass={customClass}
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        placeholder={placeholder}
        onInputChange={onInputChange}
        onKeyPress={handleKeyPress}
        errorMessage={errorMessage}
        required
      />
      <img
        className="password_icon"
        onClick={() => toggleShowPassword(!showPassword)}
        src={showPassword ? HideIcon : ShowIcon}
        alt="show password icon"
      />
    </div>
  );
};

export default PasswordInput;
