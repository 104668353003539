import React from "react";
import { useTranslation } from "react-i18next";

import HomeArtistDetail from "../HomeArtistDetail/HomeArtistDetail";

import clientsImage from '../../../img/photo_clients/photo_clients@2x.png';
import artistsImage from '../../../img/photo_artists/photo_artists@2x.png';
import inkMeImage from '../../../img/photo_ink_me/photo_ink_me@2x.png';

import "./HomeArtistSection.css";

const HomeArtistSection = () => {

  return (
    <div className="home_artist_section" id="artist_section">
      <div className="home_artist_more">
        <HomeArtistDetail title={'LANDING_PAGE.CLIENTS'} content={"LANDING_PAGE.CLIENTS_CONTENT"} image={clientsImage}/>
        <HomeArtistDetail title={'LANDING_PAGE.ARTISTS'} content={"LANDING_PAGE.ARTISTS_CONTENT"} image={artistsImage}/>
        <HomeArtistDetail title={'LANDING_PAGE.INK_ME'} content={"LANDING_PAGE.INK_ME_CONTENT"} image={inkMeImage}/>
      </div>
    </div>
  );
};

export default HomeArtistSection;
