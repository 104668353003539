import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import {
  getTOSMessage,
  getPPMessage,
  getTOS,
  getPP,
} from "../../../store/contentSlice";

import { getDate } from "../../../utils/helpers";
import "./SignUpModal.css";

const SignUpModal = ({ modalState, onModalClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [role, setRole] = useState("");
  const [acceptTOS, setAcceptTOS] = useState(false);
  const [toggleTOSModal, setToggleTOSModal] = useState(false);
  const [acceptPP, setAcceptPP] = useState(false);
  const [togglePPModal, setTogglePPModal] = useState(false);
  const termsOfServiceContent = useSelector(getTOSMessage);
  const privacyPolicyContent = useSelector(getPPMessage);

  useEffect(() => {
    if (toggleTOSModal) {
      dispatch(getTOS());
    } else if (togglePPModal) {
      dispatch(getPP());
    }
  }, [toggleTOSModal, togglePPModal, dispatch]);

  const selectCustomer = () => {
    setRole("customer");
    promptTOS();
  };

  const selectArtist = () => {
    setRole("artist");
    promptTOS();
  };

  const promptTOS = () => {
    onModalClose();
    setToggleTOSModal(true);
  };

  const promptPP = () => {
    setToggleTOSModal(false);
    setTogglePPModal(true);
    setAcceptTOS(true);
  };

  const promptRegister = () => {
    setAcceptPP(true);
    if (role === "customer") {
      navigate("/customerSignUp");
    } else if (role === "artist") {
      navigate("/artistSignUp");
    }
  };

  return (
    <div>
      {modalState ? (
        <ModalContainer
          title={t("NAVBAR.SIGN_UP")}
          onButtonClose={onModalClose}
        >
          <p className="sign_up_modal_instruc">
            {t("NAVBAR.SIGN_UP_MODAL_INSTRUCTIONS")}
          </p>
          <LgGreenButton
            text={t("NAVBAR.SIGN_UP_MODAL_CUSTOMER_SELECT")}
            onButtonClick={selectCustomer}
          />
          <div className="tattoo_artist_select">
            <button className="tattoo_artist_button" onClick={selectArtist}>
              {t("NAVBAR.SIGN_UP_MODAL_ARTIST_SELECT")}
            </button>
          </div>
        </ModalContainer>
      ) : (
        ""
      )}
      {toggleTOSModal && termsOfServiceContent ? (
        <ModalContainer
          className="tos"
          title={t("MODALS.TERMS_OF_SERVICE")}
          onButtonClose={() => setToggleTOSModal(!toggleTOSModal)}
        >
          <div className="tos_version">
            <p>
              {t("LANDING_PAGE.VERSION")} {termsOfServiceContent.version}
            </p>
            <p>
              {t("CUSTOMERS.UPDATED_ON")}{" "}
              {getDate(termsOfServiceContent.created)}
            </p>
          </div>
          <div
            className="tos_content"
            dangerouslySetInnerHTML={{
              __html: termsOfServiceContent.htmlContent,
            }}
          ></div>
          <LgGreenButton
            text={t("FORM_FIELDS.I_ACCEPT")}
            onButtonClick={promptPP}
          />
        </ModalContainer>
      ) : (
        ""
      )}
      {togglePPModal && privacyPolicyContent ? (
        <ModalContainer
          className="privacy_policy"
          title={t("MODALS.PRIVACY_POLICY")}
          onButtonClose={() => setTogglePPModal(!togglePPModal)}
        >
          <div className="pp_version">
            <p>
              {t("LANDING_PAGE.VERSION")} {privacyPolicyContent.version}
            </p>
            <p>
              {t("CUSTOMERS.UPDATED_ON")}{" "}
              {getDate(privacyPolicyContent.created)}
            </p>
          </div>
          <div
            className="pp_content"
            dangerouslySetInnerHTML={{
              __html: privacyPolicyContent.htmlContent,
            }}
          ></div>
          <LgGreenButton
            text={t("FORM_FIELDS.I_ACCEPT")}
            onButtonClick={promptRegister}
          />
        </ModalContainer>
      ) : (
        ""
      )}
    </div>
  );
};

export default SignUpModal;
