import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomerPageWrapper from "../../../components/Customers/CustomerPageWrapper/CustomerPageWrapper";

import defaultBanner from "../../../img/banner_eye/banner_eye@2x.png";

import { getContentType } from "../../../api/content";
import { htmlDecode } from "../../../utils/helpers";

import "./CustomerRatesTerms.css";

const CustomerRatesTerms = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState("");

  useEffect(() => {
    const getRatesTerms = async () => {
      try {
        let userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes("en")) {
          userLang = "en";
        }
        let res = await getContentType("Rates_Terms", userLang);
        let content = htmlDecode(res.htmlContent);
        setContent(content);
        return res;
      } catch (error) {
        console.log(error);
      }
    };

    getRatesTerms();
  }, []);

  const dashboardContent = () => {
    return (
      <div className="not_verified">
        <div
          className="content_container"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );
  };

  return (
    <div>
      <CustomerPageWrapper
        title={t("NAVBAR.RATES_TERMS")}
        image={defaultBanner}
      >
        <div className="customer_content_container">{dashboardContent()}</div>
      </CustomerPageWrapper>
    </div>
  );
};

export default CustomerRatesTerms;
