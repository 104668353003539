import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ModalContainer from "../ModalContainer/ModalContainer";
import ProfileCustomInput from "../../ButtonsFormFields/ProfileCustomInput/ProfileCustomInput";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";

import {
  editBankingInfo,
  getArtistError,
} from "../../../store/artistSlice";

import validate from "./EditBankingValidationRules";
import "./ArtistEditBanking.css";

const ArtistEditBanking = ({ paymentEmail, onModalClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const artistError = useSelector(getArtistError);
  const [bankingEmail, setBankingEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setBankingEmail(paymentEmail);
  }, [paymentEmail]);
  
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        paymentEmail: bankingEmail,
      };
      dispatch(editBankingInfo(data));
    }
  }, [errors, isSubmitting, bankingEmail]);

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    if (event.target.name === "bankingEmail") {
      setBankingEmail(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors({});
    setErrors(validate(bankingEmail));
    setIsSubmitting(true);
  };

  return (
    <div>
      <ModalContainer
        title={t("ARTISTS.EDIT_BANKING_DETAILS")}
        onButtonClose={onModalClose}
      >
        <ProfileCustomInput
          type="email"
          name="bankingEmail"
          value={bankingEmail}
          placeholder={t("FORM_FIELDS.BANKING_EMAIL")}
          onInputChange={handleChange}
          errorMessage={t(errors.bankingEmail)}
          required
        />
        <div className="save_changes_button">
          <LgGreenButton
            text={t("FORM_FIELDS.SAVE_CHANGES")}
            onButtonClick={handleSubmit}
          />
        </div>
      </ModalContainer>
    </div>
  );
};

export default ArtistEditBanking;
