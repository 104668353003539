import React from "react";
import { useTranslation } from "react-i18next";

import AboutUsDetail from "../AboutUsDetail/AboutUsDetail";

import "./AboutUs.css";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="about_us" id="about_section">
      <div className="about_us_main">
        <h2 className="about_us_title">{t('LANDING_PAGE.ABOUT_US_TITLE')}</h2>
        <p className="about_us_content">
          {t('LANDING_PAGE.ABOUT_US_CONTENT')}
        </p>
      </div>
      <div className="about_us_more">
        <AboutUsDetail title={"LANDING_PAGE.ABOUT_US_DETAIL_1"} content={"LANDING_PAGE.ABOUT_US_CONTENT_1"} />
        <AboutUsDetail title={"LANDING_PAGE.ABOUT_US_DETAIL_2"} content={"LANDING_PAGE.ABOUT_US_CONTENT_2"}/>
        <AboutUsDetail title={"LANDING_PAGE.ABOUT_US_DETAIL_3"} content={"LANDING_PAGE.ABOUT_US_CONTENT_3"} />
        <AboutUsDetail title={"LANDING_PAGE.ABOUT_US_DETAIL_4"} content={"LANDING_PAGE.ABOUT_US_CONTENT_4"} />
      </div>
    </div>
  );
};

export default AboutUs;
