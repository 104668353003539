import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalContainer from "../ModalContainer/ModalContainer";
import LgGreenButton from "../../ButtonsFormFields/LgGreenButton/LgGreenButton";
import SuccessModal from "../SuccessModal/SuccessModal";
import FailModal from "../FailModal/FailModal";

import {
  resetPaymentComplete,
  resetErrorMessage,
  customerPayoffMissedPayments,
  getCustomer,
  getloanDetailsHistory,
} from "../../../store/customerSlice";

import "./MissedPaymentsModal.css";




const MissedPaymentsModal = ({
  toggleMissedPayments,
  targetLoanId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const [toggleFail, setToggleFail] = useState(false);
  const [error, setError] = useState("");
  const stripeError = useSelector((state) => state.customer.errorMessage);
  const paymentComplete = useSelector(
    (state) => state.customer.paymentComplete
  );
  const missedAmount = useSelector((state) => state.customer.loanMissedPayment);

  useEffect(() => {
    if (paymentComplete && stripeError.length === 0) {
      setToggleFail(false);
      setToggleSuccess(true);
      setTimeout(() => {
        setToggleSuccess(false);
        dispatch(resetPaymentComplete());
        toggleMissedPayments(false);
        dispatch(getCustomer());
        const data = {
          id: targetLoanId,
        };
        dispatch(getloanDetailsHistory(data));
      }, 1500);
    } else if (paymentComplete && stripeError.length !== 0) {
      setToggleFail(true);
    }
  }, [paymentComplete, stripeError]);

  useEffect(() => {
    const toggleFailMessage = (e) => {
      setToggleFail(false);
      dispatch(resetPaymentComplete());
      dispatch(resetErrorMessage());
      toggleMissedPayments(false);
    };
    if (toggleFail) {
      window.addEventListener("click", (e) => toggleFailMessage(e));
    } else {
      window.removeEventListener("click", (e) => toggleFailMessage(e));
    }
    return () =>
      window.removeEventListener("click", (e) => toggleFailMessage(e));
  }, [toggleFail]);

  const handleMakePayment = async () => {
    const data = {
      id: targetLoanId,
    };
    await dispatch(customerPayoffMissedPayments(data));
  };

  return (
    <div>
      <ModalContainer
        title={t("CUSTOMERS.PAYMENT_DETAILS")}
        onButtonClose={() => toggleMissedPayments(false)}
      >
        <div className="payment_details_container">
          <div className="payment_details_modal_details">
            <p>
              {t("CUSTOMERS.TOTAL")}
              <span>
                ${missedAmount === null ? 0 : missedAmount.toFixed(2)}
              </span>
            </p>
          </div>
          <LgGreenButton
            text={t("CUSTOMERS.CONTINUE")}
            onButtonClick={handleMakePayment}
          />
        </div>
      </ModalContainer>
      {toggleSuccess ? (
        <SuccessModal title={"CUSTOMERS.PAYMENT_SUCCESSFUL"} />
      ) : null}
      {toggleFail ? (
        <FailModal title={"ERRORS.STRIPE_ERROR"} message={stripeError} />
      ) : null}
    </div>
  );
};

export default MissedPaymentsModal;
