export default function validate(
  email = null,
  password = null,
  confirmPassword = null,
  securityCode = null,
  firstName=null,
) {
  let errors = {};
  if (securityCode === "") {
    errors.securityCode = "ERRORS.CODE_REQUIRED";
  }
  return errors;
}
