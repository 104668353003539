import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import ErrorBoundary from "../../ErrorBoundry/ErrorBoundry";
import PDFViewer from "../../Customers/PDFViewer/PDFViewer";
import ModalContainer from "../ModalContainer/ModalContainer";
import SuccessModal from "../SuccessModal/SuccessModal";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "../StripeForm/StripeForm";

import {
  toggleInveriteModalState,
  getLoanInfo,
  ageRequest,
  bankRequest,
  getLoans,
  toggleLoading,
  getClientSecret,
  customerStripeSetup,
  stripeComplete,
  setNewLoanId,
  getSuccessModalState,
  resetSuccessModal,
} from "../../../store/customerSlice";
import { STRIPEKEY } from "../../../utils/stripe";

import "./LoanProcessModal.css";

const stripePromise = loadStripe(STRIPEKEY);

const InveriteModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("CUSTOMERS.ID_VERIFICATION");
  const [iframeTitle, setIframeTitle] = useState("Inverite Id Verification");
  const [toggleIframe, setToggleIframe] = useState(false);
  const [toggleContract, setToggleContract] = useState(false);
  const [toggleStripe, setToggleStripe] = useState(false);
  const targetLoan = useSelector(getLoanInfo);
  const clientSecret = useSelector(getClientSecret);
  const [toggleSuccess, setToggleSuccess] = useState(false);
  const successModalState = useSelector(getSuccessModalState);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",

      variables: {
        colorPrimary: "#37c452",
        colorBackground: "#D6D6D6",
        colorText: "#30313d",
        colorDanger: "#ff5a5a",
        fontFamily: "roboto, system-ui, sans-serif",
        spacingUnit: "4px",
        borderRadius: "6px",
        // See all possible variables below
      },
    },
  };

  //display success message after approval process
  useEffect(() => {
    let timer1;
    if (successModalState === true) {
      setToggleSuccess(true);
      timer1 = setTimeout(() => {
        setToggleSuccess(false);
        dispatch(toggleInveriteModalState());
        dispatch(resetSuccessModal());
        dispatch(getLoans());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [dispatch, successModalState]);

  //waiting for Stripe to complete and and move loan to approval once stripe is complete
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("redirect_status");
    if (term === "succeeded") {
      const data = {
        id: targetLoan.loanId,
      };
      dispatch(stripeComplete(data));
      dispatch(toggleLoading());
      setToggleSuccess(true);
      setTimeout(() => {
        setToggleSuccess(false);
        dispatch(getLoans());
        navigate("/customer");
      }, 1500);
    }
  }, [targetLoan.loanId, dispatch, navigate]);

  //event listener for inverite response on risk assessment iframe
  useEffect(() => {
    let timer = null;
    function listener(event) {
      if (
        event.origin === "https://sandbox.inverite.com" ||
        event.origin === "https://live.inverite.com" ||
        event.origin === "https://www.inverite.com"
      ) {
        var data = event.data;
        if (data.verified === 1 && data.similarity >= 90) {
          const data = {
            id: targetLoan.loanId,
          };
          dispatch(bankRequest(data));
          dispatch(toggleInveriteModalState());
        } else if (data.verified === 1) {
          const data = {
            id: targetLoan.loanId,
          };
          dispatch(bankRequest(data));
          dispatch(toggleInveriteModalState());
          dispatch(toggleLoading());
          timer = setTimeout(() => {
            dispatch(getLoans());
          }, 1000);
        } // perform success events here
       else if (event.data === "success") {
          dispatch(toggleInveriteModalState());
          dispatch(toggleLoading());
          timer = setTimeout(() => {
            dispatch(getLoans());
          }, 3000);
          //perform success operations here
        }
      } else {
        //perform failure events
      }
    }
    if (
      targetLoan.fullLoanStatus === "Waiting_Verification" ||
      targetLoan.fullLoanStatus === "Age_Initiated" ||
      targetLoan.fullLoanStatus === "Age_Completed" ||
      targetLoan.fullLoanStatus === "Risk_Score_Initiated"
    ) {
      if (window.addEventListener) {
        window.addEventListener("message", listener, false);
      } else {
        window.attachEvent("onmessage", listener);
      }
    }
    return () => {
      window.removeEventListener("message", listener);
      clearTimeout(timer);
    };
  }, [dispatch, targetLoan.loanId, targetLoan.fullLoanStatus]);

  //set next step on loan process stage depending on status of loan
  useEffect(() => {
    if (!targetLoan) {
    } else if (
      targetLoan.fullLoanStatus === "Waiting_Verification" ||
      targetLoan.fullLoanStatus === "Age_Initiated"
    ) {
      const data = {
        id: targetLoan.loanId,
      };
      setTitle("CUSTOMERS.ID_VERIFICATION");
      setIframeTitle("Inverite Id Verification");
      setToggleIframe(true);
      setToggleContract(false);
      dispatch(ageRequest(data));
    } else if (
      targetLoan.fullLoanStatus === "Age_Completed" ||
      targetLoan.fullLoanStatus === "Risk_Score_Initiated"
    ) {
      const data = {
        id: targetLoan.loanId,
      };
      setTitle("CUSTOMERS.BANK_VERIFICATION");
      setIframeTitle("Inverite Bank Verification");
      setToggleIframe(true);
      setToggleContract(false);
      setToggleStripe(false);
      dispatch(bankRequest(data));
    } else if (
      targetLoan.fullLoanStatus === "Risk_Score_Completed" ||
      targetLoan.fullLoanStatus === "Contract_Initiated"
    ) {
      setTitle("CUSTOMERS.LOAN_AGREEMENT");
      setIframeTitle("");
      setToggleIframe(false);
      setToggleContract(true);
      setToggleStripe(false);
      dispatch(setNewLoanId(targetLoan.loanId));
    } else if (targetLoan.fullLoanStatus === "Contract_Completed") {
      setTitle("CUSTOMERS.STRIPE_SETUP_MODAL");
      const data = {
        id: targetLoan.loanId,
      };
      dispatch(customerStripeSetup(data));
      setIframeTitle("");
      setToggleIframe(false);
      setToggleContract(false);
      setToggleStripe(true);
    }
  }, [dispatch, targetLoan]);

  const renderIframe = toggleIframe ? (
    <iframe
      id="ifrm"
      title={iframeTitle}
      className="id_verification_frame"
      src={targetLoan.iframeUrl}
    ></iframe>
  ) : null;

  const renderContract = toggleContract ? (
    <div className="contract_container">
      <PDFViewer targetLoanId={targetLoan.loanId} />
    </div>
  ) : null;

  const renderStripe = () => {
    if (toggleStripe && clientSecret) {
      return (
        <div className="stripe_container">
          <Elements stripe={stripePromise} options={options} key={clientSecret}>
            <StripeForm />
          </Elements>
        </div>
      );
    }
  };

  const closeModal = () => {
    dispatch(toggleInveriteModalState());
  };

  return (
    <div>
      <ModalContainer title={t(title)} onButtonClose={closeModal}>
        <ErrorBoundary>
          {renderIframe}
          {renderContract}
          {renderStripe()}
        </ErrorBoundary>
      </ModalContainer>
      {toggleSuccess ? (
        <SuccessModal title={"FORM_FIELDS.LOAN_APPROVED"} />
      ) : null}
    </div>
  );
};

export default InveriteModal;
